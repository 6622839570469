import React, { useRef } from 'react';
import { StyleSheet, View, Animated, I18nManager, Pressable } from 'react-native';
import { RectButton, Swipeable } from 'react-native-gesture-handler';
import { useNavigation } from '@react-navigation/native';
import { Text, Progress, IconResolver, SommdText, SommdImage, IconButton } from '../components';
import { URL } from '../constants';
import { colors, TextStyles } from '../styles';
// import { useAsyncSubmit } from '../utils';
import { Wine } from '../selectors';
// import { useStore } from '../context';
// import RedWineIcon from '../assets/redWineIcon';
// import YellowWineIcon from '../assets/yellowWineIcon';
// import HeartOutline from '../assets/heartOutline';
// import Heart from '../assets/HeartIcon';
import RedIndicator from '../assets/red_wine_indicator';
import Flag from '../assets/france.png';
import { AdminWine } from './Admin/adminSelectors';
import RedWineOutline from '../assets/redWineOutline';
import WhiteWineOutline from '../assets/whiteWineOutline';

type ActionGroup = {
  key: string;
  label: string;
  backgroundColor: string;
  color: string;
  Icon: React.ComponentType<any>;
  onPress?: (arg?: AdminWine | undefined) => void;
};

interface ActionProp {
  left: ActionGroup[];
  right: ActionGroup[];
}

type Props = {
  actions?: ActionProp;
  handleCart?: (wine: Wine) => void;
  handleCellar?: (wine: Wine) => void;

  // TODO: Handle reaction for items that are not in store
  disableReaction?: boolean;
  fromCellar?: boolean;
};

const renderAction = (
  action: ActionGroup,
  x: number,
  progress: Animated.AnimatedInterpolation,
  onClose: () => void,
  wine: AdminWine
) => {
  const { label, backgroundColor, color, Icon, onPress } = action;

  const trans = progress.interpolate({
    inputRange: [0, 1],
    outputRange: [x, 0],
  });
  const pressHandler = () => {
    if (onPress) onPress(wine);
    onClose();
  };
  return (
    <Animated.View key={x} style={[styles.actionContainer, { transform: [{ translateX: trans }] }]}>
      <RectButton style={[styles.action, { backgroundColor }]} onPress={pressHandler}>
        <IconResolver width={32} height={32} Icon={Icon} />
        <Text style={[styles.actionText, TextStyles.normalSmall, { color }]}>{label}</Text>
      </RectButton>
    </Animated.View>
  );
};

export const ACTION_WIDTH = 80;
const renderActions = (
  progress: Animated.AnimatedInterpolation,
  actions: ActionGroup[],
  multiplier: number,
  onClose: () => void,
  wine: AdminWine
) => {
  return (
    <View
      style={{
        width: ACTION_WIDTH * actions.length,
        flexDirection: I18nManager.isRTL ? 'row-reverse' : 'row',
      }}
    >
      {actions.map((action, index) => {
        const x = multiplier * ACTION_WIDTH * (actions.length - index);
        return renderAction(action, x, progress, onClose, wine);
      })}
    </View>
  );
};

function ProductItem({
  actions,
  handleCart,
  handleCellar,
  fromCellar,
  ...wine
}: AdminWine & Props) {
  // const [_, dispatch] = useStore();
  const swipeableRef = useRef<Swipeable | null>(null);
  const navigation: any = useNavigation();

  const {
    bottle,
    // id: wine_id,
    id,
    winery,
    title: name,
    // vintage,
    // wine_category,
    // reaction,
    // cart,
    sommd_score: score,
    average_price: price,
    // inventory,

    // disableReaction,
    color,
  } = wine;

  const closeSwipeable = () => {
    swipeableRef.current?.close();
  };

  const makeAction = (multiplier: 1 | -1, actions?: ActionGroup[]) =>
    function action(progress: Animated.AnimatedInterpolation) {
      if (actions) return renderActions(progress, actions, multiplier, closeSwipeable, wine);
      return null;
    };

  const renderRightActions = makeAction(1, actions?.right);
  // const renderLeftActions = makeAction(-1, actions?.left);

  // const { submit: like, loading: likeLoader } = useAsyncSubmit('wine-reaction/like');
  // const { submit: removeLike, loading: removeLikeLoader } = useAsyncSubmit(
  // 'wine-reaction/remove-like'
  // );
  // const { submit: addCellar, loading: cellarLoader } = useAsyncSubmit('cellar/add');

  // const favorite = reaction === 'like';
  // const disabled = likeLoader || removeLikeLoader;
  // const WineIcon = wine_category === 'Red' ? RedWineIcon : YellowWineIcon;
  // const HeartIcon = favorite || disabled ? Heart : HeartOutline;
  // const fillColor = favorite ? '#C85F57' : '#CCCCCC';
  // const heartIconColor = disabled ? colors.disabled : fillColor;
  // const iconDimension = { width: 20, height: 20 };

  // const likeWine = async () => {
  //   if (disableReaction) return;

  //   const payload = { wine_id };
  //   const api = favorite ? removeLike : like;

  //   await api(payload);
  //   dispatch({
  //     type: 'UPDATE_WINE',
  //     payload: { id: wine_id, reaction: favorite ? null : 'like' },
  //   });
  // };
  const Icon = color === 'Red' ? RedWineOutline : WhiteWineOutline;

  return (
    <Swipeable
      ref={swipeableRef}
      leftThreshold={0}
      rightThreshold={60}
      renderRightActions={renderRightActions}
    >
      {/* <View style={styles.iconsContainer}>
          <IconButton
            {...iconDimension}
            color={heartIconColor}
            Icon={disabled ? HeartIcon : HeartIcon}
            onPress={likeWine}
            disabled={disabled}
          />
        </View> */}
      <Pressable
        style={styles.container}
        onPress={() => navigation.navigate('ProductDetail', { id: id || wine?.wine_id })}
      >
        {bottle ? (
          <SommdImage
            multiple={wine?.count ? wine?.count : 0}
            uri={`${URL}/${bottle}`}
            containerStyle={styles.productImage}
          />
        ) : (
          <View style={{ marginHorizontal: 18 }}>
            <Icon height={120} />
          </View>
        )}
        <View style={styles.detailContainer}>
          <View style={styles.infoContainer}>
            <SommdText numberOfLines={2} style={[TextStyles.bold, styles.winery]}>
              {winery || 'Insignia'}
            </SommdText>
            <SommdText numberOfLines={1} style={[TextStyles.normalSmall, styles.spacing]}>
              {name || 'Joseph Phelps'}
            </SommdText>
            <SommdText style={[TextStyles.normalSmall, styles.price]}>
              {price ? `$ ${price}.00` : '$200.00'}
            </SommdText>
          </View>
          <View style={styles.scoreContainer}>
            <IconResolver Icon={Flag} width={32} height={32} />
            <IconButton
              Icon={RedIndicator}
              width={24}
              height={24}
              containerStyle={styles.spacing}
            />
            <Progress
              animated={false}
              size={24}
              strokeWidth={2}
              color={colors.temp.primary.teal}
              score={Number(score?.toPrecision(3)) || 94}
              childrenStyle={styles.progressChildren}
            />
          </View>
        </View>
      </Pressable>
    </Swipeable>
  );
}

export default React.memo(ProductItem);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: 24,
    flexDirection: 'row',
    backgroundColor: colors.temp.primary.white,
  },
  iconsContainer: {
    justifyContent: 'space-between',
    paddingVertical: 8,
  },
  productImage: {
    height: 120,
    marginRight: 24,
  },
  detailContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  infoContainer: {
    flex: 1,
    paddingVertical: 18,
    justifyContent: 'center',
  },
  infoTop: {
    flex: 1,
    marginBottom: 4,
  },
  winery: {},
  spacing: {
    marginTop: 8,
    marginBottom: 8,
  },
  infoBottom: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  price: {
    color: colors.temp.secondary.bordeaux.normal,
  },
  cart: {
    marginLeft: 16,
    marginRight: 8,
  },
  cta: {
    color: colors.brand,
    padding: 8,
  },
  scoreContainer: {
    marginLeft: 16,
    alignItems: 'center',
  },
  badge: {
    position: 'absolute',
    right: 0,
  },
  action: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionText: {
    textAlign: 'center',
    paddingTop: 16,
  },
  leftActionContainer: {
    width: ACTION_WIDTH,
  },
  actionContainer: {
    flex: 1,
  },
  progressLabel: {
    width: 50,
  },
  progressChildren: {
    color: colors.temp.primary.teal,
  },
});
