import React from 'react';
import { View, StyleSheet, TextStyle, ViewStyle, TouchableOpacity } from 'react-native';
import { FormProvider, useForm } from 'react-hook-form';

import { useAsyncSubmit } from '../utils';
import { FormType } from '../forms';
import LineInput from './LineInput';
import Text from './Text';
import { TextStyles } from '../styles';
import { useBiometric } from '../context';
import SommdButton from './SommdButton';
import SommdText from './SommdText';

type Props<T> = {
  onComplete: (arg0: any, arg1: any) => void;
  endpoint: string;
  formData: FormType[];
  buttonText?: string;
  formatData?: (d: T) => void;
  TailComponent?: React.ReactNode;
  defaultValues?: any;
  containerStyles?: ViewStyle;
  inputStyles?: ViewStyle;
  buttonStyles?: ViewStyle;
  errorStyles?: TextStyle;
  biometric?: boolean;
  buttonType?: 'primary' | 'secondary' | 'tertiary';
};

export default function AsyncForm<T>({
  onComplete,
  endpoint,
  formData,
  buttonText,
  formatData,
  TailComponent,
  defaultValues,
  containerStyles,
  inputStyles,
  buttonStyles,
  errorStyles,
  buttonType,
  biometric,
}: Props<T>) {
  const methods = useForm({ defaultValues });
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getValues,
  } = methods;
  const { submit, loading, error } = useAsyncSubmit(endpoint);
  const { isEnabled, showBiometric } = useBiometric();

  const onSubmit = async (data: any) => {
    const formattedData = formatData ? formatData(data) : data;
    const result = await submit(formattedData);

    if (result) {
      onComplete(result, data);
      reset();
    }
  };

  const onPress = handleSubmit(onSubmit);

  const handlePress = async () => {
    const data = await showBiometric({ title: 'Login' });

    if (data) {
      const { password, username } = data;
      onSubmit({ password, username });
    }
  };

  return (
    <FormProvider {...methods}>
      <View style={[styles.container, containerStyles]}>
        {formData.map((f) => {
          const validation = { ...f.validation };
          if (f.customValidation) {
            validation.validate = (data: any) => {
              // @ts-ignore
              return f.customValidation(getValues(), data);
            };
          }

          return (
            <LineInput
              {...f}
              key={f.name}
              validation={validation}
              control={control}
              error={errors[f.name]}
              config={{ ...f.config, onSubmitEditing: onPress }}
              containerStyle={{ ...styles.lineInput, ...inputStyles }}
            />
          );
        })}
        {TailComponent}
        <SommdText style={[TextStyles.smallPrimaryVariant, styles.error, errorStyles]}>
          {error || ''}
        </SommdText>
        <SommdButton
          title={buttonText || 'Submit'}
          onPress={onPress}
          disabled={loading}
          type={buttonType}
          style={buttonStyles}
        />
        {isEnabled && biometric && (
          <TouchableOpacity onPress={handlePress} style={styles.buttontailContainer}>
            <Text style={TextStyles.smallPrimaryVariant}>Use Biometric Login</Text>
          </TouchableOpacity>
        )}
      </View>
    </FormProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  lineInput: {
    paddingVertical: 4,
  },
  error: {
    paddingVertical: 14,
  },
  buttontailContainer: {
    alignItems: 'center',
    marginTop: 40,
  },
});
