import * as React from 'react';
import Svg, { Mask, Path } from 'react-native-svg';

function SvgComponent(props: any) {
  return (
    <Svg width={32} height={32} viewBox="0 0 32 32" fill="none" {...props}>
      <Mask id="a" fill="#fff">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 28H6.235V14.706H4L16.118 4l12.117 10.706H26V28h-8v-7h-4v7Z"
        />
      </Mask>
      <Path
        d="M14 28v2h2v-2h-2Zm-7.765 0h-2v2h2v-2Zm0-13.294h2v-2h-2v2Zm-2.235 0-1.324-1.499-3.96 3.499H4v-2ZM16.118 4l1.324-1.499-1.324-1.17-1.325 1.17L16.118 4Zm12.117 10.706v2h5.285l-3.96-3.499-1.325 1.499Zm-2.235 0v-2h-2v2h2ZM26 28v2h2v-2h-2Zm-8 0h-2v2h2v-2Zm0-7h2v-2h-2v2Zm-4 0v-2h-2v2h2Zm-.706 9H14v-4h-.706v4Zm-7.059 0h7.06v-4h-7.06v4Zm-2-15.294V28h4V14.706h-4Zm-.235 2h2.235v-4H4v4ZM14.793 2.5 2.676 13.207l2.648 2.998L17.442 5.499 14.793 2.5ZM29.56 13.207 17.441 2.501 14.793 5.5l12.118 10.706 2.648-2.998ZM26 16.706h2.235v-4H26v4ZM28 28V14.706h-4V28h4Zm-9.059 2H26v-4h-7.059v4ZM18 30h.941v-4H18v4Zm-2-9v7h4v-7h-4Zm-2 2h4v-4h-4v4Zm2 5v-7h-4v7h4Z"
        fill="#452A21"
        mask="url(#a)"
      />
    </Svg>
  );
}

export default SvgComponent;
