import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props: any) {
  return (
    <Svg width={32} height={32} viewBox="0 0 32 32" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.424 6.179a7.516 7.516 0 0 1 3.261 0A2.275 2.275 0 0 1 19.467 8.4v1.314h2V8.4a4.275 4.275 0 0 0-3.348-4.173 9.516 9.516 0 0 0-4.128 0A4.275 4.275 0 0 0 10.643 8.4v1.314h2V8.4c0-1.066.74-1.99 1.781-2.221ZM3 11h26l-.2 2-1.043 10.399A4 4 0 0 1 23.777 27H7.832a4 4 0 0 1-3.99-3.726L3.137 13 3 11Zm2.837 12.137L5.142 13H11v12H7.832a2 2 0 0 1-1.995-1.863ZM13 25V13h6v12h-6Zm8 0h2.777a2 2 0 0 0 1.99-1.8L26.79 13H21v12Z"
        fill="#452A21"
      />
    </Svg>
  );
}

export default SvgComponent;
