"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.spring = exports.jump = exports.timing = void 0;
var react_native_1 = require("react-native");
var isMobile = react_native_1.Platform.OS === 'android' || react_native_1.Platform.OS === 'ios';
var animFactory = function (animation, defaultOptions) {
    return function (driver, toValue, options) {
        return animation(driver, __assign(__assign(__assign({ useNativeDriver: isMobile }, defaultOptions), options), { toValue: toValue }));
    };
};
exports.timing = animFactory(react_native_1.Animated.timing, { easing: react_native_1.Easing.linear });
var jump = function (translateY, options) {
    return react_native_1.Animated.loop((0, exports.timing)(translateY, 1, __assign({ duration: 2000 }, options)));
};
exports.jump = jump;
exports.spring = animFactory(react_native_1.Animated.spring, {});
