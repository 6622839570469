import React from 'react';
import { Page, SommdButton } from '../components';
import ProductList from './ProductList';
import { colors } from '../styles';

function CellarCategory({ route, navigation }: { route: any; navigation: any }) {
  const { params } = route;
  const { header, data }: { header: string; data: any } = params;
  const headerNew = `My ${header || ''} \n Wines`;
  return (
    <Page
      headerTitle={headerNew || ''}
      headerRight={
        <SommdButton
          type="secondary"
          title="Add"
          textStyle={{ color: colors.temp.secondary.bordeaux.light }}
          onPress={() => navigation.navigate('AddWine')}
        />
      }
    >
      <ProductList data={data} type="cellar" />
    </Page>
  );
}

export default CellarCategory;
