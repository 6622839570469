import React from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import { LinearGradient } from './LinearGradient';

import BackgroundTop from '../assets/backgroundTop';
import BackgroundBottom from '../assets/backgroundBottom';

export default function GradientWrapper({
  children,
  containerStyle,
}: {
  children: React.ReactNode;
  containerStyle?: ViewStyle;
}) {
  return (
    <LinearGradient colors={['#E7B6A3', '#C85F57']} style={styles.container}>
      <BackgroundTop
        preserveAspectRatio="none"
        height="15%"
        width="120%"
        style={{ position: 'absolute', top: 0 }}
      />
      <BackgroundBottom
        color="#C85F57"
        preserveAspectRatio="none"
        width="120%"
        height="20%"
        style={{ position: 'absolute', bottom: 0 }}
      />
      <View style={[styles.innerContainer, containerStyle]}>{children}</View>
    </LinearGradient>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  innerContainer: {
    flex: 1,
    width: '100%',
  },
});
