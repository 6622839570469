import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { URL } from '../constants';
import { colors, TextStyles } from '../styles';
import { getScreenDimension } from '../utils';
import type { Question } from '../selectors';

import SommdText from './SommdText';
import SommdImage from './SommdImage';
import type { InputProps } from './types';

const padding = 24;
const screenWidth = getScreenDimension();
const width = (screenWidth - 3 * padding) / 2;

const OnboardingInput = React.forwardRef(
  (
    {
      data,
      onChange,
      value,
      error,
    }: InputProps & {
      data: Question;
      error?: { type?: string; message?: string };
    },
    ref: any
  ) => {
    const { question, answers } = data;

    const body = answers.map((o) => {
      const isSelected = value.includes(o.answer_id);

      return (
        <TouchableOpacity
          key={o.answer_id}
          onPress={() => {
            // if (multiselect) {
            //   if (isSelected) onChange([...value].filter((v) => v !== o.answer_id));
            //   else onChange([...value, o.answer_id]);
            // } else {
            //   onChange([o.answer_id]);
            // }
            onChange([o.answer_id]);
          }}
          style={[styles.body, isSelected && styles.selected, { width }]}
        >
          {o.image_name ? (
            <SommdImage uri={`${URL}/${o.image_name}`} containerStyle={[{ width }, styles.image]} />
          ) : null}
          <SommdText style={styles.answer}>{o.answer}</SommdText>
        </TouchableOpacity>
      );
    });

    return (
      <View style={styles.container} ref={ref}>
        <SommdText style={[styles.title, TextStyles.extraBold]}>{question}</SommdText>
        <View style={styles.imageContainer}>{body}</View>
        <View style={styles.footer}>
          <SommdText vMargin="medium" style={[TextStyles.error]}>
            {error?.message || ''}
          </SommdText>
        </View>
      </View>
    );
  }
);

export default OnboardingInput;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  title: {
    paddingTop: 110,
    paddingBottom: 18,
    textAlign: 'center',
    paddingHorizontal: 36,
  },
  answer: {
    textAlign: 'center',
    marginVertical: 8,
  },
  imageContainer: {
    flexGrow: 1,
    alignContent: 'center',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'flex-end',
    paddingRight: padding,
    paddingBottom: padding,
    justifyContent: 'center',
  },
  body: {
    marginLeft: padding,
    marginBottom: padding / 2,
  },
  image: {
    minHeight: 150,
  },
  selected: {
    backgroundColor: colors.temp.secondary.brown[5],
  },
  footer: {
    height: 150,
    alignItems: 'center',
    paddingHorizontal: 16,
  },
});
