import { lazy } from 'react';

export const Admin = lazy(() => import('./screens/Admin/Admin'));
export const ForgotPassword = lazy(() => import('./screens/user/ForgotPassword'));
export const Login = lazy(() => import('./screens/user/Login'));
export const Signup = lazy(() => import('./screens/user/Signup'));
export const Verification = lazy(() => import('./screens/user/Verification'));
export const Onboarding = lazy(() => import('./screens/user/Onboarding'));
export const ProductDetail = lazy(() => import('./screens/productDetail/ProductDetail'));
export const PairingDetail = lazy(() => import('./screens/PairingDetail'));
export const SearchByImage = lazy(() => import('./screens/SearchByImage'));
export const Cellar = lazy(() => import('./screens/Cellar'));
export const Pairing = lazy(() => import('./screens/Pairing'));
export const Camera = lazy(() => import('./screens/camera'));
export const Cart = lazy(() => import('./screens/Cart'));
export const Profile = lazy(() => import('./screens/profile/Profile'));
export const Home = lazy(() => import('./screens/Home'));
export const AddressForm = lazy(() => import('./screens/AddressForm'));
export const FlavourProfile = lazy(() => import('./screens/profile/FlavourProfile'));
export const Invitations = lazy(() => import('./screens/profile/Invitations'));
export const Orders = lazy(() => import('./screens/profile/Orders'));
export const Addresses = lazy(() => import('./screens/profile/Addresses'));
export const BiometricLogin = lazy(() => import('./screens/profile/BiometricLogin'));
export const WelcomeScreen = lazy(() => import('./screens/WelcomeScreen'));
export const AddWine = lazy(() => import('./screens/AddWine'));
export const FilterScreen = lazy(() => import('./screens/FilterScreen'));
export const EditEmail = lazy(() => import('./screens/profile/EditEmail'));
export const ScanResult = lazy(() => import('./screens/ScanResult'));
export const FavoriteWines = lazy(() => import('./screens/profile/FavoriteWines'));
