import React, { useEffect, useRef, useState } from 'react';
import {
  View,
  ViewStyle,
  StyleSheet,
  FlatList,
  TouchableOpacity,
  TextInput,
  TextInputProps,
  Pressable,
} from 'react-native';

import { colors, TextStyles } from '../styles';
import Pull from '../assets/pullUp';

import SommdText from './SommdText';
import IconButton from './IconButton';

type Props = TextInputProps & {
  onChange: (v: string | number) => void;
  error?: { type: 'string'; message: 'string' };
  data: { id: string; val: string }[];
  containerStyle?: ViewStyle;
};

const Search = React.forwardRef((props: Props, ref: any) => {
  const { placeholder, secureTextEntry, containerStyle, data, value, onChange, error } = props;
  const [search, setSearch] = React.useState('');
  const [show, setShow] = React.useState(false);
  const [filterData, setFilterData] = useState<{ id: string; val: string }[]>([]);
  const pickerRef = useRef<any>();

  useEffect(() => {
    setFilterData(data);
  }, [data]);

  const handleSearch = (text: string) => {
    if (text) {
      setSearch(text);
      setFilterData(data.filter((item) => item.val.toLowerCase().includes(search.toLowerCase())));
    } else {
      setSearch('');
      setFilterData(data);
    }
  };
  const d: number = filterData?.length;

  return (
    <View
      ref={ref}
      style={[{ flexDirection: 'column', marginBottom: 16, width: 180 }, containerStyle]}
    >
      <SommdText vPadding="small">{placeholder}</SommdText>
      {show ? (
        <>
          <TextInput
            autoFocus
            ref={pickerRef}
            value={show ? search : data.find((d) => d.id === value)?.val}
            placeholder={`Select ${placeholder || ''}`}
            placeholderTextColor="#8E8E93"
            onChangeText={(text) => handleSearch(text)}
            secureTextEntry={secureTextEntry}
            style={{
              fontSize: 14,
              lineHeight: 21.6,
              fontFamily: 'Sarabun-Regular',
              color: colors.temp.secondary.brown.normal,
            }}
          />
          <IconButton
            Icon={Pull}
            containerStyle={styles.icon}
            onPress={() => setShow((prev) => !prev)}
          />
          <View>
            <View style={styles.list}>
              <FlatList
                keyExtractor={(item) => item.val}
                data={filterData}
                style={[{ height: d > 4 ? 226 : (d * 54 || 20) + 10 }, styles.flatList]}
                renderItem={(item) => (
                  <TouchableOpacity
                    onPress={() => {
                      setSearch(item.item.val);
                      onChange(item.item.id);
                      setSearch('');
                      setShow((prev) => !prev);
                    }}
                    style={styles.displaybox}
                  >
                    <SommdText vPadding="small" hPadding="medium" style={[TextStyles.bold]}>
                      {item.item.val}
                    </SommdText>
                  </TouchableOpacity>
                )}
                ListEmptyComponent={<SommdText>No Results found</SommdText>}
              />
            </View>
          </View>
        </>
      ) : (
        <Pressable onPress={() => setShow(!!d)}>
          <SommdText
            style={{
              fontSize: 14,
              color: value ? colors.temp.secondary.brown.normal : '#8E8E93',
            }}
          >
            {value ? data?.find((d) => d.id === value)?.val : `Select ${placeholder || ''}`}
          </SommdText>
        </Pressable>
      )}
      {error && (
        <SommdText vMargin="small" style={TextStyles.error}>
          {error.message || 'Error'}
        </SommdText>
      )}
    </View>
  );
});

export default Search;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginVertical: 16,
  },
  flatList: {
    flexWrap: 'wrap',
    marginTop: 16,
    borderWidth: 2,
    borderColor: 'black',
    shadowColor: '#171717',
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
  },
  displaybox: {
    marginVertical: 8,
  },
  textinput: {
    ...TextStyles.textinput,
    paddingVertical: 8,
    borderBottomWidth: 1,
    borderBottomColor: colors.temp.primary.teal,
  },
  icon: {
    position: 'absolute',
    right: 0,
    top: -8,
    marginVertical: 8,
  },
  list: {
    flex: 1,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 99999,
    backgroundColor: 'white',
  },
});
