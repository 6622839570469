import React, { useMemo } from 'react';
import { Platform, PressableProps, ViewProps, ViewStyle } from 'react-native';
import { renderProps, RenderPropsType } from '../../utils';

import { View } from './helpers';

type FlexAlignType = 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline';

export default function Row({
  children,
  style = {},
  alignItems = 'flex-start',
  justifyContent = 'flex-start',
  nowrap,
  fullHeight,
  // nogutter = false,
  // gutterWidth = CONFIG.gutterWidth,
  component = View,
  ...otherProps
}: {
  children: React.ReactNode;
  style?: ViewStyle;
  alignItems?: FlexAlignType;
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  nowrap?: boolean;
  fullHeight?: boolean;
  nogutter?: boolean;
  gutterWidth?: number;
  component?: any;
} & ViewProps &
  PressableProps &
  RenderPropsType) {
  const theStyle = useMemo(() => {
    // const theGutterWidth = getGutterWidth(gutterWidth, nogutter);

    const styles: ViewStyle = {
      // marginLeft: theGutterWidth / 2,
      // marginRight: theGutterWidth / 2,
      display: 'flex',
      flex: style?.flex ?? (Platform.OS === 'web' ? 1 : 0),
      // flexGrow: 0,
      // flexShrink: 0,
      alignItems,
      justifyContent,
      flexDirection: 'row',
      flexWrap: nowrap ? 'nowrap' : 'wrap',
      position: style?.position ?? 'relative',
      // overflow: 'hidden',
      // height: fullHeight
      //   ? '100%'
      //   : style?.height ?? size || screenClass ||
      //   ? getSize(screenClass, this.props)
      //   : undefined,
    };

    return styles;
  }, [alignItems, justifyContent, nowrap, style?.flex, style?.position]);

  return React.createElement(
    component,
    renderProps({ ...otherProps, style: [theStyle, style] }),
    children
  );
}
