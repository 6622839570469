/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Animated,
  StyleSheet,
  TouchableOpacity,
  Keyboard,
  Platform,
  useWindowDimensions,
  LayoutChangeEvent,
  StyleProp,
  ViewStyle,
  EventSubscription,
} from 'react-native';
import { BottomTabBarProps } from '@react-navigation/bottom-tabs';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { IconResolver, SommdText, View } from './components';
import { colors, LayoutStyles, TextStyles } from './styles';
import Camera from './assets/cameraBottomNav';
import Cellar from './assets/cellar';
import CellarSelected from './assets/cellarSelected';
import Home from './assets/home';
import HomeSelected from './assets/homeSelected';
import Cart from './assets/cart';
import CartSelected from './assets/cartselected';
import Pairing from './assets/pairing';
import PairingSelected from './assets/pairingSelected';

function useIsKeyboardShown() {
  const [isKeyboardShown, setIsKeyboardShown] = React.useState(false);

  React.useEffect(() => {
    const handleKeyboardShow = () => setIsKeyboardShown(true);
    const handleKeyboardHide = () => setIsKeyboardShown(false);

    let willShow: EventSubscription;
    let willHide: EventSubscription;
    if (Platform.OS === 'ios') {
      willShow = Keyboard.addListener('keyboardWillShow', handleKeyboardShow);
      willHide = Keyboard.addListener('keyboardWillHide', handleKeyboardHide);
    } else {
      willShow = Keyboard.addListener('keyboardDidShow', handleKeyboardShow);
      willHide = Keyboard.addListener('keyboardDidHide', handleKeyboardHide);
    }

    return () => {
      willHide.remove();
      willShow.remove();
    };
  }, []);

  return isKeyboardShown;
}

const useNativeDriver = Platform.OS !== 'web';

function findMedianNavBarItem(arr: any[]) {
  if (arr.length % 2 === 0) return -1;
  return Math.floor(arr.length / 2);
}

const icons = {
  Home: [Home, HomeSelected],
  Pairing: [Pairing, PairingSelected],
  Camera: [Camera],
  Cellar: [Cellar, CellarSelected],
  Cart: [Cart, CartSelected],
};

const ICON_SIZE = 32;

const styles = StyleSheet.create({
  tabBar: {
    left: 0,
    right: 0,
    bottom: 0,
    elevation: 8,
  },
  container: {
    flexDirection: 'row',
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    paddingBottom: 16,
    width: '100%',
    backgroundColor: colors.temp.primary.white,
  },
  label: {
    paddingTop: 5,
  },
  itemContainer: {
    flex: 1,
    marginTop: 15,
    alignItems: 'center',
  },
  medianContainer: { marginTop: 6 },
  median: {
    backgroundColor: colors.temp.secondary.brown.normal,
    width: 51,
    height: 51,
    alignItems: 'center',
    borderRadius: 51 / 2,
    justifyContent: 'center',
  },
});

const keyboardHidesTabBar = Platform.OS !== 'ios';

export default function BottomNav({
  state,
  style,
  navigation,
  descriptors,
}: BottomTabBarProps & {
  style?: StyleProp<ViewStyle>;
}) {
  const focusedOptions = descriptors[state.routes[state.index].key].options;
  const isKeyboardShown = useIsKeyboardShown();
  const dimensions = useWindowDimensions();
  const visibilityAnimationConfigRef = React.useRef(focusedOptions.tabBarVisibilityAnimationConfig);

  const shouldShowTabBar = !(keyboardHidesTabBar && isKeyboardShown);

  const [isTabBarHidden, setIsTabBarHidden] = React.useState(!shouldShowTabBar);

  const visible = React.useRef(new Animated.Value(shouldShowTabBar ? 1 : 0)).current;

  const [layout, setLayout] = React.useState({
    height: 0,
    width: dimensions.width,
  });

  React.useEffect(() => {
    const visibilityAnimationConfig = visibilityAnimationConfigRef.current;

    if (shouldShowTabBar) {
      const animation =
        visibilityAnimationConfig?.show?.animation === 'spring' ? Animated.spring : Animated.timing;

      animation(visible, {
        toValue: 1,
        useNativeDriver,
        duration: 250,
        ...visibilityAnimationConfig?.show?.config,
      }).start(({ finished }) => {
        if (finished) {
          setIsTabBarHidden(false);
        }
      });
    } else {
      setIsTabBarHidden(true);

      const animation =
        visibilityAnimationConfig?.hide?.animation === 'spring' ? Animated.spring : Animated.timing;

      animation(visible, {
        toValue: 0,
        useNativeDriver,
        duration: 200,
        ...visibilityAnimationConfig?.hide?.config,
      }).start();
    }
  }, [visible, shouldShowTabBar]);

  const handleLayout = (e: LayoutChangeEvent) => {
    const { height, width } = e.nativeEvent.layout;

    setLayout((layout) => {
      if (height === layout.height && width === layout.width) {
        return layout;
      }
      return {
        height,
        width,
      };
    });
  };
  const defaultInsets = useSafeAreaInsets();

  const medianIdx = findMedianNavBarItem(Object.keys(icons));

  return (
    <Animated.View
      style={[
        styles.tabBar,
        {
          transform: [
            {
              translateY: visible.interpolate({
                inputRange: [0, 1],
                outputRange: [layout.height, 0],
              }),
            },
          ],
          // Absolutely position the tab bar so that the content is below it
          // This is needed to avoid gap at bottom when the tab bar is hidden
          position: isTabBarHidden ? 'absolute' : (null as any),
        },
        {
          paddingHorizontal: Math.max(defaultInsets.left, defaultInsets.right),
        },
        style,
      ]}
      pointerEvents={isTabBarHidden ? 'none' : 'auto'}
      onLayout={handleLayout}
    >
      <View style={[styles.container, LayoutStyles.shadow]}>
        {state.routes.map((route: { name: string; key: string }, index: number) => {
          const { options } = descriptors[route.key];
          const here = options.tabBarLabel !== undefined ? options.tabBarLabel : options.title;
          const label = here !== undefined ? options.title : route.name;

          const isFocused = state.index === index;

          const onPress = () => {
            const event = navigation.emit({
              type: 'tabPress',
              target: route.key,
              canPreventDefault: true,
            });

            if (!isFocused && !event.defaultPrevented) {
              navigation.navigate(route.name);
            }
          };

          const onLongPress = () => {
            navigation.emit({
              type: 'tabLongPress',
              target: route.key,
            });
          };
          // @ts-ignore
          const currentIcon = icons[route.name];
          if (!currentIcon) return null;
          const inner = currentIcon[1] ?? currentIcon[0];
          const Icon = isFocused ? inner : currentIcon[0];

          const commonProps = {
            onPress,
            onLongPress,
            key: route.key,
            testID: options.tabBarTestID,
            accessibilityLabel: options.tabBarAccessibilityLabel,
            accessibilityState: isFocused ? { selected: true } : {},
          };

          if (index === medianIdx)
            return (
              <TouchableOpacity
                {...commonProps}
                accessibilityRole="button"
                style={[styles.itemContainer, styles.medianContainer]}
              >
                <View style={styles.median}>
                  <IconResolver Icon={Icon} width={ICON_SIZE} height={ICON_SIZE} />
                </View>
              </TouchableOpacity>
            );

          return (
            <TouchableOpacity
              {...commonProps}
              accessibilityRole="button"
              style={styles.itemContainer}
            >
              <IconResolver Icon={Icon} width={ICON_SIZE} height={ICON_SIZE} />
              <SommdText style={[styles.label, TextStyles.normalSmaller]}>{label}</SommdText>
            </TouchableOpacity>
          );
        })}
      </View>
    </Animated.View>
  );
}
