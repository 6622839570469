import { Action } from '../../Store';
import {
  AdminWine,
  Appellation,
  Body,
  Category,
  Color,
  Country,
  FeaturedWine,
  FoodCategory,
  Package,
  Region,
  SubRegion,
  SweetNess,
  Varietal,
  Vineyards,
  WineNames,
  WineType,
  WineVarietal,
} from './adminSelectors';

const countryReducer = (state: { country: Country[] }, action: Action) => {
  switch (action.type) {
    case 'POPULATE_COUNTRIES': {
      const country = action.payload;
      return {
        ...state,
        country,
      };
    }
    case 'ADD_COUNTRY': {
      const c = action.payload;
      const { country } = state;
      const id: number = country.length;
      const data = {
        id: id + 1,
        country: c,
        flag_image: null,
      };
      return {
        ...state,
        country: [...country, data],
      };
    }
    case 'DELETE_COUNTRY': {
      const id = action.payload;
      const { country: count } = state;
      return {
        ...state,
        country: count.filter((i: Country) => i.id !== id),
      };
    }
    default:
      return state;
  }
};

const regionReducer = (state: { region: [Region[]] }, action: Action) => {
  switch (action.type) {
    case 'POPULATE_REGIONS': {
      const regionsByCountry = action.payload;
      const region = regionsByCountry.reduce((res: any, r: any) => {
        if (res[r.country_id]) {
          res[r.country_id].push(r);
        } else res[r.country_id] = [r];
        return res;
      }, {});
      return {
        ...state,
        region,
      };
    }
    case 'ADD_REGION': {
      const { i, r } = action.payload;
      const { region } = state;
      const regdata = Object.entries(region);
      const count: number[] = regdata.map((i) => i[1].length);
      const countid = count.reduce((p, q) => p + q, 0);
      const data = {
        id: countid + 1 + 37,
        country_id: i,
        region: r,
      };
      const regiondata = [...region[i], data];
      return {
        ...state,
        region: { ...region, ...{ [i]: regiondata } },
      };
    }
    case 'DELETE_REGION': {
      const { i, ri } = action.payload;
      const { region } = state;
      const filterdata = region[i].filter((i: Region) => i.id !== ri);
      return {
        ...state,
        region: { ...region, ...{ [i]: filterdata } },
      };
    }
    default:
      return state;
  }
};

const subregionReducer = (state: { subregion: [SubRegion[]] }, action: Action) => {
  switch (action.type) {
    case 'POPULATE_SUB_REGIONS': {
      const subRegionsByRegion = action.payload;
      const subregion = subRegionsByRegion.reduce((res: any, r: any) => {
        if (res[r.region_id]) {
          res[r.region_id].push(r);
        } else res[r.region_id] = [r];
        return res;
      }, {});
      return {
        ...state,
        subregion,
      };
    }
    case 'ADD_SUB_REGION': {
      const { i, ri, value } = action.payload;
      const { subregion } = state;
      const subdata = Object.entries(subregion);
      const count: number[] = subdata.map((i) => i[1].length);
      const id = count.reduce((p, q) => p + q, 0);
      const data = {
        id: id + 1 + 4,
        country_id: i,
        region_id: ri,
        subregion: value,
      };
      const subregiondata = [...subregion[ri], data];
      return {
        ...state,
        subregion: { ...subregion, ...{ [ri]: subregiondata } },
      };
    }
    case 'DELETE_SUB_REGION': {
      const { ri, sri } = action.payload;
      const { subregion } = state;
      const filterdata = subregion[ri].filter((i: SubRegion) => i.id !== sri);
      return {
        ...state,
        subregion: { ...subregion, ...{ [ri]: filterdata } },
      };
    }
    default:
      return state;
  }
};

const winebodyReducer = (state: { body: Body[] }, action: Action) => {
  switch (action.type) {
    case 'POPULATE_WINE_BODY': {
      const body = action.payload;
      return {
        ...state,
        body,
      };
    }
    case 'ADD_WINE_BODY': {
      const { body } = state;
      const count = body.length;
      const data = {
        id: count + 1,
        body: action.payload,
      };
      return {
        ...state,
        body: [...body, data],
      };
    }
    case 'DELETE_WINE_BODY': {
      const { body } = state;
      return {
        ...state,
        body: body.filter((i) => i.id !== action.payload),
      };
    }
    default:
      return state;
  }
};

const winecolorReducer = (state: { color: Color[] }, action: Action) => {
  switch (action.type) {
    case 'POPULATE_WINE_COLOR': {
      const color = action.payload;
      return {
        ...state,
        color,
      };
    }
    case 'ADD_WINE_COLOR': {
      const { color } = state;
      const count = color.length;
      const data = {
        id: count + 1,
        color: action.payload,
      };
      return {
        ...state,
        color: [...color, data],
      };
    }
    case 'DELETE_WINE_COLOR': {
      const { color } = state;
      return {
        ...state,
        color: color.filter((i) => i.id !== action.payload),
      };
    }
    default:
      return state;
  }
};

const winecategoryReducer = (state: { category: Category[] }, action: Action) => {
  switch (action.type) {
    case 'POPULATE_WINE_CATEGORY': {
      const category = action.payload;
      return {
        ...state,
        category,
      };
    }
    case 'ADD_WINE_CATEGORY': {
      const { category } = state;
      const count = category.length;
      const data = {
        id: count + 1,
        category: action.payload,
      };
      return {
        ...state,
        category: [...category, data],
      };
    }
    case 'DELETE_WINE_CATEGORY': {
      const { category } = state;
      return {
        ...state,
        category: category.filter((i) => i.id !== action.payload),
      };
    }
    default:
      return state;
  }
};

const winetypeReducer = (state: { wine_type: WineType[] }, action: Action) => {
  switch (action.type) {
    case 'POPULATE_WINE_TYPE': {
      const wine_type = action.payload;
      return {
        ...state,
        wine_type,
      };
    }
    case 'ADD_WINE_TYPE': {
      const { wine_type } = state;
      const count = wine_type.length;
      const data = {
        id: count + 1,
        wine_type: action.payload,
      };
      return {
        ...state,
        wine_type: [...wine_type, data],
      };
    }
    case 'DELETE_WINE_TYPE': {
      const { wine_type } = state;
      return {
        ...state,
        wine_type: wine_type.filter((i) => i.id !== action.payload),
      };
    }
    default:
      return state;
  }
};

const winesweetnessReducer = (state: { wine_sweetness: SweetNess[] }, action: Action) => {
  switch (action.type) {
    case 'POPULATE_WINE_SWEETNESS': {
      const wine_sweetness = action.payload;
      return {
        ...state,
        wine_sweetness,
      };
    }
    case 'ADD_WINE_SWEETNESS': {
      const { wine_sweetness } = state;
      const count = wine_sweetness.length;
      const data = {
        id: count + 1,
        sweetness: action.payload,
      };
      return {
        ...state,
        wine_sweetness: [...wine_sweetness, data],
      };
    }
    case 'DELETE_WINE_SWEETNESS': {
      const { wine_sweetness } = state;
      return {
        ...state,
        wine_sweetness: wine_sweetness.filter((i) => i.id !== action.payload),
      };
    }
    default:
      return state;
  }
};

const varietalReducer = (state: { varietal: Varietal[] }, action: Action) => {
  switch (action.type) {
    case 'POPULATE_VARIETAL': {
      const varietal = action.payload;
      return {
        ...state,
        varietal,
      };
    }
    case 'DELETE_VARIETAL': {
      const { varietal } = state;
      return {
        ...state,
        varietal: varietal.flat().filter((i) => i.id !== action.payload),
      };
    }
    default:
      return state;
  }
};

const wineVarietalReducer = (state: { varietal: WineVarietal[] }, action: Action) => {
  switch (action.type) {
    case 'POPULATE_WINE_VARIETAL': {
      const varietal = action.payload;
      return {
        ...state,
        varietal,
      };
    }
    case 'DELETE_WINE_VARIETAL': {
      const { varietal } = state;
      return {
        ...state,
        varietal: varietal.flat().filter((i) => i.wine_id !== action.payload),
      };
    }
    default:
      return state;
  }
};

const foodcategoryReducer = (state: { foodcategory: FoodCategory[] }, action: Action) => {
  switch (action.type) {
    case 'POPULATE_FOODCATEGORY': {
      const foodcategory = action.payload;
      return {
        ...state,
        foodcategory,
      };
    }
    case 'DELETE_FOODCATEGORY': {
      const { foodcategory } = state;
      return {
        ...state,
        foodcategory: foodcategory.filter((i) => i.id !== action.payload),
      };
    }
    default:
      return state;
  }
};

const winevineyardReducer = (state: { vineyards: [Vineyards[]] }, action: Action) => {
  switch (action.type) {
    case 'POPULATE_VINEYARDS': {
      const vineyardsData = action.payload;
      const vineyards = vineyardsData.reduce((res: any, r: any) => {
        if (res[r.subregion_id]) {
          res[r.subregion_id].push(r);
        } else res[r.subregion_id] = [r];
        return res;
      }, {});
      return {
        ...state,
        vineyards,
      };
    }
    case 'ADD_VINEYARD': {
      const { country_id, region_id, subregion_id, vineyard } = action.payload;
      const { vineyards } = state;
      const count = vineyards.length;
      const data = {
        id: count + 1,
        country_id,
        region_id,
        subregion_id,
        vineyard,
      };
      return {
        ...state,
        vineyards: [...vineyards, data],
      };
    }
    case 'DELETE_VINEYARD': {
      const { vi, sri } = action.payload;
      const { vineyards } = state;
      const filterdata = vineyards[sri].filter((i: Vineyards) => i.id !== vi);
      return {
        ...state,
        vineyards: { ...vineyards, ...{ [sri]: filterdata } },
      };
    }
    default:
      return state;
  }
};

const wineappellationReducer = (state: { appellations: Appellation[] }, action: Action) => {
  switch (action.type) {
    case 'POPULATE_APPELLATION': {
      const appellationsData = action.payload;
      const appellations = appellationsData.reduce((res: any, r: any) => {
        if (res[r.subregion_id]) {
          res[r.subregion_id].push(r);
        } else res[r.subregion_id] = [r];
        return res;
      }, {});
      return {
        ...state,
        appellations,
      };
    }
    case 'ADD_APPELLATION': {
      const { country_id, region_id, subregion_id, appelllation } = action.payload;
      const { appellations } = state;
      const count = appellations.length;
      const data = {
        id: count + 1,
        country_id,
        region_id,
        subregion_id,
        appelllation,
      };
      return {
        ...state,
        appellations: [...appellations, data],
      };
    }
    case 'DELETE_APPELLATION': {
      const { id } = action.payload;
      const { appellations } = state;

      return {
        ...state,
        appellations: appellations.filter((i) => i.id !== id),
      };
    }
    default:
      return state;
  }
};

const winenameReducer = (state: { winenames: WineNames[] }, action: Action) => {
  switch (action.type) {
    case 'POPULATE_WINENAMES': {
      const wineNames = action.payload;
      return {
        ...state,
        wineNames,
      };
    }
    default:
      return state;
  }
};

const winepackageReducer = (state: { winepackage: Package[] }, action: Action) => {
  switch (action.type) {
    case 'POPULATE_WINEPACKAGE': {
      const winepackage = action.payload;
      return {
        ...state,
        winepackage,
      };
    }
    case 'DELETE_WINE_PACKAGE': {
      const { winepackage } = state;
      return {
        ...state,
        winepackage: winepackage.filter((i) => i.id !== action.payload),
      };
    }
    default:
      return state;
  }
};

const winesocialPostReducer = (state: { socialpost: Package[] }, action: Action) => {
  switch (action.type) {
    case 'POPULATE_SOCIAL_POST': {
      const socialpost = action.payload;
      return {
        ...state,
        socialpost,
      };
    }
    case 'DELETE_SOCIAL_POST': {
      const { socialpost } = state;
      return {
        ...state,
        socialpost: socialpost.filter((i) => i.id !== action.payload),
      };
    }
    default:
      return state;
  }
};

const winegrapevineReducer = (state: { grapevine: Package[] }, action: Action) => {
  switch (action.type) {
    case 'POPULATE_GRAPE_VINE': {
      const grapevine = action.payload;
      return {
        ...state,
        grapevine,
      };
    }
    case 'EDIT_GRAPE_VINE': {
      const { grapevine } = state;
      const { id } = action.payload;
      const data = action.payload;
      const findIndex = grapevine.findIndex((i) => i.id === id);
      const newgrapevine = [
        ...grapevine.slice(0, findIndex),
        { ...grapevine[findIndex], ...data },
        ...grapevine.splice(findIndex + 1),
      ];
      return {
        ...state,
        grapevine: newgrapevine,
      };
    }
    case 'DELETE_GRAPE_VINE': {
      const { grapevine } = state;
      return {
        ...state,
        grapevine: grapevine.filter((i) => i.id !== action.payload),
      };
    }
    default:
      return state;
  }
};

const winesReducer = (state: { wines: AdminWine[] }, action: Action) => {
  switch (action.type) {
    case 'POPULATE_WINES': {
      const wines = action.payload;
      return {
        ...state,
        wines,
      };
    }
    case 'DELETE_WINES': {
      const { wines } = state;
      return {
        ...state,
        featuredwine: wines.filter((i) => i.id !== action.payload),
      };
    }
    default:
      return state;
  }
};

const featuredwineReducer = (state: { featuredwine: FeaturedWine[] }, action: Action) => {
  switch (action.type) {
    case 'POPULATE_FEATURED_WINE': {
      const featuredwine = action.payload;
      return {
        ...state,
        featuredwine,
      };
    }
    case 'DELETE_FEATURED_WINE': {
      const { featuredwine } = state;
      return {
        ...state,
        featuredwine: featuredwine.filter((i) => i.featured_id !== action.payload),
      };
    }
    default:
      return state;
  }
};

function combineReducers(reducers: {
  [arg0: string]: (state: any, action: Action) => Record<string, any>;
}) {
  return (state: any, action: Action) =>
    Object.keys(reducers).reduce(
      (acc, key) => ({ ...acc, [key]: reducers[key](state[key], action) }),
      {}
    );
}

export const initialState = {
  country: {},
  region: {},
  subregion: {},
  winecategory: {},
  winebody: {},
  winecolor: {},
  varietal: {},
  winevarietal: {},
  foodcategory: {},
  wineappellation: {},
  winevineyard: {},
  winenames: {},
  winepackage: {},
  socialpost: {},
  grapevine: {},
  featuredwine: {},
  wines: {},
  wine_type: {},
  wine_sweetness: {},
};

export const rootReducer = combineReducers({
  country: countryReducer,
  region: regionReducer,
  subregion: subregionReducer,
  winebody: winebodyReducer,
  winecolor: winecolorReducer,
  winecategory: winecategoryReducer,
  varietal: varietalReducer,
  winevarietal: wineVarietalReducer,
  foodcategory: foodcategoryReducer,
  wineappellation: wineappellationReducer,
  winevineyard: winevineyardReducer,
  winenames: winenameReducer,
  winepackage: winepackageReducer,
  socialpost: winesocialPostReducer,
  grapevine: winegrapevineReducer,
  featuredwine: featuredwineReducer,
  wines: winesReducer,
  wine_type: winetypeReducer,
  wine_sweetness: winesweetnessReducer,
});
