/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { TextInputProps } from 'react-native';
import PasswordHide from '../assets/passwordHide';
import PasswordShow from '../assets/passwordShow';

import TextInput from './TextInput';
import IconButton from './IconButton';

type Props = TextInputProps & {
  onChange: (v: string | number) => void;
  error?: string;
  onChangeEnd?: (value: string) => void;
};

const PasswordInput = React.forwardRef((props: Props, ref: any) => {
  const [secure, setSecure] = useState(true);

  const Icon = secure ? PasswordHide : PasswordShow;

  return (
    <TextInput
      ref={ref}
      {...props}
      secureTextEntry={secure}
      extraComponent={
        <IconButton width={32} Icon={Icon} height={32} onPress={() => setSecure((prev) => !prev)} />
      }
    />
  );
});

export default PasswordInput;
