import React, { useMemo } from 'react';
import { StyleSheet, ViewStyle } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { colors } from '../styles';

import ScreenHeader from './ScreenHeader';

type Props = {
  top?: boolean;
  bottom?: boolean;
  headerTitle?: string;
  renderBack?: boolean;
  children: React.ReactNode;
  headerLeft?: React.ReactNode;
  headerRight?: React.ReactNode;
  headerSubtitle?: string;
  backgroundColor?: string;
  style?: ViewStyle;
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.temp.primary.white,
  },
});

export default function Page({
  children,
  headerLeft,
  headerTitle,
  headerRight,
  headerSubtitle,
  top = true,
  bottom = true,
  renderBack = true,
  backgroundColor,
  style,
}: Props) {
  const resolvedEdges: any = useMemo(() => {
    if (top && bottom) return ['top', 'bottom', 'left', 'right'];
    if (top) return ['top', 'left', 'right'];
    if (bottom) return ['bottom', 'left', 'right'];
    return ['left', 'right'];
  }, [bottom, top]);

  const backColor = backgroundColor || colors.temp.primary.white;

  return (
    <SafeAreaView
      style={[styles.container, { backgroundColor: backColor }, style]}
      edges={resolvedEdges}
    >
      {headerTitle && (
        <ScreenHeader
          insets={false}
          Left={headerLeft}
          title={headerTitle}
          Right={headerRight}
          renderBack={renderBack}
          subtitle={headerSubtitle}
          backgroundColor={backgroundColor}
        />
      )}
      {children}
    </SafeAreaView>
  );
}
