import React, { useEffect, useRef } from 'react';
import {
  TouchableOpacity,
  View,
  StyleSheet,
  Animated,
  Easing,
  TextStyle,
  ViewStyle,
  ImageStyle,
} from 'react-native';
import Svg, { Circle } from 'react-native-svg';

import { colors, TextStyles } from '../styles';
import { getScreenDimension, isMobile } from '../utils';
import SommdText from './SommdText';

const AnimatedCircle = Animated.createAnimatedComponent(Circle);
const width = getScreenDimension();
const DEFAULT_SIZE = Math.ceil(width / 4);
const DEFAULT_WIDTH = 10;
const DEFAULT_OPACITY = 0;
const DEFAULT_SCORE = 100;
const ANIMATION_DURATION = 1000;

export default function Progress({
  size = DEFAULT_SIZE,
  strokeWidth = DEFAULT_WIDTH,
  opacity = DEFAULT_OPACITY,
  animated = true,
  score = DEFAULT_SCORE,
  color = colors.brand,
  children,
  containerStyle,
  svgStyle,
  childrenStyle,
  onPress,
}: {
  size?: number;
  strokeWidth?: number;
  opacity?: number;
  score?: number;
  color?: string;
  children?: React.ReactNode;
  containerStyle?: ViewStyle;
  labelStyle?: TextStyle;
  svgStyle?: ImageStyle;
  childrenStyle?: TextStyle | ViewStyle;
  animated?: boolean;
  onPress?: () => void;
}) {
  const progress = useRef(new Animated.Value(0)).current;
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const strokeDasharray = `${circumference} ${circumference}`;

  const initial = Math.PI * 2;
  const final = (Math.PI * 2 * (100 - score)) / 100;

  const alpha = progress.interpolate({
    inputRange: [0, 1],
    outputRange: [initial, final],
  });

  useEffect(() => {
    if (animated) {
      Animated.timing(progress, {
        toValue: 1,
        easing: Easing.linear,
        duration: ANIMATION_DURATION,
        useNativeDriver: isMobile,
      }).start();
    }
  }, []);

  const commonProps = {
    stroke: color,
    fill: 'transparent',
    cx: size / 2,
    cy: size / 2,
    r: radius,
    strokeWidth,
    strokeDasharray,
  };

  const animatedStrokeDashoffset = Animated.multiply(alpha, radius);
  const strokeDashoffset = final * radius;

  return (
    <View style={[styles.container, containerStyle]}>
      <TouchableOpacity onPress={onPress}>
        <Svg height={size} width={size} style={[styles.svg, svgStyle]}>
          <Circle {...{ ...commonProps, strokeOpacity: opacity }} />
          {animated ? (
            <AnimatedCircle
              {...{
                ...commonProps,
                strokeDashoffset: animatedStrokeDashoffset,
              }}
            />
          ) : (
            <Circle {...{ ...commonProps, strokeDashoffset }} />
          )}
        </Svg>

        <View
          style={[
            styles.innerContainer,
            {
              width: size,
              height: size,
              borderRadius: size / 2,
            },
            children ? childrenStyle : {},
          ]}
        >
          {children || (
            <SommdText style={[TextStyles.smaller, TextStyles.center, childrenStyle]}>
              {score}
            </SommdText>
          )}
        </View>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  label: {
    paddingBottom: 8,
    width: 75,
    textAlign: 'center',
  },
  svg: {
    transform: [{ rotate: '-90deg' }],
  },
  innerContainer: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
