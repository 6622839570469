/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  StyleProp,
  TextStyle,
  TouchableOpacity,
  TouchableOpacityProps as RNTouchableOpacityProps,
} from 'react-native';

import { BtnStyles, colors, TextStyles } from '../styles';
import { renderProps, RenderPropsType } from '../utils';
import IconResolver from './IconResolver';

import Text from './Text';

type TouchableOpacityProps = RNTouchableOpacityProps & {
  type?: 'primary' | 'secondary' | 'tertiary';
  title: string;
  icon?: any;
  textStyle?: StyleProp<TextStyle>;
};

const textStyles = {
  primary: {
    color: colors.temp.primary.white,
  },
  secondary: {
    color: colors.temp.secondary.bordeaux.dark,
    fontSize: 22,
    lineHeight: 23.72,
  },
  tertiary: { color: colors.temp.secondary.bordeaux.dark },
};

const disabledColor = colors.temp.secondary.brown.normal;
// const clickedColor = colors.temp.secondary.bordeaux.dark;

export default function SommdButton({
  type = 'primary',
  title,
  style,
  textStyle,
  icon,
  disabled,
  ...rest
}: TouchableOpacityProps & RenderPropsType) {
  const btnStyle = BtnStyles[type];
  const txtStyle = textStyles[type];
  const ICON = 24;

  return (
    <TouchableOpacity
      {...renderProps({
        ...rest,
        style: [
          btnStyle,
          disabled && type === 'primary' && { backgroundColor: disabledColor },
          style,
        ],
        disabled,
      })}
      hitSlop={{ top: 16, bottom: 16, left: 16, right: 16 }}
    >
      {icon && (
        <IconResolver style={{ paddingHorizontal: ICON }} Icon={icon} width={ICON} height={ICON} />
      )}
      <Text
        style={[
          txtStyle,
          disabled && type !== 'primary' && { color: disabledColor },
          TextStyles.button,
          textStyle,
        ]}
      >
        {title}
      </Text>
    </TouchableOpacity>
  );
}
