import React, { useCallback, useEffect, useRef } from 'react';
import { Animated, Dimensions, Keyboard, Platform, StyleSheet, TextInput } from 'react-native';

const { State: TextInputState } = TextInput;

export default function KeyboardShift({ children }: { children: React.ReactNode }) {
  const shift = useRef(new Animated.Value(0)).current;

  const handleKeyboardDidShow = useCallback(
    (event: any) => {
      if (Platform.OS !== 'ios') return;
      const { height: windowHeight } = Dimensions.get('window');
      const keyboardHeight = event.endCoordinates.height;
      const currentlyFocusedField = TextInputState.currentlyFocusedInput();
      // @ts-ignore
      currentlyFocusedField.measure((originX, originY, width, height, pageX, pageY) => {
        const fieldHeight = height;
        const fieldTop = pageY;
        const gap = windowHeight - keyboardHeight - (fieldTop + fieldHeight) - 200;
        if (gap >= 0) {
          return;
        }
        Animated.timing(shift, {
          toValue: gap,
          duration: 100,
          useNativeDriver: true,
        }).start();
      });
    },
    [shift]
  );

  const handleKeyboardDidHide = useCallback(() => {
    if (Platform.OS !== 'ios') return;
    Animated.timing(shift, {
      toValue: 0,
      duration: 100,
      useNativeDriver: true,
    }).start();
  }, [shift]);

  useEffect(() => {
    const keyboardDidShowSub = Keyboard.addListener('keyboardDidShow', handleKeyboardDidShow);
    const keyboardDidHideSub = Keyboard.addListener('keyboardDidHide', handleKeyboardDidHide);
    return () => {
      keyboardDidShowSub.remove();
      keyboardDidHideSub.remove();
    };
  }, [handleKeyboardDidHide, handleKeyboardDidShow]);

  return (
    <Animated.View style={[styles.container, { transform: [{ translateY: shift }] }]}>
      {children}
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
});
