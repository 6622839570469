import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

function SvgComponent(props: any) {
  return (
    <Svg viewBox="0 0 77 141" {...props}>
      <G fill="none" fillRule="evenodd">
        <Path
          d="M11.666 74.53c5.917 5.738 25.611 15.547 33.003 11.124 7.393-4.424 27.004-13.572 28.532-25.221 1.527-11.65 1.02-18.218-1.958-25.729-2.978-7.512-16.221 1.499-21.303 11.996-5.081 10.495-29.936 16.533-45.907 6.657C-2.07 46.34 5.75 68.791 11.666 74.53"
          fill="#91191B"
        />
        <Path
          d="M34.215 49.97c7.013-.58 22.046-15.349 24.55-12.6-7.197 4.977-6.315 15.141-24.048 21.428-15.424 3.302-39.166-4.7-31.85-11.052 9.749-8.466 24.337 2.804 31.348 2.224"
          fill="#C42124"
        />
        <Path
          d="M39.253 83.988c3.68-1.077 35.08-12.119 34.563-30.412-.858-30.424-13.476-47.947-16.15-52.12l-40.582-.195c-1.114 1.296-7.281 9.586-9.272 15.195-2.937 8.274-6.31 21.81-4.629 38.062 2.401 23.191 32.402 28.626 36.07 29.47m22.294-6.491c-1.2.84-2.472 1.659-3.811 2.456-9.228 5.49-18.363 7.98-18.747 8.084l-.343.092-.347-.07C37.921 87.98.545 80.092.017 51.818-.592 19.211 15.496 1.984 16.182 1.267l.436-.457L58.166.022l.463.682c.71 1.043 17.396 25.867 17.887 52.15.173 9.199-4.984 17.653-14.969 24.643"
          fill="#FFF"
        />
        <Path
          fill="#FFF"
          d="m40.277 90.734-.136 1.34.62 33.143.068 3.682-5.063-.186 1.3-35.732-.467 35.148 1.09.481 1.152-.806-.688-36.828-6.796-2.704 7.05 1.283 6.15-1.817-2.37 1.658M8.638 60.648c-.596-4.169-2.263-15.826-.482-25.778 1.018-5.687 3.309-18.483 9.829-30.51l1.667.084c-5.258 13.196-6.64 19.565-8.675 30.94-2.037 11.373-.02 25.348.805 28.039.825 2.69 1.478 3.94 6.237 9.359-3.857-2.32-8.734-7.611-9.38-12.134"
        />
        <Path
          d="m60.57 139.203-20.28.21-2.074.022s8.6-1.3 8.97-1.304l9.546-.465c.37-.004 4.484-.653 5.062.187.578.84-1.224 1.35-1.224 1.35"
          fill="#D4145A"
        />
        <Path
          d="m37.08 133.411-.226.32.467 1.104-.585.834 1.653 1.18-12.864.277s5.12-1.098 6.982-2.4l4.994-3.496 1.409.257-1.83 1.924Zm-3.675 6.253-19.346.513c-.365.012-1.41-.256-.577-.839.832-.583 3.695-.65 3.695-.65l8.562-.018c1.154-.012 9.718.93 9.718.93l-2.052.064Zm27.292-3.547c-9.32.073-13.655-2.128-19.174-6.242l-6.094.065c-5.886 4.361-11.72 6.757-19.622 6.906 0 0-4.059-.026-3.736 2.236.324 2.26 3.813 1.864 3.813 1.864l44.887-.852s2.18-.563 2.434-1.984c.254-1.423-2.431-2.06-2.508-1.993Z"
          fill="#FFF"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
