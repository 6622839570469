import React from 'react';
import { StyleSheet } from 'react-native';

import { colors, TextStyles } from '../styles';

import SommdButton from './SommdButton';
import SommdText from './SommdText';
import View from './View';

export default function Section({
  header,
  onPress,
  children,
  moreData,
  backgroundColor,
}: {
  header?: string;
  onPress?: () => void;
  backgroundColor?: string;
  moreData?: boolean;
  children?: React.ReactNode;
}) {
  return (
    <>
      <View padding="large" style={[styles.sectionContainer, { backgroundColor }]}>
        <SommdText style={TextStyles.extraBoldSmall}>{header}</SommdText>
        {onPress && moreData && (
          <SommdButton
            title="See All"
            type="secondary"
            onPress={onPress}
            textStyle={[TextStyles.bold, styles.cta]}
          />
        )}
      </View>
      <View bPadding="large" style={[{ backgroundColor }]}>
        {children}
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  sectionContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cta: { color: colors.temp.secondary.bordeaux.light },
});
