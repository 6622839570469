import React from 'react';
import { Image, ImageStyle, ImageSourcePropType } from 'react-native';

export interface IconResolverProps {
  style?: ImageStyle;
  width?: number;
  height?: number;
  dimension?: number;
  color?: string;
  Icon: React.ComponentType<any> | ImageSourcePropType;
}

const iconDimension = {
  width: 24,
  height: 24,
};

export default function IconResolver({
  style,
  Icon,
  width,
  height,
  dimension,
  color = 'white',
}: IconResolverProps) {
  if (width) iconDimension.width = width;
  if (height) iconDimension.height = height;
  if (dimension) {
    iconDimension.width = dimension;
    iconDimension.height = dimension;
  }

  return typeof Icon === 'function' ? (
    <Icon {...{ ...iconDimension, style, color }} />
  ) : (
    <Image style={[iconDimension, style]} resizeMode="contain" source={Icon} />
  );
}
