import { StyleSheet } from 'react-native';
import colors from './colors';

const shadow = {
  shadowRadius: 24,
  shadowOpacity: 0.07,
  shadowOffset: { width: 0, height: -5 },
  elevation: 20,
  shadowColor: 'black',
};

export const LayoutStyles = StyleSheet.create({
  center: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  shadow,
  background: {
    backgroundColor: colors.temp.primary.white,
  },
});

export const IconStyles = {
  regular: {
    width: 20,
    height: 20,
  },
  large: {
    width: 32,
    height: 32,
  },
};
