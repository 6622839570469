import React from 'react';
import 'react-native-gesture-handler';
import { write } from '@lib/storage';
import { DEFAULT_SCREEN, ONBOARDING_INIT_DATA_KEY } from './constants';
import { handleBack } from './utils';

import {
  ForgotPassword,
  Login,
  Signup,
  Verification,
  Onboarding,
  ProductDetail,
  PairingDetail,
  SearchByImage,
  Profile,
  AddressForm,
  FlavourProfile,
  Invitations,
  Orders,
  Addresses,
  BiometricLogin,
  WelcomeScreen,
  AddWine,
  FilterScreen,
  EditEmail,
  ScanResult,
  FavoriteWines,
  Admin,
} from './Lazy';
import { BottomTabs } from './BottomTabs';
import CellarCategory from './screens/CellarCategory';

export interface Screen {
  name: string;
  Comp: React.FC<any>;
  route?: 'restricted' | 'protected';
  options?: any;
  config?: any;
}

export const AUTH_SCREENS: Screen[] = [
  { name: 'Login', Comp: Login, route: 'restricted' },
  { name: 'Signup', Comp: Signup, route: 'restricted' },
  { name: 'Welcome', Comp: WelcomeScreen, route: 'restricted' },
  { name: 'Verification', Comp: Verification, route: 'restricted' },
  { name: 'ForgotPassword', Comp: ForgotPassword, route: 'restricted' },
];
export const SCREENS: Screen[] = [
  {
    name: 'BottomTabs',
    Comp: BottomTabs,
    route: 'protected',
  },
  {
    name: 'Onboarding',
    Comp: Onboarding,
    config: {
      onComplete: async ({ navigation }: { navigation: any }) => {
        await write(ONBOARDING_INIT_DATA_KEY, '');
        navigation.replace(DEFAULT_SCREEN);
      },
    },
    route: 'protected',
  },
  {
    name: 'EditPreferences',
    Comp: Onboarding,
    config: {
      onComplete: ({ navigation }: { navigation: any }) => handleBack(navigation),
      onBack: ({ navigation }: { navigation: any }) => handleBack(navigation),
      onSkip: true,
    },
    route: 'protected',
  },
  { name: 'FavoriteWines', Comp: FavoriteWines, route: 'protected' },
  { name: 'EditEmail', Comp: EditEmail, route: 'protected' },
  { name: 'AddWine', Comp: AddWine, route: 'protected' },
  { name: 'FilterScreen', Comp: FilterScreen, route: 'protected' },
  { name: 'Search', Comp: SearchByImage, route: 'protected' },
  { name: 'Scan', Comp: ScanResult, route: 'protected' },
  { name: 'ProductDetail', Comp: ProductDetail, route: 'protected' },
  { name: 'PairingDetail', Comp: PairingDetail, route: 'protected' },
  { name: 'CellarCategory', Comp: CellarCategory, route: 'protected' },
  {
    name: 'Profile',
    Comp: Profile,
    route: 'protected',
  },
  { name: 'AddressForm', Comp: AddressForm, route: 'protected' },
  { name: 'Invitations', Comp: Invitations, route: 'protected' },
  { name: 'Addresses', Comp: Addresses, route: 'protected' },
  { name: 'FlavourProfile', Comp: FlavourProfile, route: 'protected' },
  { name: 'BiometricLogin', Comp: BiometricLogin, route: 'protected' },
  { name: 'Orders', Comp: Orders, route: 'protected' },
  { name: 'Admin', Comp: Admin, route: 'protected' },
];

export const LINKING = {
  prefixes: ['https://wineapp.com', 'wineapp://'],
  config: {
    screens: {
      DetailNavigation: {
        path: '',
        screens: {
          Landing: { path: '' },
          Login: {
            path: 'login/:referral?',
            stringify: {
              referral: () => '',
            },
          },
          Signup: 'signup',
          Verification: 'verification',
          ForgotPassword: 'forgotpass',
          Onboarding: 'preferences',
          EditPreferences: 'editPreferences',
          ProductDetail: 'product/:id',
          PairingDetail: 'pairing/:id',
          Invitations: 'invitaions',
          Addresses: 'addresses',
          FlavourProfile: 'flavourProfile',
          CellarCategory: 'cellarCategory',
          AddWine: 'addwine',
          FilterScreen: 'filterscreen',
          Orders: 'orders',
          Admin: 'admin',
          Profile: 'profile',
          EditEmail: 'editemail',
          ScanResult: 'scan',
          Search: {
            path: 'search/:type?/:value?',
          },
          BottomTabs: {
            path: 'tabs',
            screens: {
              Pairing: {
                path: 'pairing',
                screens: {
                  ByWine: 'wine',
                  ByCuisine: 'cuisine',
                },
              },
              Cart: 'cart',
              Cellar: 'cellar',
              Home: 'home',
              Camera: 'camera',
            },
          },
        },
      },
    },
  },
};
