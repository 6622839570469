import React, { useState, useRef, useCallback, useEffect } from 'react';
import {
  Animated,
  StyleSheet,
  FlatList,
  ListRenderItem,
  ViewStyle,
  TextStyle,
  Pressable,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { colors } from '../styles';
import Back from '../assets/back';

import SommdButton from './SommdButton';
import IconButton from './IconButton';
import View from './View';

const dotSize = 8;

const Slider = React.forwardRef(
  (
    {
      data,
      errors,
      onSkip,
      onClose,
      spacing = 0,
      ctaStyle,
      imageSize,
      btnText,
      onComplete,
      loading = false,
      renderItem,
      keyExtractor,
      indicatorsStyle,
      ctaContainerStyle,
      contentContainerStyle,
      ItemSeparatorComponent,
      EmptyComponent,
      indicatorColor = colors.temp.primary.white,
    }: {
      data: any[];
      errors?: any;
      onComplete?: any;
      spacing?: number;
      imageSize: number;
      btnText?: string;
      loading?: boolean;
      onSkip?: () => void;
      ctaStyle?: TextStyle;
      onClose?: () => void;
      indicatorColor?: string;
      indicatorsStyle?: ViewStyle;
      ctaContainerStyle?: ViewStyle;
      renderItem: ListRenderItem<any>;
      contentContainerStyle?: ViewStyle;
      keyExtractor: (item: any) => string;
      EmptyComponent?: React.ComponentType<any>;
      ItemSeparatorComponent?: React.ComponentType<any>;
    },
    ref: any
  ) => {
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const indexRef = useRef(currentSlideIndex);
    indexRef.current = currentSlideIndex;
    const scrollX = useRef(new Animated.Value(0)).current;

    const { top } = useSafeAreaInsets();

    const onScroll = useCallback(
      (event) => {
        const {
          contentOffset: { x },
        } = event.nativeEvent;

        const index = x / imageSize;
        const roundIndex = Math.round(index);
        const distance = Math.abs(roundIndex - index);

        // Prevent one pixel triggering setIndex in the middle
        // of the transition. With this we have to scroll a bit
        // more to trigger the index change.
        const isNoMansLand = distance > 0.4;

        if (roundIndex !== indexRef.current && !isNoMansLand) {
          setCurrentSlideIndex(roundIndex);
        }
      },
      [imageSize]
    );

    const handleNext = () => {
      if (currentSlideIndex >= data.length - 1) return;
      ref.current.scrollToIndex({ index: currentSlideIndex + 1 });
    };

    useEffect(() => {
      const index = Number(Object.keys(errors || {})[0]);
      if (index) {
        ref.current.goTo(index - 1);
      }
    }, [errors, ref]);

    return (
      <>
        <FlatList
          ref={ref}
          horizontal
          data={data}
          pagingEnabled
          snapToEnd={false}
          decelerationRate="fast"
          renderItem={renderItem}
          style={styles.container}
          scrollEventThrottle={16}
          snapToInterval={imageSize}
          keyExtractor={keyExtractor}
          getItemLayout={(_, index) => ({
            length: imageSize,
            offset: imageSize * index,
            index,
          })}
          showsHorizontalScrollIndicator={false}
          ItemSeparatorComponent={ItemSeparatorComponent}
          contentContainerStyle={[contentContainerStyle, { paddingHorizontal: spacing }]}
          onScroll={Animated.event([{ nativeEvent: { contentOffset: { x: scrollX } } }], {
            useNativeDriver: false,
            listener: onScroll,
          })}
          ListEmptyComponent={EmptyComponent}
        />
        <View style={[styles.indicatorContainer, indicatorsStyle]}>
          {Array.from({ length: data.length }).map((_, idx) => {
            const translateX = scrollX.interpolate({
              inputRange: [
                (imageSize - 2 * spacing) * (idx - 1),
                (imageSize - 2 * spacing) * (idx + 1),
              ],
              outputRange: [-dotSize, dotSize],
              extrapolate: 'clamp',
            });

            return (
              <Pressable
                key={`dot${idx + 1}`}
                onPress={() => ref.current.scrollToIndex({ index: idx })}
                style={[
                  {
                    width: dotSize,
                    height: dotSize,
                    borderRadius: dotSize / 2,
                    backgroundColor: `${indicatorColor}4D`,
                    marginLeft: idx === 0 ? 0 : dotSize / 2,
                  },
                  styles.dotOuter,
                ]}
              >
                <Animated.View
                  key={`dot${idx + 1}`}
                  style={[
                    {
                      width: dotSize,
                      height: dotSize,
                      transform: [{ translateX }],
                      backgroundColor: indicatorColor,
                    },
                    styles.dotInner,
                  ]}
                />
              </Pressable>
            );
          })}
        </View>
        {onSkip && currentSlideIndex < data.length - 1 && (
          <SommdButton
            title="Skip"
            type="tertiary"
            tPadding="large"
            onPress={onSkip}
            textStyle={styles.cta}
            style={[styles.tertiaryCtaContainer, { top }]}
          />
        )}
        {onClose && (
          <IconButton
            Icon={Back}
            width={20}
            height={20}
            onPress={onClose}
            color={colors.temp.secondary.brown.normal}
            containerStyle={{ ...styles.backContainer, top }}
          />
        )}
        {onComplete &&
          (currentSlideIndex < data.length - 1 ? (
            <SommdButton
              title="Next"
              type="secondary"
              onPress={handleNext}
              textStyle={[styles.cta, ctaStyle]}
              style={[styles.ctaContainer, ctaContainerStyle]}
            />
          ) : (
            <SommdButton
              type="primary"
              disabled={loading}
              title={btnText || 'Get Started'}
              onPress={onComplete}
              textStyle={styles.cta}
              style={[styles.ctaContainer, ctaContainerStyle]}
            />
          ))}
      </>
    );
  }
);

export default Slider;

const styles = StyleSheet.create({
  container: {
    // backgroundColor: colors.temp.primary.white,
  },
  indicatorContainer: {
    zIndex: 2,
    bottom: 200,
    width: '100%',
    position: 'absolute',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  dotOuter: {
    overflow: 'hidden',
  },
  dotInner: { position: 'absolute', left: 0, top: 0 },
  tertiaryCtaContainer: {
    position: 'absolute',
    right: 24,
  },
  backContainer: {
    position: 'absolute',
    left: 24,
  },
  cta: {
    color: colors.temp.primary.white,
  },
  ctaContainer: {
    position: 'absolute',
    alignSelf: 'center',
    bottom: 100,
  },
});
