import React from 'react';
import { View, Image, StyleSheet, ViewStyle, Text } from 'react-native';

import { useImageDimension } from '../hooks';

export default function SommdImage({
  uri,
  containerStyle,
  aspectRatio = true,
  multiple = 0,
}: {
  uri: string | number;
  aspectRatio?: boolean;
  containerStyle?: ViewStyle | ViewStyle[];
  multiple?: number;
}) {
  const { source, width: w, height: h } = useImageDimension(uri);

  return (
    <View>
      {multiple ? (
        <View style={[styles.shadowProp, styles.counter]}>
          <Text>x {multiple}</Text>
        </View>
      ) : null}
      <View style={[styles.container, containerStyle]}>
        <Image
          source={source}
          style={[styles.image, { ...(aspectRatio && { aspectRatio: w && h && w / h }) }]}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: 150,
    overflow: 'hidden',
    alignItems: 'center',
  },
  image: {
    width: '100%',
    height: '100%',
  },
  counter: {
    position: 'absolute',
    zIndex: 9999,
    padding: 5,
    backgroundColor: 'white',
    borderRadius: 50,
    top: 50,
    right: 8,
  },
  shadowProp: {
    shadowColor: '#171717',
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
  },
});
