import React, { useState } from 'react';
import { StyleSheet } from 'react-native';

import { Button, TextInput, Modal } from '../components';

export default function ConfirmationModal({ title, onClose }: {
  title: string,
  onClose?: () => void,
}) {
  const [text, setText] = useState<string>('');

  return (
    <Modal title={title} visible={!!title} transparent onClose={onClose}>
      <TextInput
        style={styles.modalInput}
        placeholder="Tell us more..."
        value={text} onChange={(v: any) =>setText(v)} 
      />
      <Button title="Confirm" onPress={onClose} />
    </Modal>
  )
}


const styles = StyleSheet.create({
  modalInput: {
    marginVertical: 32,
  }
})

