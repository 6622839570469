import { StyleSheet, ViewStyle } from 'react-native';
import colors from './colors';

const common: ViewStyle = {
  borderWidth: 1,
  padding: 12,
  borderRadius: 8,
  alignItems: 'center',
};

export const ButtonStyles = StyleSheet.create({
  primary: {
    ...common,
    borderColor: colors.brandPrimary,
    backgroundColor: colors.primaryVariant,
  },
  secondary: {
    ...common,
    borderColor: colors.brandPrimary,
    backgroundColor: colors.brandPrimary,
  },
  transparent: {
    ...common,
    borderColor: colors.primaryVariant,
    backgroundColor: 'transparent',
  },
  disabled: {
    backgroundColor: colors.disabled,
  },
});

export const BtnStyles = StyleSheet.create({
  primary: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 16,
    paddingHorizontal: 32,
    borderRadius: 55,
    backgroundColor: colors.temp.primary.bordeaux,
  },
  secondary: {
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  tertiary: {
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
});
