import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { Text, Modal, SommdButton } from '../components';

export default function CartOrCellarModal({
  loading,
  fromCellar = false,
  title,
  items,
  onClose,
  onConfirm,
}: {
  loading: boolean | undefined;
  fromCellar: boolean | undefined;
  title: string;
  items?: number;
  onClose: () => void;
  onConfirm: (arg0: number) => void;
}) {
  const [curr, setCurr] = useState<number>(items || 0);

  const adder = (add: number) => setCurr((prev) => prev + add);
  const subtractor = (subtract: number) => setCurr((prev) => prev - subtract);

  return (
    <Modal
      visible={!!title}
      title={fromCellar ? `Remove From ${title}` : `Add to ${title}`}
      onClose={onClose}
    >
      <Text>
        {fromCellar
          ? `Total items will be in ${title} : ${curr}`
          : `Total items in ${title} : ${curr}`}
      </Text>
      <View style={styles.container}>
        <Row
          value={curr}
          count={1}
          add={() => adder(1)}
          sub={() => subtractor(1)}
          fromCellar={fromCellar}
        />
        <Row
          value={curr}
          count={6}
          add={() => adder(6)}
          sub={() => subtractor(6)}
          fromCellar={fromCellar}
        />
        <Row
          value={curr}
          count={12}
          add={() => adder(12)}
          sub={() => subtractor(12)}
          fromCellar={fromCellar}
        />
      </View>
      <SommdButton title="Confirm" onPress={() => onConfirm(curr)} disabled={curr < 0 || loading} />
    </Modal>
  );
}

function Row({
  count,
  value,
  add,
  sub,
  fromCellar = false,
}: {
  count: number;
  add: () => void;
  sub: () => void;
  value: number;
  fromCellar?: boolean;
}) {
  return (
    <View style={styles.rowContainer}>
      {!fromCellar && <SommdButton title={`Add ${count}`} onPress={add} />}
      <SommdButton title={`Remove ${count}`} onPress={sub} disabled={value < count} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 24,
  },
  rowContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 8,
  },
});
