import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props: any) {
  return (
    <Svg width={25} height={24} viewBox="0 0 25 24" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.373 4.878a5.387 5.387 0 0 1 2.337 0c.666.148 1.14.74 1.14 1.422v1.236h2V6.3a3.457 3.457 0 0 0-2.706-3.374 7.387 7.387 0 0 0-3.205 0A3.457 3.457 0 0 0 8.232 6.3v1.236h2V6.3c0-.683.474-1.274 1.14-1.422ZM2.75 8.25h19.5l-.2 2-.642 6.399a4 4 0 0 1-3.98 3.601H7.308a4 4 0 0 1-3.99-3.726l-.43-6.274-.138-2Zm2.563 8.137-.421-6.137H8.75v8H7.308a2 2 0 0 1-1.995-1.863Zm5.437 1.863v-8h4v8h-4Zm6 0h.678a2 2 0 0 0 1.99-1.8l.622-6.2h-3.29v8Z"
        fill="#fff"
      />
    </Svg>
  );
}

export default SvgComponent;
