import { Dimensions, Platform, StyleProp, TextStyle, ViewStyle } from 'react-native';
import { DEFAULT_REFERRAL, DEFAULT_SCREEN, DEFAULT_TAB } from '../constants';

export function pluralize(val: number, keyword: string) {
  if (val < 2) return `${val} ${keyword}`;
  return `${val} ${keyword}s`;
}

export function uppercase(v: string) {
  return v
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
    .join(' ');
}

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function getReferral(params: { [arg0: string]: string }) {
  if (!params) return DEFAULT_REFERRAL;
  return params.referral || DEFAULT_REFERRAL;
}

export const getScreenDimension = (): number =>
  Platform.select({
    web: Dimensions.get('window').width,
    android: Dimensions.get('screen').width,
    ios: Dimensions.get('screen').width,
  }) || 0;

export const isMobile = Platform.OS === 'android' || Platform.OS === 'ios';
export const handleBack = (navigation: any) => {
  if (navigation.canGoBack()) navigation.goBack();
  else navigation.push(DEFAULT_SCREEN, { screen: DEFAULT_TAB });
};

export function formatCellatWines(cellar_wines: any) {
  if (!cellar_wines?.wines) return null;
  const { wines = [] } = cellar_wines;
  const formatted_cellar_wines = wines.reduce(
    (acc: any, cuu: any) => {
      const { country, varietal, color } = cuu;

      if (!(color in acc.wine_type)) acc.wine_type[color] = [cuu];
      else acc.wine_type[color].push(cuu);

      if (!(country in acc.country)) acc.country[country] = [cuu];
      else acc.country[country].push(cuu);

      if (!(varietal in acc.varietals)) acc.varietals[varietal] = [cuu];
      else acc.varietals[varietal].push(cuu);

      // if (count <= 6) acc.running_low.push(cuu);

      return acc;
    },
    { country: {}, wine_type: {}, varietals: {} }
  );
  return formatted_cellar_wines;
}

const MAP_KEYS: Record<string, string> = {
  padding: 'padding',
  hPadding: 'paddingHorizontal',
  lPadding: 'paddingLeft',
  rPadding: 'paddingRight',
  vPadding: 'paddingVertical',
  tPadding: 'paddingTop',
  bPadding: 'paddingBottom',
  margin: 'margin',
  hMargin: 'marginHorizontal',
  vMargin: 'marginVertical',
  tMargin: 'marginTop',
  bMargin: 'marginBottom',
  lMargin: 'marginLeft',
  rMargin: 'marginRight',
};

const SPACING: Record<string, number | undefined> = {
  small: 8,
  medium: 16,
  large: 24,
  xLarge: 32,
  xxLarge: 48,
  xxxLarge: 56,
  xxxxLarge: 64,
};

const resolved = (key: string, value?: string) =>
  value ? { [MAP_KEYS[key]]: SPACING[value] } : {};

export type RenderPropsType = {
  padding?: string;
  margin?: string;
  vPadding?: string;
  hPadding?: string;
  lPadding?: string;
  rPadding?: string;
  tPadding?: string;
  bPadding?: string;
  vMargin?: string;
  hMargin?: string;
  tMargin?: string;
  bMargin?: string;
  style?: StyleProp<ViewStyle> & StyleProp<TextStyle>;
} & Record<string, any>;

export const renderProps = ({
  padding,
  margin,
  vPadding,
  tPadding,
  bPadding,
  lPadding,
  rPadding,
  hPadding,
  vMargin,
  hMargin,
  tMargin,
  bMargin,
  style,
  ...rest
}: RenderPropsType) => {
  return {
    style: [
      style,
      resolved('padding', padding),
      resolved('margin', margin),
      resolved('vPadding', vPadding),
      resolved('hPadding', hPadding),
      resolved('tPadding', tPadding),
      resolved('bPadding', bPadding),
      resolved('lPadding', lPadding),
      resolved('rPadding', rPadding),
      resolved('vMargin', vMargin),
      resolved('hMargin', hMargin),
      resolved('tMargin', tMargin),
      resolved('bMargin', bMargin),
    ],
    ...rest,
  };
};
