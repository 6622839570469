import React from 'react';
import { View } from 'react-native';
import { TextStyles } from '../styles';
import SommdText from './SommdText';
import UploadImage from './UploadImage';

type Props = {
  error?: string;
  name: string;
  onChange: (v: string | number) => void;
  placeholder?: string;
};

const ImageInput = React.forwardRef((props: Props, ref: any) => {
  const { name, onChange, placeholder, error } = props;

  return (
    <View ref={ref}>
      {/* <IconButton Icon={Outline} onPress={() => setVisible(true)} />
      <SommdText>{placeholder}</SommdText> */}
      <UploadImage name={name} onSelect={onChange} />
      <SommdText>{placeholder}</SommdText>
      {error ? (
        <SommdText vPadding="small" style={[TextStyles.error]}>
          {error || ''}
        </SommdText>
      ) : null}
    </View>
  );
});

export default ImageInput;
