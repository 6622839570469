import { ElementType } from 'react';
import TextInput from './TextInput';
import PasswordInput from './PasswordInput';
import OnboardingInput from './OnboardingInput';
// import PickerInput from './PickerInput';
import ImageInput from './ImageInput';
import SearchableTextInput from './SearchableTextInput';
import DropSelect from './DropSelect';
import BooleanInput from './BooleanInput';

const inputTypes: { [arg0: string]: ElementType } = {
  textinput: TextInput,
  passwordinput: PasswordInput,
  onboardinginput: OnboardingInput,
  // pickerinput: PickerInput,
  searchableinput: SearchableTextInput,
  imageinput: ImageInput,
  dropselect: DropSelect,
  booleanselect: BooleanInput,
};

export default inputTypes;
