import { StyleSheet } from 'react-native';
import colors from './colors';

const primary = {
  color: colors.primary,
};

const secondary = {
  color: colors.secondary,
};

const primaryVariant = {
  color: colors.primaryVariant,
};

const secondaryVariant = {
  color: colors.secondaryVariant,
};

const small = {
  fontSize: 14,
};

const color = { color: colors.temp.secondary.brown.normal };
const regular = { ...color, fontFamily: 'Sarabun-Regular' };
const bold = { ...color, fontFamily: 'Sarabun-Bold' };
const extraBold = { ...color, fontFamily: 'Sarabun-ExtraBold' };

export const TextStyles = StyleSheet.create({
  regular: {
    ...primary,
    fontFamily: 'SFProText-Regular',
    fontSize: 18,
  },
  small,
  smaller: {
    fontSize: 10,
  },
  medium: {
    fontSize: 18,
  },
  large: {
    fontSize: 22,
  },
  headerOne: { fontSize: 60 },
  headerTwo: { fontSize: 48 },
  headerThree: { fontSize: 30 },
  headerFour: { fontSize: 24 },
  headerFive: { fontSize: 32 },
  primary,
  primaryVariant,
  secondary,
  secondaryVariant,
  disabled: {
    opacity: 0.5,
    color: colors.primaryVariant,
  },
  // error: {
  //   ...small,
  //   color: colors.brandPrimary,
  // },
  smallPrimaryVariant: {
    ...small,
    ...primaryVariant,
  },
  smallSecondary: {
    ...small,
    ...secondary,
  },
  smallSecondaryVariant: {
    ...small,
    ...secondaryVariant,
  },

  actualNormal: {
    ...regular,
    lineHeight: 20.8,
    fontSize: 16,
  },
  normal: {
    ...regular,
    lineHeight: 21.6,
    fontSize: 18,
  },
  normalSmall: {
    ...regular,
    lineHeight: 19.6,
    fontSize: 14,
  },
  normalSmaller: {
    ...regular,
    lineHeight: 12,
    fontSize: 12,
  },
  normalSmallerBold: {
    ...extraBold,
    lineHeight: 12,
    fontSize: 12,
  },
  normalLarge: {
    ...regular,
    lineHeight: 30,
    fontSize: 24,
  },
  extraNormalLarge: {
    ...regular,
    lineHeight: 27.13,
    fontSize: 22,
  },
  bold: {
    ...bold,
    lineHeight: 23.4,
    fontSize: 18,
  },
  extraBold: {
    ...extraBold,
    lineHeight: 32,
    fontSize: 32,
  },
  extraBoldLarge: {
    ...extraBold,
    lineHeight: 40,
    fontSize: 40,
  },
  extraBoldSmall: {
    ...extraBold,
    lineHeight: 29.59,
    fontSize: 24,
  },
  extraBoldSmaller: {
    ...extraBold,
    lineHeight: 27.13,
    fontSize: 22,
  },
  uppercase: {
    ...regular,
    lineHeight: 17.26,
    fontSize: 14,
    letterSpacing: 2.24,
    textTransform: 'uppercase',
  },
  button: {
    fontFamily: 'Sarabun-ExtraBold',
    fontSize: 18,
    lineHeight: 19.4,
  },
  textinput: {
    ...regular,
    fontSize: 18,
    lineHeight: 19.4,
  },
  header: {
    fontFamily: 'Quicksand-Bold',
    fontSize: 32,
    lineHeight: 32,
    color: colors.temp.secondary.brown.normal,
  },
  error: {
    fontFamily: 'Sarabun-Regular',
    lineHeight: 15.09,
    fontSize: 14,
    color: colors.temp.secondary.bordeaux.light,
  },
  center: {
    textAlign: 'center',
  },
});
