import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props: any) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path stroke="#fff" strokeWidth={3} strokeLinecap="square" d="M6.75 12h10.5" />
    </Svg>
  );
}

export default SvgComponent;
