import React from 'react';
import { TextStyle, ViewStyle } from 'react-native';
import { TextStyles } from '../styles';
import On from '../assets/On';
import Off from '../assets/Off';

import SommdText from './SommdText';

export default function LogoHeader({
  title,
  style,
  textStyle,
  on,
}: {
  on?: boolean;
  title?: string | React.ReactNode;
  style?: ViewStyle;
  textStyle?: TextStyle;
}) {
  const Icon = on ? On : Off;

  return (
    <>
      <Icon style={style} />
      {title && (
        <SommdText style={[TextStyles.extraBold, TextStyles.center, textStyle]}>{title}</SommdText>
      )}
    </>
  );
}
