import React, { useState, useEffect, useCallback } from 'react';
import {
  Pressable,
  ViewProps,
  Dimensions,
  PressableProps,
  ScaledSize,
  View as RNView,
  ViewStyle,
} from 'react-native';

const { width } = Dimensions.get('window');

export const CONFIG = {
  breakpoints: [576, 768, 992, 1200, 1600],
  gutterWidth: 24,
  gridColumns: 12,
  defaultScreenClass: 'xs',
  maxScreenClass: 'xl',
};

export const screenClasses = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];

export const useScreenClass = () => {
  const getScreenClass = useCallback((viewport) => {
    const { breakpoints, defaultScreenClass, maxScreenClass } = CONFIG;

    let newScreenClass = defaultScreenClass;

    if (viewport) {
      newScreenClass = 'xs';
      if (viewport >= breakpoints[0]) newScreenClass = 'sm';
      if (viewport >= breakpoints[1]) newScreenClass = 'md';
      if (viewport >= breakpoints[2]) newScreenClass = 'lg';
      if (viewport >= breakpoints[3]) newScreenClass = 'xl';
      if (viewport >= breakpoints[4]) newScreenClass = 'xxl';
    }

    const newScreenClassIndex = screenClasses.indexOf(newScreenClass);
    const maxScreenClassIndex = screenClasses.indexOf(maxScreenClass);
    if (maxScreenClassIndex >= 0 && newScreenClassIndex > maxScreenClassIndex) {
      newScreenClass = screenClasses[maxScreenClassIndex];
    }

    return newScreenClass;
  }, []);

  const [screenClass, setScreenClass] = useState(() => getScreenClass(width));

  useEffect(() => {
    const handleWindowResized = ({ window }: { window: ScaledSize }) =>
      setScreenClass(getScreenClass(window.width));

    Dimensions.addEventListener('change', handleWindowResized);

    return () => Dimensions.removeEventListener('change', handleWindowResized);
  }, [getScreenClass]);

  return screenClass;
};

export function View({
  children,
  style,
  onPress,
  ...rest
}: {
  children: React.ReactChildren;
  style: ViewStyle;
} & PressableProps &
  ViewProps) {
  const Wrapper = onPress ? Pressable : RNView;

  return (
    <Wrapper onPress={onPress} style={[{ flexDirection: 'row' }, style]} {...rest}>
      {children}
    </Wrapper>
  );
}

export function getGutterWidth(width?: number, nogutter?: boolean) {
  if (nogutter) return 0;
  if (typeof width === 'number') return width;
  return CONFIG.gutterWidth;
}
