/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState, useEffect } from 'react';
import {
  View,
  StyleSheet,
  Easing,
  TextInput as RNTextInput,
  Animated,
  TextInputProps,
  TouchableWithoutFeedback,
} from 'react-native';
import Close from '../assets/closeWrong';

import { colors, TextStyles } from '../styles';
import IconButton from './IconButton';

import SommdText from './SommdText';

type Props = TextInputProps & {
  onChange: (v: string | number) => void;
  error?: string;
  extraComponent?: React.ReactNode;
  onChangeEnd?: (value: string) => void;
  keyboardType?: string;
};

const TextInput = React.forwardRef(
  (
    {
      value,
      onChange,
      placeholder,
      style,
      error,
      onChangeEnd,
      extraComponent,
      keyboardType,
      ...rest
    }: Props,
    ref: any
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    let inputRef = useRef<RNTextInput>(null)?.current;
    const focusAnim = useRef(new Animated.Value(0)).current;

    const handleChangeText = (text: string) => {
      onChange(text);
      if (onChangeEnd) onChangeEnd(text);
    };

    useEffect(() => {
      Animated.timing(focusAnim, {
        toValue: isFocused || !!value ? 1 : 0,
        duration: 100,
        easing: Easing.bezier(0.4, 0, 0.2, 1),
        useNativeDriver: true,
      }).start();
    }, [focusAnim, isFocused, value]);
    return (
      <View style={[styles.container, style]}>
        <RNTextInput
          {...rest}
          ref={(e) => {
            ref?.(e);
            inputRef = e;
          }}
          value={value}
          onChangeText={handleChangeText}
          style={[
            styles.textinput,
            error ? { borderBottomColor: colors.temp.secondary.bordeaux.light } : {},
          ]}
          placeholderTextColor={colors.temp.secondary.brown.normal}
          autoCapitalize="none"
          autoCorrect={false}
          keyboardType={keyboardType || 'default'}
          onBlur={() => {
            setIsFocused(false);
          }}
          onFocus={() => {
            setIsFocused(true);
          }}
        />
        {error ? (
          <IconButton
            Icon={Close}
            containerStyle={styles.icon}
            onPress={() => handleChangeText('')}
          />
        ) : null}
        <View style={[styles.icon, error ? styles.spacing : {}]}>{extraComponent}</View>
        <TouchableWithoutFeedback onPress={() => inputRef?.focus()}>
          <Animated.View
            style={[
              styles.labelContainer,
              {
                transform: [
                  {
                    scale: focusAnim.interpolate({
                      inputRange: [0, 1],
                      outputRange: [1, 0.75],
                    }),
                  },
                  {
                    translateY: focusAnim.interpolate({
                      inputRange: [0, 1],
                      outputRange: [0, -32],
                    }),
                  },
                  {
                    translateX: focusAnim.interpolate({
                      inputRange: [0, 1],
                      outputRange: [0, -(placeholder?.length || 0) * 1.5],
                    }),
                  },
                ],
              },
            ]}
          >
            <SommdText style={styles.label}>{placeholder}</SommdText>
          </Animated.View>
        </TouchableWithoutFeedback>
        {error ? (
          <SommdText vPadding="small" style={[TextStyles.error, styles.error]}>
            {error}
          </SommdText>
        ) : null}
      </View>
    );
  }
);

export default TextInput;

const styles = StyleSheet.create({
  container: {
    // marginBottom: 48,
    marginVertical: 16,
  },
  textinput: {
    ...TextStyles.textinput,
    paddingVertical: 8,
    borderBottomWidth: 1,
    borderBottomColor: colors.temp.primary.teal,
  },
  labelContainer: {
    position: 'absolute',
    left: 0,
  },
  label: {
    ...TextStyles.textinput,
    paddingVertical: 8,
  },
  error: {
    bottom: 24,
  },
  icon: {
    position: 'absolute',
    right: 0,
    top: -8,
    marginVertical: 8,
  },
  spacing: {
    right: 24,
  },
});
