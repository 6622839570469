const spacing = {
  extraBigestPadding: 112,
  extraBiggerPadding: 104,
  extraBigPadding: 96,
  bigPadding: 88,
  bigSmallPadding: 80,
  bigSmallerPadding: 72,
  bigSmallestPadding: 64,
  smallPadding: 56,
  smallerPadding: 48,
  smallestPadding: 40,
  shortPadding: 32,
  shortterPadding: 24,
  shortestPadding: 16,
  shortestSmallPadding: 8,
};

export default spacing;
