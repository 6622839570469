import { read, write } from '@lib/storage';

import Config from './config';

export const SESSION_KEY = 'wine_app_session';

export interface Session {
  id_token: string;
  refresh_token: string;
  token_type: 'Bearer';
  expires_in: number;
}

export const refreshToken = async (token: string) => {
  try {
    const res = await fetch('https://cognito-idp.us-east-1.amazonaws.com/', {
      headers: {
        'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
        'Content-Type': 'application/x-amz-json-1.1',
      },
      method: 'POST',
      body: JSON.stringify({
        ClientId: Config.REFRESH_TOKEN_CLIENT_ID,
        AuthFlow: 'REFRESH_TOKEN_AUTH',
        AuthParameters: {
          REFRESH_TOKEN: token,
          SECRET_HASH: Config.REFRESH_TOKEN_SECRET_HASH,
        },
      }),
    });

    const data = await res.json();
    return data.AuthenticationResult.IdToken;
  } catch (err) {
    return null;
  }
};

export const loadSession = async () => {
  const session: Session = await read(SESSION_KEY);

  if (!session) return null;
  return session;
};

export const startSession = async (session: Session) => {
  await write(SESSION_KEY, session);
};

export const clearSession = async () => {
  await write(SESSION_KEY, '');
};
