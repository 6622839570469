import { useState, useCallback } from 'react';

type Val = number | string | boolean;

export default function useToggle(
  initial: Val = false
): [Val, (v?: Val) => void, () => void, () => void, () => void] {
  const [visible, setVisible] = useState<Val>(initial);

  const toggle = useCallback((v) => {
    if (v === undefined) setVisible((v) => !v);
    else setVisible(v);
  }, []);
  const open = useCallback(() => setVisible(true), []);
  const close = useCallback(() => setVisible(false), []);
  const reset = useCallback(() => setVisible(initial), [initial]);

  return [visible, toggle, open, close, reset];
}
