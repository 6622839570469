import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props: any) {
  return (
    <Svg width={32} height={32} viewBox="0 0 32 32" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 10a9.77 9.77 0 0 1 8.82 5.5A9.77 9.77 0 0 1 16 21a9.77 9.77 0 0 1-8.82-5.5A9.77 9.77 0 0 1 16 10Zm-4.5 5.5c0-2.48 2.02-4.5 4.5-4.5s4.5 2.02 4.5 4.5S18.48 20 16 20s-4.5-2.02-4.5-4.5Z"
        fill="#452A21"
      />
      <Path d="M16 13a2.5 2.5 0 0 1 0 5 2.5 2.5 0 0 1 0-5Z" fill="#452A21" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 8c-5 0-9.27 3.11-11 7.5 1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5C25.27 11.11 21 8 16 8Zm8.82 7.5A9.77 9.77 0 0 0 16 10a9.77 9.77 0 0 0-8.82 5.5A9.77 9.77 0 0 0 16 21a9.77 9.77 0 0 0 8.82-5.5Z"
        fill="#452A21"
      />
      <Path
        d="M5 25 25 5.5"
        stroke="#452A21"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default SvgComponent;
