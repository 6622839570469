import * as React from 'react';
import Svg, { Mask, Path } from 'react-native-svg';

function SvgComponent(props: any) {
  return (
    <Svg width={32} height={32} viewBox="0 0 32 32" fill="none" {...props}>
      <Mask id="a" fill="#fff">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.5 1A1.5 1.5 0 0 0 15 2.5v6.756a4.502 4.502 0 0 0-3 4.244v14a4.5 4.5 0 1 0 9 0v-14a4.502 4.502 0 0 0-3-4.244V2.5A1.5 1.5 0 0 0 16.5 1Z"
        />
      </Mask>
      <Path
        d="m15 9.256.666 1.886A2 2 0 0 0 17 9.256h-2Zm3 0h-2a2 2 0 0 0 1.334 1.886L18 9.256ZM17 2.5a.5.5 0 0 1-.5.5v-4A3.5 3.5 0 0 0 13 2.5h4Zm0 6.756V2.5h-4v6.756h4ZM14 13.5c0-1.085.693-2.014 1.666-2.358L14.334 7.37A6.502 6.502 0 0 0 10 13.5h4Zm0 14v-14h-4v14h4Zm2.5 2.5a2.5 2.5 0 0 1-2.5-2.5h-4a6.5 6.5 0 0 0 6.5 6.5v-4Zm2.5-2.5a2.5 2.5 0 0 1-2.5 2.5v4a6.5 6.5 0 0 0 6.5-6.5h-4Zm0-14v14h4v-14h-4Zm-1.666-2.358A2.502 2.502 0 0 1 19 13.5h4a6.502 6.502 0 0 0-4.334-6.13l-1.332 3.772ZM16 2.5v6.756h4V2.5h-4Zm.5.5a.5.5 0 0 1-.5-.5h4A3.5 3.5 0 0 0 16.5-1v4Z"
        fill="#452A21"
        mask="url(#a)"
      />
      <Mask id="b" fill="#fff">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.5 0A1.5 1.5 0 0 0 24 1.5v4.837A3.5 3.5 0 0 0 22 9.5v12a3.5 3.5 0 1 0 7 0v-12a3.5 3.5 0 0 0-2-3.163V1.5A1.5 1.5 0 0 0 25.5 0Z"
        />
      </Mask>
      <Path
        d="M24 1.5h2-2Zm0 4.837.858 1.806A2 2 0 0 0 26 6.337h-2Zm3 0h-2a2 2 0 0 0 1.142 1.806L27 6.337ZM26 1.5a.5.5 0 0 1-.5.5v-4A3.5 3.5 0 0 0 22 1.5h4Zm0 4.837V1.5h-4v4.837h4ZM24 9.5a1.5 1.5 0 0 1 .858-1.357L23.142 4.53A5.5 5.5 0 0 0 20 9.5h4Zm0 12v-12h-4v12h4Zm1.5 1.5a1.5 1.5 0 0 1-1.5-1.5h-4a5.5 5.5 0 0 0 5.5 5.5v-4Zm1.5-1.5a1.5 1.5 0 0 1-1.5 1.5v4a5.5 5.5 0 0 0 5.5-5.5h-4Zm0-12v12h4v-12h-4Zm-.858-1.357A1.5 1.5 0 0 1 27 9.5h4a5.5 5.5 0 0 0-3.142-4.97l-1.716 3.613ZM25 1.5v4.837h4V1.5h-4Zm.5.5a.5.5 0 0 1-.5-.5h4A3.5 3.5 0 0 0 25.5-2v4Z"
        fill="#452A21"
        mask="url(#b)"
      />
      <Mask id="c" fill="#fff">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5 0A1.5 1.5 0 0 0 6 1.5v4.837A3.5 3.5 0 0 0 4 9.5v12a3.5 3.5 0 1 0 7 0v-12a3.5 3.5 0 0 0-2-3.163V1.5A1.5 1.5 0 0 0 7.5 0Z"
        />
      </Mask>
      <Path
        d="m6 6.337.858 1.806A2 2 0 0 0 8 6.337H6Zm3 0H7a2 2 0 0 0 1.142 1.806L9 6.337ZM8 1.5a.5.5 0 0 1-.5.5v-4A3.5 3.5 0 0 0 4 1.5h4Zm0 4.837V1.5H4v4.837h4ZM6 9.5a1.5 1.5 0 0 1 .858-1.357L5.142 4.53A5.5 5.5 0 0 0 2 9.5h4Zm0 12v-12H2v12h4ZM7.5 23A1.5 1.5 0 0 1 6 21.5H2A5.5 5.5 0 0 0 7.5 27v-4ZM9 21.5A1.5 1.5 0 0 1 7.5 23v4a5.5 5.5 0 0 0 5.5-5.5H9Zm0-12v12h4v-12H9Zm-.858-1.357A1.5 1.5 0 0 1 9 9.5h4a5.5 5.5 0 0 0-3.142-4.97L8.142 8.143ZM7 1.5v4.837h4V1.5H7Zm.5.5a.5.5 0 0 1-.5-.5h4A3.5 3.5 0 0 0 7.5-2v4Z"
        fill="#452A21"
        mask="url(#c)"
      />
    </Svg>
  );
}

export default SvgComponent;
