import React, { useContext } from 'react';

import User from '../User';
import { Session } from '../session';

export interface AppCtx {
  user: User | null;
  onLogin?: (session: Session) => Promise<void>;
}

const AppContext = React.createContext<AppCtx | null>(null);
export const useUser = (): AppCtx => {
  const user = useContext(AppContext);
  // @ts-ignore
  return user;
};
export const AppProvider = AppContext.Provider;
