import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props: any) {
  return (
    <Svg width={32} height={32} viewBox="0 0 32 32" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 1A1.5 1.5 0 0 0 15 2.5v6.756a4.502 4.502 0 0 0-3 4.244v14a4.5 4.5 0 1 0 9 0v-14a4.502 4.502 0 0 0-3-4.244V2.5A1.5 1.5 0 0 0 16.5 1ZM25.5 0A1.5 1.5 0 0 0 24 1.5v4.837A3.5 3.5 0 0 0 22 9.5v12a3.5 3.5 0 1 0 7 0v-12a3.5 3.5 0 0 0-2-3.163V1.5A1.5 1.5 0 0 0 25.5 0ZM7.5 0A1.5 1.5 0 0 0 6 1.5v4.837A3.5 3.5 0 0 0 4 9.5v12a3.5 3.5 0 1 0 7 0v-12a3.5 3.5 0 0 0-2-3.163V1.5A1.5 1.5 0 0 0 7.5 0Z"
        fill="#452A21"
      />
    </Svg>
  );
}

export default SvgComponent;
