"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var Controller_1 = require("./Controller");
function useTransitionState(state, animation) {
    var controller = (0, Controller_1.useController)(state);
    (0, react_1.useEffect)(function () {
        controller.advance(state, animation);
    }, [state]);
    return controller;
}
exports.default = useTransitionState;
