const colors = {
  brand: '#C85F57',
  brandVariant: '#CC3333',
  brandPrimary: '#C42124',
  brandSecondary: '#911A1F',
  primary: '#000000',
  primaryVariant: '#ffffff',
  secondary: '#777777',
  secondaryVariant: '#333333',
  disabled: '#0005',

  temp: {
    primary: {
      teal: '#589D97',
      bordeaux: '#9D0041',
      creme: '#B89877',
      white: '#FFFFFF',
    },
    secondary: {
      brown: {
        '5': '#0D452A21',
        '30': '#4D452A21',
        '50': '#80452A21',
        normal: '#452A21',
      },
      bordeaux: {
        rose: '#C4667D',
        light: '#C13355',
        dark: '#700631',
        normal: '#9D0041',
      },
      creme: {
        normal: '#D6C0AB',
        light: '#F4E8DD',
        dark: '#B89877',
      },
      caramel: {
        light: '#F9E3CF',
        normal: '#EFD3B8',
        dark: '#DCB287',
      },
    },
    functional: {
      grey: {
        '100': '#FF333333',
        '80': '#CC333333',
        '60': '#99333333',
        '40': '#66333333',
        '20': '#33333333',
      },
      lightGrey: {
        '100': '#F2F2F2',
        '80': '#CCF2F2F2',
        '60': '#99F2F2F2',
        '40': '#66F2F2F2',
        '20': '#33F2F2F2',
      },
    },
  },
};
export default colors;
