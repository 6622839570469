import { Session, loadSession, clearSession, startSession } from './session';

class User {
  session: Session;

  onLogout?: () => void;

  static async load() {
    const session = await loadSession();
    if (!session) return null;

    return User.create(session);
  }

  static async create(session: Session) {
    await startSession(session);

    return new User(session);
  }

  constructor(session: Session) {
    this.session = session;
  }

  logout = async () => {
    await clearSession();
    if (this.onLogout) this.onLogout();
  };
}

export default User;
