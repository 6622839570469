import DeviceInfo from 'react-native-device-info';

export const URL = 'http://app-dev.sommd.com';
export const DEFAULT_TAB = 'Home';
export const DEFAULT_SCREEN = 'BottomTabs';
export const ONBOARDING_INIT_DATA_KEY = 'onboarding_init_data';

export const SORT_OPTIONS: { [arg0: string]: string } = {
  recent: 'Recently added',
  score: 'Sommd Score',
  name: 'Name',
  bottles: 'Number of bottles',
};

export const FILTER_OPTIONS: { [arg0: string]: string } = {
  like: 'Loved',
  appellation: 'Appellation',
  vintage: 'Vintage',
  grape: 'Grape',
  country: 'Country',
};

export type FilterProops = {
  id: string;
  title: string;
  option: string;
}[];

export const FILTER_OPTIONSS = [
  {
    id: '1',
    title: 'Type',
    option: ['Red', 'White', 'Sparkling', 'Rosé', 'Dessert'],
  },
  {
    id: '2',
    title: 'Region',
    option: ['France', 'USA', 'Italy', 'Australia', 'Dessert'],
  },
  {
    id: '3',
    title: 'Varietal',
    option: ['Cabernet', 'Sauvignon', 'Pinot'],
  },
];

export type SortOptions = 'recent' | 'score' | 'Grape' | 'bottles';
export type FilterOptions = 'like' | 'appellation' | 'vintage' | 'grape' | 'country';

export const NAVBAR_HEIGHT = 150;
export const NAVBAR_TOPARC_HEIGHT = 90;
export const NAVBAR_EXTENSION_HEIGHT = 50;
export const NAVBAR_BOTTOMARC_HEIGHT = NAVBAR_EXTENSION_HEIGHT;
export const NAVBAR_TITLE_HEIGHT = NAVBAR_HEIGHT - NAVBAR_TOPARC_HEIGHT;

// import Config from "react-native-config";
// const BASE_URL = Config.WINE_APP_BASE_URL || '/dev/';

const URLS = {
  staging: {
    base: 'https://0e7rhqo1m3.execute-api.us-east-1.amazonaws.com',
    suffix: 'test',
  },
  production: {
    base: 'https://wdl9igkr97.execute-api.us-east-1.amazonaws.com',
    suffix: 'dev',
  },
};

const CURRENT_URL = DeviceInfo.getBundleId().includes('staging') ? URLS.staging : URLS.staging;

export const API_URL = CURRENT_URL.base;
export const BASE_URL = `${API_URL}/${CURRENT_URL.suffix}/`;
export const ADMIN_URL = `${BASE_URL}admin/`;
