import React from 'react';
import { Text as RNText, TextProps as RNTextProps } from 'react-native';
import { TextStyles } from '../styles';


type TextProps = RNTextProps & {
  children?: React.ReactNode,
}

export default function Text({ style, ...rest }: TextProps) {
  return (
    <RNText
      style={[TextStyles.regular, style]}
      allowFontScaling={false}
      {...rest}
    />
  );
}
