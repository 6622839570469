import React, { useContext, useState, useEffect, useMemo, useCallback } from 'react';
import { read, write, remove } from '@lib/storage';
import * as Keychain from 'react-native-keychain';
import { Platform } from 'react-native';

interface Ctx {
  loading: boolean;
  isEnabled: boolean;
  save: (arg0: any) => Promise<any>;
  enable: () => Promise<any>;
  disable: () => Promise<any>;
  isAvailable: Keychain.BIOMETRY_TYPE | null;
  showBiometric: (arg0: { title?: string }) => Promise<false | Keychain.UserCredentials>;
}

const Biometric = React.createContext<Ctx | null>(null);

const BIOMETRIC_SERVICE = 'sommd_biometric_service';
const BIOMETRIC_KEY = 'sommd_biometric_validation';

export const useBiometric = (): Ctx => {
  const biometric = useContext(Biometric);
  // @ts-ignore
  return biometric;
};

const isMobile = Platform.OS === 'android' || Platform.OS === 'ios';

export function BiometricProvider({ children }: { children: React.ReactNode }) {
  const [isEnabled, setEnabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isAvailable, setIsAvailable] = useState<Keychain.BIOMETRY_TYPE | null>(null);

  useEffect(() => {
    (async () => {
      if (!isMobile) return;
      const available = await Keychain.getSupportedBiometryType();

      setIsAvailable(available);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (!isMobile) return;
      if (isAvailable) {
        const token = await read(BIOMETRIC_KEY);
        if (token === 'enabled') setEnabled(true);
      }
    })();
  }, [isAvailable]);

  async function showBiometric({ title }: { title?: string }) {
    try {
      const result = await Keychain.getGenericPassword({
        service: BIOMETRIC_SERVICE,
        authenticationPrompt: {
          title,
        },
      });

      if (typeof result === 'boolean' && !result) return false;

      return result;
    } catch (e) {
      console.warn('Biometric Login Setup Failed');
      return false;
    }
  }

  async function disable() {
    setLoading(true);
    // TODO: reset password only when it changes
    // await Keychain.resetGenericPassword({
    //   service: BIOMETRIC_SERVICE,
    // });
    await remove(BIOMETRIC_KEY);
    setEnabled(false);
    setLoading(false);
  }

  const enable = useCallback(async () => {
    setLoading(true);
    const data = await showBiometric({ title: 'Enable Biometric' });
    if (data) {
      await write(BIOMETRIC_KEY, 'enabled');
      setEnabled(true);
    }
    setLoading(false);
  }, []);

  async function save({ username, password }: { username: string; password: string }) {
    try {
      await Keychain.setGenericPassword(username, password, {
        service: BIOMETRIC_SERVICE,
        storage: Keychain.STORAGE_TYPE.RSA,
        accessControl: Keychain.ACCESS_CONTROL.BIOMETRY_ANY,
        authenticationType: Keychain.AUTHENTICATION_TYPE.BIOMETRICS,
        accessible: Keychain.ACCESSIBLE.WHEN_PASSCODE_SET_THIS_DEVICE_ONLY,
      });
    } catch (err) {
      console.warn('Biometric Login Setup Failed');
    }
  }

  const ctx = useMemo(
    () => ({
      save,
      enable,
      disable,
      loading,
      isEnabled,
      isAvailable,
      showBiometric,
    }),
    [enable, isAvailable, isEnabled, loading]
  );

  return <Biometric.Provider value={ctx}>{children}</Biometric.Provider>;
}
