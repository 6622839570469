import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { colors, TextStyles } from '../styles';
import { handleBack } from '../utils';
import Back from '../assets/back';

import View from './View';
import SommdText from './SommdText';
import { Col, Row } from './Grids';

function ScreenHeader({
  Left,
  Right,
  title,
  subtitle,
  renderBack,
  insets = true,
  backgroundColor = colors.temp.primary.white,
}: {
  Left?: any;
  Right?: any;
  title: string;
  insets?: boolean;
  subtitle?: string;
  renderBack?: boolean;
  backgroundColor?: string;
}) {
  const navigation = useNavigation();

  const body = useMemo(
    () => (
      <View vPadding="large" hMargin="large" style={{ backgroundColor }}>
        <Row alignItems="center" justifyContent="center">
          <SommdText center style={[TextStyles.header, styles.title]}>
            {title}
          </SommdText>
          {Left ? (
            <View style={styles.prev}>{Left}</View>
          ) : (
            renderBack && (
              <Col
                style={styles.prev}
                onPress={() => handleBack(navigation)}
                hitSlop={{ top: 16, left: 16, bottom: 16, right: 16 }}
              >
                {/* color property added to the Back svg because its color can be changed according to the color provided because in its script fill color has been customized to currentColor  */}
                <Back color={colors.temp.secondary.brown.normal} />
              </Col>
            )
          )}
          <View style={styles.next}>{Right}</View>
        </Row>
        {subtitle && (
          <SommdText tMargin="large" style={[TextStyles.bold, TextStyles.center]}>
            {subtitle}
          </SommdText>
        )}
      </View>
    ),
    [Left, Right, backgroundColor, navigation, renderBack, subtitle, title]
  );

  return insets ? (
    <SafeAreaView style={{ backgroundColor }} edges={['top', 'left', 'right']}>
      {body}
    </SafeAreaView>
  ) : (
    body
  );
}

export default ScreenHeader;

const styles = StyleSheet.create({
  prev: {
    left: 0,
    position: 'absolute',
  },
  next: {
    right: 0,
    position: 'absolute',
  },
  title: {
    textAlign: 'center',
  },
});
