import React, { useRef, useState } from 'react';
import { Animated, StyleSheet, View, TouchableOpacity, ViewStyle } from 'react-native';
import { spring } from '@lib/anim';

import Text from './Text';
import { colors, TextStyles } from '../styles';
import { getScreenDimension } from '../utils';


const spacing = 16;
// const spacingSmall = spacing / 2;
const spacingExtraSmall = spacing / 4;
// const spacingLarge = spacing * 2;
// const spacingExtraLarge = spacing * 3;
const SCREEN_WIDTH = getScreenDimension();
const animOptions = {
  bounciness: 2,
  speed: 5,
};


const Tab = ({ onPress, label, index, isActive }: {
  onPress: (arg0: number) => void,
  label: string,
  index: number,
  isActive: boolean
}) => {
  const color = isActive ? colors.brandPrimary : '#7B7B7B';

  return (
    <TouchableOpacity onPress={() => onPress(index)} style={styles.tabItem}>
      <Text style={[TextStyles.small, { color }]}>{label}</Text>
    </TouchableOpacity>
  )
}

export default function Tabs({ tabs, tabsStyle, containerStyle, horizontal = 0 }: {
  tabs: { [arg0: string]: [] | [React.ComponentType<any>] | [React.ComponentType<any>, {}] },
  tabsStyle?: ViewStyle,
  containerStyle?: ViewStyle,
  horizontal?: number
}) {
  const [active, setActive] = useState(0);
  const tabSize = useRef(0);
  const tabX = useRef(new Animated.Value(0)).current;
  const bodyX = useRef(new Animated.Value(0)).current;
  const bodyWidth = SCREEN_WIDTH - horizontal * 2;

  const handlePress = (index: number) => {
    setActive(index);
    spring(tabX, tabSize.current * index, animOptions).start();

    spring(bodyX, -SCREEN_WIDTH * index, animOptions).start();
  }

  const labels = Object.keys(tabs);
  const data = Object.values(tabs);

  return (
    <View style={[styles.container, containerStyle]}>
      <View style={[styles.tabContainer, tabsStyle, { marginHorizontal: horizontal }]}>
        <Animated.View
          onLayout={event => { tabSize.current = event.nativeEvent.layout.width; }}
          style={[
            styles.tabIndicator,
            {
              transform: [{ translateX: tabX }],
              width: `${100 /labels.length}%`
            }
          ]}
        />
        {labels.map((label, index) => (
          <Tab
            key={label}
            onPress={handlePress}
            {...{ label, index, isActive: index === active }}
          />
        ))}
      </View>
      <Animated.View
        style={[
          styles.bodyContainer,
          {
            transform: [{ translateX: bodyX }],
          }
        ]}
      >
        {data.map(([Comp, props], index) => (
          <View key={index + 1} style={{ width: bodyWidth, marginHorizontal: horizontal }}>
            {Comp ? <Comp {...props} /> : null}
          </View>
        ))}
      </Animated.View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  tabContainer: {
    borderBottomWidth: 1,
    borderColor: '#CFCFCF',
    flexDirection: 'row',
  },
  tabItem: {
    paddingVertical: spacingExtraSmall,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabIndicator: {
    height: 3,
    backgroundColor: colors.brandPrimary,
    position :'absolute',
    bottom: 0,
  },
  bodyContainer: {
    flex: 1,
    flexDirection: 'row',
  },
})
