import React, { useEffect, useState } from 'react';
import { View, TextInputProps, ActivityIndicator } from 'react-native';
import DropDownPicker, { ItemType } from 'react-native-dropdown-picker';
import { TextStyles } from '../styles';
import Down from '../assets/down_Arrow';
import UpS from '../assets/upSmall.png';
import SommdText from './SommdText';
import IconResolver from './IconResolver';

type Props = TextInputProps & {
  onChange?: (v: string | number | number[] | string[]) => void;
  error?: string;
  items?: ItemType[];
  multipleSelect?: boolean;
  loading?: boolean;
  width?: boolean;
};

const DropSelect = React.forwardRef((props: Props, ref: any) => {
  const {
    placeholder,
    items: cameItems,
    onChange,
    error,
    multipleSelect = false,
    loading,
    width,
  } = props;
  const [open, setOpen] = useState(false);
  const [value1, setValue] = useState<any>([]);
  const [items, setItems] = useState<ItemType[]>([]);

  useEffect(() => {
    setItems(cameItems || []);
  }, [cameItems]);

  return (
    <View style={{ width: multipleSelect || width ? undefined : 200 }}>
      <SommdText vMargin="small">{placeholder}</SommdText>
      <DropDownPicker
        key={ref}
        mode="BADGE"
        open={open}
        value={value1}
        items={items}
        multiple={multipleSelect}
        min={0}
        max={8}
        setOpen={setOpen}
        setValue={setValue}
        setItems={setItems}
        dropDownDirection="BOTTOM"
        searchable
        showArrowIcon
        showTickIcon
        loading={loading || false}
        disabledStyle={{
          opacity: 0.5,
        }}
        placeholder={`Select a ${placeholder || ''}`}
        style={{ top: 10, flexDirection: 'column' }}
        badgeStyle={{
          paddingHorizontal: 10,
          paddingVertical: 6,
          borderRadius: 4,
        }}
        badgeTextStyle={[TextStyles.actualNormal]}
        placeholderStyle={[TextStyles.actualNormal, { opacity: 0.5 }]}
        dropDownContainerStyle={{
          borderColor: '#cccccc',
          borderRadius: 8,
        }}
        searchPlaceholder="Search...."
        listItemLabelStyle={[TextStyles.actualNormal, { marginHorizontal: 16, marginVertical: 8 }]}
        searchContainerStyle={{ marginVertical: 4 }}
        searchTextInputStyle={[TextStyles.actualNormal]}
        onSelectItem={(item: any) => {
          if (multipleSelect) {
            const data = item.map((item: any) => item?.value);
            if (onChange) onChange(data);
            return;
          }
          if (onChange) onChange(item?.value);
        }}
        ActivityIndicatorComponent={({ color, size }) => (
          <ActivityIndicator color={color} size={size} />
        )}
        ArrowUpIconComponent={() => <IconResolver Icon={UpS} width={16} height={16} />}
        ArrowDownIconComponent={() => <Down />}
        activityIndicatorColor="red"
        activityIndicatorSize={30}
        arrowIconContainerStyle={{ marginBottom: 10, marginTop: 4 }}
      />
      {error ? (
        <SommdText vPadding="small" style={[TextStyles.error]}>
          {error || ''}
        </SommdText>
      ) : null}
    </View>
  );
});

export default DropSelect;
