import React from 'react';
import { View as RNView, TouchableOpacity, ViewProps } from 'react-native';
import { renderProps, RenderPropsType } from '../utils';

export default function View({
  children,
  ...props
}: { children?: React.ReactNode } & ViewProps & RenderPropsType) {
  return React.createElement(
    props.onPress ? TouchableOpacity : RNView,
    renderProps(props),
    children
  );
}
