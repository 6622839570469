import React from 'react';
import {
  TextStyle,
  TouchableOpacity,
  TouchableOpacityProps as RNTouchableOpacityProps,
} from 'react-native';
import { ButtonStyles, colors, TextStyles } from '../styles';
import Text from './Text';

type TouchableOpacityProps = RNTouchableOpacityProps & {
  type?: 'primary' | 'secondary' | 'transparent';
  title: string;
  textStyle?: TextStyle;
};

const textStyles = {
  primary: colors.brandPrimary,
  secondary: colors.primaryVariant,
  transparent: colors.brandPrimary,
};

export default function Button({
  type = 'primary',
  title,
  style,
  textStyle,
  disabled,
  ...rest
}: TouchableOpacityProps) {
  const btnStyle = ButtonStyles[type];
  const txtStyle = textStyles[type];

  return (
    <TouchableOpacity
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      disabled={disabled}
      style={[btnStyle, disabled && ButtonStyles.disabled, style]}
    >
      <Text style={[{ color: txtStyle }, disabled && TextStyles.disabled, textStyle]}>{title}</Text>
    </TouchableOpacity>
  );
}
