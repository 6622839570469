"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useController = exports.Controller = void 0;
var react_1 = require("react");
var react_native_1 = require("react-native");
var defaultAnimation = function (value, toValue) {
    return react_native_1.Animated.timing(value, {
        toValue: toValue,
        duration: 225,
        useNativeDriver: true,
        easing: react_native_1.Easing.bezier(0.42, 0, 0.58, 1),
    });
};
var Transition = (function () {
    function Transition(state, driver) {
        this.state = state;
        this.driver = typeof driver === 'number' ? new react_native_1.Animated.Value(driver) : driver;
    }
    return Transition;
}());
var Controller = (function () {
    function Controller(initialState) {
        this.listeners = [];
        this.current = new Transition(initialState, 1);
        this.next = this.current;
    }
    Controller.prototype.other = function (state) {
        if (this.current.state === state) {
            return this.next.state;
        }
        return this.current.state;
    };
    Controller.prototype.getTransition = function (state) {
        if (state === this.current.state) {
            return this.current;
        }
        if (state === this.next.state) {
            return this.next;
        }
        return null;
    };
    Controller.prototype.register = function (state, setter) {
        var _this = this;
        var listener = { state: state, setter: setter };
        this.listeners.push(listener);
        return function () {
            var idx = _this.listeners.indexOf(listener);
            _this.listeners.splice(idx, 1);
        };
    };
    Controller.prototype.findTransition = function (state) {
        if (state === this.current.state)
            return this.current;
        if (state === this.next.state)
            return this.next;
        return null;
    };
    Controller.prototype.trigger = function (states) {
        var _this = this;
        this.listeners.forEach(function (listener) {
            if (states.includes(listener.state)) {
                var transition = _this.findTransition(listener.state);
                if (transition !== null) {
                    listener.setter(new Transition(transition.state, transition.driver));
                }
                else {
                    listener.setter(null);
                }
            }
        });
    };
    Controller.prototype.advance = function (state, animation) {
        var _this = this;
        if (animation === void 0) { animation = defaultAnimation; }
        if (this.next.state === state) {
            return;
        }
        if (this.current.state === state) {
            var tmp = this.next.state;
            this.next.state = this.current.state;
            this.current.state = tmp;
            this.trigger([this.next.state, this.current.state]);
            return;
        }
        this.next = new Transition(state, 0);
        this.trigger([this.next.state, this.current.state]);
        react_native_1.Animated.parallel([
            animation(this.current.driver, 0),
            animation(this.next.driver, 1),
        ]).start(function () {
            var states = [_this.current.state, _this.next.state];
            _this.current.driver.setValue(0);
            _this.current = _this.next;
            _this.current.driver.setValue(1);
            _this.trigger(states);
        });
    };
    return Controller;
}());
exports.Controller = Controller;
function useController(initialState) {
    var ref = (0, react_1.useRef)(null);
    if (ref.current === null) {
        ref.current = new Controller(initialState);
    }
    return ref.current;
}
exports.useController = useController;
