import React, { useState } from 'react';
import { Switch, TextStyle, View, ViewStyle } from 'react-native';
import SommdText from './SommdText';
import { colors, TextStyles } from '../styles';

type Props = {
  defaultBoolean?: boolean;
  placeholder?: string;
  onChange?: (v: string | number | boolean) => void;
  error?: string;
  containerStyle?: ViewStyle;
  placeholderStyle?: TextStyle;
};

const BooleanInput = React.forwardRef((props: Props, ref: any) => {
  const { placeholder, onChange, error, containerStyle, defaultBoolean, placeholderStyle } = props;
  const [isEnabled, setIsEnabled] = useState(false);
  const toggleSwitch = () => {
    setIsEnabled((previousState) => !previousState);
    if (onChange) onChange(!isEnabled);
  };
  return (
    <View style={containerStyle}>
      <SommdText vPadding="small" style={placeholderStyle || TextStyles.textinput}>
        {placeholder || 'Verify'}
      </SommdText>
      <Switch
        ref={ref}
        trackColor={{
          false: colors.temp.secondary.bordeaux.light,
          true: colors.temp.primary.teal,
        }}
        thumbColor="#f4f3f4"
        ios_backgroundColor="#3e3e3e"
        onValueChange={toggleSwitch}
        value={defaultBoolean || isEnabled}
      />
      {error ? (
        <SommdText tMargin="medium" style={[TextStyles.error]}>
          {error || ''}
        </SommdText>
      ) : null}
    </View>
  );
});

export default BooleanInput;
