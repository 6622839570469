import { useEffect, useRef } from 'react';
// @ts-ignore
import isEqual from 'lodash.isequal';

export default function useNestedEffect(effectFunction: () => void, deps: any[]) {
  const isFirst = useRef(true);
  const prevDeps = useRef(deps);

  useEffect(() => {
    const isSame = prevDeps.current.every((obj, idx) => isEqual(obj, deps[idx]));

    if (isFirst.current || !isSame) {
      effectFunction();
    }

    isFirst.current = false;
    prevDeps.current = deps;
  }, deps);

  return null;
}
