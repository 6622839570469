import React, { useState } from 'react';
import {
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
  TextInput as RNTextInput,
  ViewStyle,
} from 'react-native';

import { colors, LayoutStyles, TextStyles } from '../styles';
import SearchIcon from '../assets/SearchIcon';
import Close from '../assets/close';

import View from './View';
import IconButton from './IconButton';

function SearchBar({
  value,
  onChange,
  style,
  placeholder,
}: {
  value: string;
  onChange: (arg0: string) => void;
  style?: ViewStyle;
  placeholder?: string;
}) {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <KeyboardAvoidingView
      style={{ position: 'absolute', bottom: 0, width: '100%' }}
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
    >
      <View style={[style, styles.container, LayoutStyles.shadow]}>
        <View hMargin="xxLarge" bMargin="large" style={[styles.searchBarContainer]}>
          <RNTextInput
            value={value}
            placeholder={placeholder || 'Search your wine'}
            placeholderTextColor={colors.temp.primary.white}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onChangeText={(text) => onChange(text)}
            style={[styles.input, isFocused ? styles.focused : {}]}
          />
          {value ? (
            <IconButton Icon={Close} width={32} height={32} onPress={() => onChange('')} />
          ) : (
            <SearchIcon width={32} height={32} />
          )}
        </View>
      </View>
    </KeyboardAvoidingView>
  );
}

export default SearchBar;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: colors.temp.primary.teal,
  },
  searchBarContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    justifyContent: 'center',
    borderBottomColor: colors.temp.secondary.brown.normal,
  },
  input: {
    ...TextStyles.normal,
    flex: 1,
    paddingTop: 24,
    paddingBottom: 16,
    textDecorationLine: 'none',
    color: colors.temp.primary.white,
  },
  focused: { borderBottomColor: colors.temp.primary.white },
});
