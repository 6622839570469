import React from 'react';
import { Text as RNText, TextProps as RNTextProps } from 'react-native';
import { TextStyles } from '../styles';
import { renderProps, RenderPropsType } from '../utils';

type TextProps = RNTextProps &
  RenderPropsType & {
    children?: React.ReactNode;
  };

export default function SommdText({ style, children, ...rest }: TextProps) {
  return React.createElement(
    RNText,
    renderProps({
      ...rest,
      allowFontScaling: false,
      style: [TextStyles.normal, style],
    }),
    children
  );
}
