"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var react_native_1 = require("react-native");
function createDriver(v) {
    if (typeof v === 'number')
        return new react_native_1.Animated.Value(v);
    if (typeof v === 'object') {
        return Object.keys(v).reduce(function (res, key) {
            res[key] = createDriver(v[key]);
            return res;
        }, {});
    }
    throw new Error("Cannot create an animated value from ".concat(v));
}
function useAnimation(init, styler) {
    var ref = (0, react_1.useRef)();
    if (!ref.current) {
        var driver = createDriver(typeof init === 'function' ? init() : init);
        var style = styler(driver);
        ref.current = [style, driver];
    }
    return ref.current;
}
exports.default = useAnimation;
