import React, { useMemo, useState } from 'react';
import { FlatList, StyleSheet } from 'react-native';
import { useStore } from '../context';
import { Wine } from '../selectors';
import { colors } from '../styles';
// import Cart from '../assets/cart';
import CartWhite from '../assets/cartWhite';
import Cellar from '../assets/cellar';
import CellarWhite from '../assets/cellarWhite';
import Heart from '../assets/heartOutline';
import Remove from '../assets/minimize';
import Plus from '../assets/Plus';
import CartOrCellarModal from './CartOrCellarModal';
import ConfirmationModal from './ConfirmationModal';
import ProductItem from './ProductItem';
import { AdminWine } from './Admin/adminSelectors';
import { useAsyncSubmit } from '../utils';
import { Seperator } from './Admin/utilsAdmin';

type Props = {
  data: AdminWine[];
  type?: string;
};

export default function ProductList({ data = [], type = 'normal' }: Props) {
  const [_, dispatch] = useStore();

  const [modal, setModal] = useState('');
  const [wine, setWine] = useState<any>();

  const { submit, loading } = useAsyncSubmit('cellar/add');
  const { submit: pullFromCellar, loading: pullFromCellarLoading } = useAsyncSubmit('cellar/pull');
  const { submit: addToFavorite } = useAsyncSubmit('wine-reaction/favorite');
  const { submit: removeFromFavorite } = useAsyncSubmit('wine-reaction/remove-favorite');

  const items =
    wine && ((modal === 'Cart' && wine.cart) || 0 || (modal === 'Inventory' && wine.inventory));

  const closeModal = () => {
    setModal('');
  };

  const fromCellar = useMemo(() => type === 'cellar', [type]);

  const handleConfirm = async (count: number) => {
    // if (modal) {
    //   dispatch({
    //     type: 'UPDATE_WINE',
    //     payload: {
    //       id: wine?.wine_id,
    //       [modal.toLowerCase()]: cartCellar,
    //     },
    //   });
    //   setModal('');
    // }
    if (fromCellar) {
      await pullFromCellar({
        wine_id: wine?.id || wine?.wine_id,
        count: (wine?.count || 0) - count,
      });
      dispatch({
        type: 'UPDATE_TO_CELLAR',
        payload: { ...wine, count },
      });
    } else {
      await submit({ wine_id: wine?.id, count });
      dispatch({
        type: 'ADD_TO_CELLAR',
        payload: { ...wine, count },
      });
    }
    setModal('');
  };

  const handleCartCellar = (modal: string) => (wine: Wine) => {
    setWine(wine);
    setModal(modal);
  };

  const handleAddToCellar = (wine: AdminWine) => {
    // add wine to cellar API_URL/cellar/add
    setWine(wine);
    setModal('Cellar');
  };

  const handleAddToBasket = (wine: AdminWine) => {
    dispatch({
      type: 'ADD_CART',
      payload: wine,
    });
  };

  const handleAddToFavorite = async (wine: any) => {
    dispatch({
      type: 'ADD_TO_FAVORITE',
      payload: wine,
    });
    await addToFavorite({ wine_id: wine?.id });
  };

  const handleRemoveFromFavorite = async (wine: any) => {
    dispatch({
      type: 'REMOVE_FROM_FAVORITE',
      payload: wine?.id,
    });
    await removeFromFavorite({ wine_id: wine?.id });
  };

  const handleRemoveFromCart = async (wine: any) => {
    dispatch({
      type: 'REMOVE_CART',
      payload: wine?.id,
    });
  };

  const orderAction = [
    {
      key: 'order',
      label: 'Order more',
      backgroundColor: colors.brandPrimary,
      color: '#F2F2F2',
      Icon: Cellar,
    },
  ];

  const cellarRightActions = [
    {
      key: 'removeFromCellar',
      label: 'Remove\nFrom\nCellar',
      backgroundColor: colors.temp.secondary.bordeaux.rose,
      color: '#FFFFFF',
      Icon: Remove,
      onPress: (wine: any) => handleAddToCellar(wine),
    },
  ];

  const favoriteRightActions = [
    {
      key: 'removeFromFavorite',
      label: 'Remove\nFrom\nFavorite',
      backgroundColor: colors.temp.secondary.bordeaux.rose,
      color: '#FFFFFF',
      Icon: Remove,
      onPress: (wine: any) => handleRemoveFromFavorite(wine),
    },
  ];

  const cartRightActions = [
    {
      key: 'removeFromCart',
      label: 'Remove\nFrom\nCart',
      backgroundColor: colors.temp.secondary.bordeaux.rose,
      color: '#FFFFFF',
      Icon: Remove,
      onPress: (wine: any) => handleRemoveFromCart(wine),
    },
  ];

  const normalRightActions = [
    {
      key: 'addFavourites',
      label: 'Add\nto\nFavorites',
      backgroundColor: colors.temp.secondary.bordeaux.rose,
      color: '#FFFFFF',
      Icon: Heart,
      onPress: (wine: any) => handleAddToFavorite(wine),
    },
    {
      key: 'thumbsUp',
      label: 'Add\nto\nCellar',
      backgroundColor: colors.temp.primary.bordeaux,
      color: '#FFFFFF',
      Icon: CellarWhite,
      onPress: (wine: any) => handleAddToCellar(wine),
    },
    {
      key: 'thumbsDown',
      label: 'Add\nto\nBasket',
      backgroundColor: colors.temp.primary.teal,
      color: '#FFFFFF',
      Icon: CartWhite,
      onPress: (wine: any) => handleAddToBasket(wine),
    },
    {
      key: 'addToCategory',
      label: 'Add\nto\nCategory',
      backgroundColor: colors.temp.primary.creme,
      color: '#FFFFFF',
      Icon: Plus,
      onPress: () => setModal('Thumbs Down'),
    },
  ];

  const rightAction: any = {
    cart: cartRightActions,
    favorite: favoriteRightActions,
    cellar: cellarRightActions,
    normal: normalRightActions,
  };

  const reactionActions = rightAction[type] || normalRightActions;

  const renderCartCellarModal = modal === 'Cart' || modal === 'Inventory' || modal === 'Cellar';
  const renderConfirmModal = modal === 'Thumbs Up' || modal === 'Thumbs Down';

  return (
    <>
      <FlatList
        showsVerticalScrollIndicator={false}
        data={data}
        renderItem={({ item }) => (
          <ProductItem
            {...item}
            actions={{ left: orderAction, right: reactionActions }}
            handleCart={handleCartCellar('Cart')}
            handleCellar={handleCartCellar('Inventory')}
            fromCellar={fromCellar}
          />
        )}
        ItemSeparatorComponent={Seperator}
        keyExtractor={(item: AdminWine) => item.id}
        style={styles.container}
      />
      {renderConfirmModal && <ConfirmationModal title={modal} onClose={closeModal} />}
      {renderCartCellarModal && (
        <CartOrCellarModal
          loading={fromCellar ? pullFromCellarLoading : loading}
          fromCellar={fromCellar}
          title={modal}
          items={fromCellar ? wine?.count : items || 0}
          onConfirm={handleConfirm}
          onClose={closeModal}
        />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.temp.primary.white,
  },
});
