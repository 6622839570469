import React, { useContext } from 'react';

const StoreContext = React.createContext<Record<string, any> | null>(null);

export const useStore = () => {
  // @ts-ignore
  const { state, dispatch } = useContext(StoreContext);
  return [state, dispatch];
};

export const StoreProvider = StoreContext.Provider;
