/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { TouchableOpacity, ScrollView, Modal, View } from 'react-native';
import Axios from 'axios';
import { useForm } from 'react-hook-form';
import { LineInput, Loading, SommdButton, SommdText } from '../../components';
import { loadSession } from '../../session';
import { useDeepNestedEffect } from '../../utils';
import {
  AdminWine,
  Appellation,
  Body,
  Category,
  Color,
  Country,
  FeaturedWine,
  FoodCategory,
  GrapeVine,
  Package,
  Region,
  SocialPost,
  SubRegion,
  SweetNess,
  Varietal,
  Vineyards,
  WineNames,
  WineType,
  WineVarietal,
} from './adminSelectors';
import { initialState, rootReducer } from './AdminReducer';
import { useUser } from '../../context';
import { ADMIN_URL } from '../../constants';

type AdminCtx = {
  country?: Record<string, Country[]> | Record<any, any>;
  region?: Record<string, [Region[]]>;
  subregion?: Record<string, [SubRegion[]]>;
  winecategory?: Record<string, Category[]>;
  winebody?: Record<string, Body[]>;
  winecolor?: Record<string, Color[]>;
  varietal?: Record<string, Varietal[]>;
  winevarietal?: Record<string, WineVarietal[]>;
  foodcategory?: Record<string, FoodCategory[]>;
  wineappellation?: Record<string, [Appellation[]]>;
  winevineyard?: Record<string, [Vineyards[]]>;
  winenames?: Record<string, WineNames[]>;
  winepackage?: Record<string, Package[]>;
  socialpost?: Record<string, SocialPost[]>;
  grapevine?: Record<string, GrapeVine[]>;
  featuredwine?: Record<string, FeaturedWine[]>;
  wines?: Record<string, AdminWine[]>;
  wine_type?: Record<string, WineType[]>;
  wine_sweetness?: Record<string, SweetNess[]>;
};

export const AdminContext = React.createContext<{
  state: AdminCtx;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

export function AdminProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(rootReducer, initialState);

  const {
    datas: countries,
    loading: countriesLoading,
    error: countriesError,
  } = useFetch({ url: 'countries' });
  const {
    datas: regions,
    loading: regionsLoading,
    error: regionsError,
  } = useFetch({ url: 'regions' });
  const {
    datas: subRegions,
    loading: subRegionsLoading,
    error: subRegionsError,
  } = useFetch({ url: 'sub-regions' });
  const {
    error: wineConfigError,
    loading: wineConfigLoading,
    datas: { wine_category, wine_body, wine_color, wine_type, wine_sweetness },
  } = useFetch({ url: 'wine-configs' });
  const {
    error: wineAppellationError,
    loading: wineAppellationLoading,
    datas: appellations,
  } = useFetch({ url: 'wine-appellations' });
  const {
    error: wineNamesError,
    loading: wineNamesLoading,
    datas: winenames,
  } = useFetch({ url: 'wine-names' });
  const {
    error: winePackageError,
    loading: winePackageLoading,
    datas: winepackage,
  } = useFetch({ url: 'wine-packages' });
  const { datas: winevarietal } = useFetch({ url: 'wine-varietal' });
  const { datas: socialpost } = useFetch({ url: 'social-posts' });
  const { datas: grapevine } = useFetch({ url: 'grapevine' });
  const { datas: featuredwine } = useFetch({ url: 'featured-wines' });
  const { datas: wines } = useFetch({ url: 'wines' });
  const { datas: varietal } = useFetch({ url: 'varietal' });
  const { datas: foodcategory } = useFetch({ url: 'food-categories' });

  useEffect(() => {
    if (countries) {
      dispatch({
        type: 'POPULATE_COUNTRIES',
        payload: countries,
      });
    }
    if (regions) {
      dispatch({
        type: 'POPULATE_REGIONS',
        payload: regions,
      });
    }
    if (subRegions) {
      dispatch({
        type: 'POPULATE_SUB_REGIONS',
        payload: subRegions,
      });
    }
    if (wine_body) {
      dispatch({
        type: 'POPULATE_WINE_BODY',
        payload: wine_body,
      });
    }
    if (wine_color) {
      dispatch({
        type: 'POPULATE_WINE_COLOR',
        payload: wine_color,
      });
    }
    if (wine_category) {
      dispatch({
        type: 'POPULATE_WINE_CATEGORY',
        payload: wine_category,
      });
    }
    if (appellations) {
      dispatch({
        type: 'POPULATE_APPELLATION',
        payload: appellations,
      });
    }
    if (winenames) {
      dispatch({
        type: 'POPULATE_WINENAMES',
        payload: winenames,
      });
    }
    if (winepackage) {
      dispatch({
        type: 'POPULATE_WINEPACKAGE',
        payload: winepackage,
      });
    }
    if (socialpost) {
      dispatch({
        type: 'POPULATE_SOCIAL_POST',
        payload: socialpost,
      });
    }
    if (socialpost) {
      dispatch({
        type: 'POPULATE_GRAPE_VINE',
        payload: grapevine,
      });
    }
    if (featuredwine) {
      dispatch({
        type: 'POPULATE_FEATURED_WINE',
        payload: featuredwine,
      });
    }
    if (wines) {
      dispatch({
        type: 'POPULATE_WINES',
        payload: wines,
      });
    }
    if (varietal) {
      dispatch({
        type: 'POPULATE_VARIETAL',
        payload: varietal,
      });
    }
    if (foodcategory) {
      dispatch({
        type: 'POPULATE_FOODCATEGORY',
        payload: foodcategory,
      });
    }
    if (winevarietal) {
      dispatch({
        type: 'POPULATE_WINE_VARIETAL',
        payload: winevarietal,
      });
    }
    if (wine_type) {
      dispatch({
        type: 'POPULATE_WINE_TYPE',
        payload: wine_type,
      });
    }
    if (wine_sweetness) {
      dispatch({
        type: 'POPULATE_WINE_SWEETNESS',
        payload: wine_sweetness,
      });
    }
  }, [
    appellations,
    countries,
    dispatch,
    featuredwine,
    foodcategory,
    grapevine,
    regions,
    socialpost,
    subRegions,
    varietal,
    wine_body,
    wine_category,
    wine_color,
    wine_sweetness,
    wine_type,
    winenames,
    winepackage,
    wines,
    winevarietal,
  ]);

  const val = useMemo(() => ({ state, dispatch }), [state]);

  if (
    countriesLoading ||
    regionsLoading ||
    subRegionsLoading ||
    wineConfigLoading ||
    wineAppellationLoading ||
    wineNamesLoading ||
    winePackageLoading
  )
    return <Loading />;

  if (
    countriesError ||
    regionsError ||
    subRegionsError ||
    wineConfigError ||
    wineAppellationError ||
    wineNamesError ||
    winePackageError
  )
    return <SommdText>Error</SommdText>;

  return <AdminContext.Provider value={val}>{children}</AdminContext.Provider>;
}

export function useAdminData() {
  return useContext(AdminContext);
}

function BaseForm({
  formData,
  control,
  errors,
  handleSubmit,
}: {
  formData: any;
  control: any;
  errors: any;
  handleSubmit: any;
}) {
  const onSubmit = async (data: any) => {
    console.log(data, 'onsubmit');
    // const result = await submit(formattedData);

    // if (result) {
    //   onComplete(result, data);
    //   reset();
    // }
  };

  const onPress = handleSubmit(onSubmit);

  return (
    <ScrollView>
      {formData.map((f: any) => {
        return (
          <LineInput
            key={f.name}
            name={f.name}
            validation={{}}
            control={control}
            config={f.config}
            input={f.input}
            error={errors[f.name]}
            containerStyle={{ marginHorizontal: 64, marginBottom: 8 }}
          />
        );
      })}
      <SommdButton style={{ margin: 16 }} title="Submit" onPress={onPress} />
    </ScrollView>
  );
}

function BaseFormButton({ header, children }: { header: string; children: any }) {
  const [visible, setVisible] = useState(false);

  const onClose = () => setVisible(false);

  return (
    <TouchableOpacity
      style={{
        paddingHorizontal: 16,
        height: '100%',
        justifyContent: 'center',
      }}
      onPress={() => setVisible(true)}
      hitSlop={{ top: 16, left: 16, right: 16, bottom: 16 }}
    >
      <SommdText style={{ fontSize: 12 }}>Add</SommdText>
      <Modal
        presentationStyle="fullScreen"
        animationType="slide"
        visible={visible}
        onRequestClose={onClose}
      >
        <View
          style={{
            padding: 16,
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <SommdText>{`Add ${header}`}</SommdText>
          <TouchableOpacity style={{ position: 'absolute', right: 16 }} onPress={onClose}>
            <SommdText>Close</SommdText>
          </TouchableOpacity>
        </View>
        {children}
      </Modal>
    </TouchableOpacity>
  );
}

export function BasicFormButton({
  header,
  formData,
  defaultValues,
}: {
  header: any;
  formData: any;
  defaultValues?: any;
}) {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({ defaultValues });

  return (
    <BaseFormButton header={header}>
      <BaseForm formData={formData} errors={errors} control={control} handleSubmit={handleSubmit} />
    </BaseFormButton>
  );
}

// function ConditionalFormButton({
//   header,
//   formData,
//   condition,
//   defaultValues,
// }: {
//   header: any;
//   formData: any;
//   condition: any;
//   defaultValues?: any;
// }) {
//   const { countries, regionsByCountry, subRegionsByRegion } = useAdminData();
//   const {
//     handleSubmit,
//     formState: { errors },
//     control,
//     watch,
//   } = useForm({ defaultValues });

//   const countryId = watch('country');
//   const regionId = watch('region');
//   const globalConfigs = {
//     country: {
//       name: 'country',
//       input: 'pickerinput',
//       config: {
//         placeholder: 'Select Country',
//         items: countries.map((c: Country) => ({
//           label: c.country,
//           value: c.id,
//         })),
//       },
//     },
//     region: {
//       name: 'region',
//       input: 'pickerinput',
//       config: {
//         placeholder: 'Select Region',
//         items: countryId
//           ? regionsByCountry[countryId].map(({ id, region }: Region) => ({
//               label: region,
//               value: id,
//             }))
//           : [],
//       },
//     },
//     'sub-region': {
//       name: 'sub-region',
//       input: 'pickerinput',
//       config: {
//         placeholder: 'Select SubRegion',
//         items: regionId
//           ? subRegionsByRegion[regionId].map(({ id, subregion }: SubRegion) => ({
//               label: subregion,
//               value: id,
//             }))
//           : [],
//       },
//     },
//   };

//   const modifiedFormData = [
//     ...formData,
//     ...condition.map((c: 'country' | 'region' | 'sub-region') => globalConfigs[c]),
//   ];

//   return (
//     <BaseFormButton header={header}>
//       <BaseForm
//         errors={errors}
//         control={control}
//         formData={modifiedFormData}
//         handleSubmit={handleSubmit}
//       />
//     </BaseFormButton>
//   );
// }

// function Row({ error, label, style, onPress, FormButton }: any) {
//   return (
//     <TouchableOpacity
//       style={{
//         borderWidth: 1,
//         alignItems: 'center',
//         flexDirection: 'row',
//         justifyContent: 'center',
//         flex: 1,
//         ...style,
//       }}
//       onPress={onPress}
//     >
//       <SommdText style={{ flex: 1, textAlign: 'center', padding: 8 }}>{label}</SommdText>
//       {FormButton}
//       {error ? (
//         <SommdText style={{ position: 'absolute', left: 16, fontSize: 12, color: 'red' }}>
//           {error}
//         </SommdText>
//       ) : null}
//     </TouchableOpacity>
//   );
// }

export function useFetch({
  url,
  config,
  condition = true,
}: {
  url: string;
  config?: any;
  condition?: boolean;
}) {
  const [datas, setDatas] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { user } = useUser();
  const len = datas.length || 0;

  useDeepNestedEffect(() => {
    // console.log(len, url, config, '-___-');
    async function init() {
      try {
        setLoading(true);
        setError('');
        const session = await loadSession();

        const { data, status } = await Axios.request({
          method: config ? 'POST' : 'GET',
          url: `${ADMIN_URL}get-${url}`,
          headers: {
            'Content-Type': 'application/json',
            ...(session && { Authorization: `Bearer ${session.id_token}` }),
          },
          ...config,
          responseType: 'json',
        });
        if (status === 401) user?.logout();
        if (data.success) {
          setDatas(data.data);
        }
        setLoading(false);
      } catch (err: any) {
        setError(err.message);
        setLoading(false);
      }
    }

    if (condition && !len) init();
  }, [len, url, condition, config]);

  return { error, loading, datas };
}

// function capitalize(str: string) {
//   return str.charAt(0).toUpperCase() + str.slice(1);
// }

// function FetchTab({ children, url, label, FormButton }: any) {
//   const [open, setOpen] = useState(false);
//   const { error, loading, datas } = useFetch({ url, condition: open });

//   useEffect(() => {
//     if (error && open) setOpen(false);
//   }, [error, open]);

//   return (
//     <>
//       <Row
//         error={error}
//         FormButton={FormButton}
//         label={loading ? 'Loading' : label}
//         onPress={() => setOpen((prev) => !prev)}
//       />
//       {open && !loading && datas ? children(datas) : null}
//     </>
//   );
// }

// function BaseTab({ children, label, FormButton, style }: any) {
//   const [open, setOpen] = useState(false);

//   return (
//     <>
//       <Row
//         style={style}
//         label={label}
//         FormButton={FormButton}
//         onPress={() => setOpen((prev) => !prev)}
//       />
//       {open ? children : null}
//     </>
//   );
// }

// function Tab({
//   keys,
//   label,
//   params,
//   count = 0,
// }: {
//   count?: number;
//   params?: any;
//   keys?: { u: string; k?: string; p?: (arg0: any) => any }[];
//   label: string;
// }) {
//   const { u, k, p } = keys?.[0] || {};

//   const { open, setOpen, error, loading, datas } = useFetch({
//     url: u || '',
//     config: params && params !== -1 && { data: params },
//   });

//   if (!keys) return null;
//   return (
//     <>
//       <Row
//         error={error}
//         add={!!params}
//         count={count}
//         onPress={() => {
//           if (params) setOpen((prev) => !prev);
//         }}
//         style={{ marginLeft: count * 16 }}
//         label={loading ? 'Loading' : label}
//       />

//       {open && k && datas.length
//         ? datas.map((d: any, i: number) => (
//             <Tab
//               key={`${label}${i + 1}`}
//               keys={keys.slice(1)}
//               count={count + 1}
//               label={d[k]}
//               params={p?.(d)}
//             />
//           ))
//         : null}
//     </>
//   );
// }

// export default function AdminBeta() {
//   const {
//     datas: countries,
//     loading: countriesLoading,
//     error: countriesError,
//   } = useFetch({ url: 'countries' });
//   const {
//     datas: regions,
//     loading: regionsLoading,
//     error: regionsError,
//   } = useFetch({ url: 'regions' });
//   const {
//     datas: subRegions,
//     loading: subRegionsLoading,
//     error: subRegionsError,
//   } = useFetch({ url: 'sub-regions' });

//   if (countriesLoading || regionsLoading || subRegionsLoading) return <Loading />;

//   if (countriesError || regionsError || subRegionsError) return <SommdText>Error</SommdText>;

//   const regionsByCountry = regions.reduce((res: any, r: any) => {
//     if (res[r.country_id]) {
//       res[r.country_id].push(r);
//     } else res[r.country_id] = [r];
//     return res;
//   }, {});

//   const subRegionsByRegion = subRegions.reduce((res: any, r: any) => {
//     if (res[r.region_id]) {
//       res[r.region_id].push(r);
//     } else res[r.region_id] = [r];
//     return res;
//   }, {});

//   return (
//     <AdminProvider value={{ countries, regionsByCountry, subRegionsByRegion }}>
//       <Page bottom={false} headerTitle="Admin" style={{ margin: 16 }}>
//         <ScrollView>
//           <Tab
//             params={-1}
//             label="Country"
//             keys={[
//               { u: 'countries', k: 'country', p: (d) => ({ country_id: d.id }) },
//               {
//                 u: 'regions-by-country',
//                 k: 'region',
//                 p: (d) => ({ region_id: d.id, country_id: d.country_id }),
//               },
//               {
//                 u: 'sub-regions-by-region',
//                 k: 'subregion',
//               },
//             ]}
//           />
//           <BaseTab
//             label="Countries"
//             FormButton={
//               <BasicFormButton
//                 header="Country"
//                 formData={[
//                   {
//                     name: 'country',
//                     input: 'textinput',
//                     config: { placeholder: 'Enter country name' },
//                   },
//                 ]}
//               />
//             }
//           >
//             {countries.map((c: Country) => (
//               <BaseTab
//                 key={c.id}
//                 style={{ marginLeft: 16 }}
//                 label={c.country}
//                 FormButton={
//                   <BasicFormButton
//                     header={`Region for ${c.country}`}
//                     defaultValues={{ country_id: c.id }}
//                     formData={[
//                       {
//                         name: 'region',
//                         input: 'textinput',
//                         config: { placeholder: 'Enter region name' },
//                       },
//                     ]}
//                   />
//                 }
//               >
//                 {regionsByCountry[c.id].map((r: Region) => (
//                   <BaseTab
//                     FormButton={
//                       <BasicFormButton
//                         header={`Sub-Region for ${r.region} Region in ${c.country}`}
//                         defaultValues={{ country_id: c.id, region_id: r.id }}
//                         formData={[
//                           {
//                             name: 'sub-region',
//                             input: 'textinput',
//                             config: { placeholder: 'Enter region name' },
//                           },
//                         ]}
//                       />
//                     }
//                     key={r.id}
//                     style={{ marginLeft: 32 }}
//                     label={r.region}
//                   >
//                     {subRegionsByRegion[r.id].map((s: SubRegion) => (
//                       <Row key={s.id} label={s.subregion} style={{ marginLeft: 48 }} />
//                     ))}
//                   </BaseTab>
//                 ))}
//               </BaseTab>
//             ))}
//           </BaseTab>
//           <FetchTab label="Wine Configs" url="wine-configs">
//             {(datas: any) =>
//               Object.keys(datas).map((r: any) => {
//                 const title = r.split('_')[1];

//                 return (
//                   <React.Fragment key={title}>
//                     <Row label={capitalize(title)} style={{ marginLeft: 16 }} />
//                     {(datas[r] || []).map((s: { [arg0: string]: string }) => (
//                       <Row key={`${s.id}`} label={s[title]} style={{ marginLeft: 32 }} />
//                     ))}
//                   </React.Fragment>
//                 );
//               })
//             }
//           </FetchTab>
//           <FetchTab url="wine-names" label="Wine names">
//             {(datas: any) => {
//               return datas.map((d: { id: string; title: string }) => (
//                 <Row key={d.id} label={d.title} style={{ marginLeft: 16 }} />
//               ));
//             }}
//           </FetchTab>
//           <FetchTab url="wine-vineyards" label="Wine vineyards">
//             {(datas: any) => {
//               return datas.map((d: { id: string; title: string }) => (
//                 <Row key={d.id} label={d.title} style={{ marginLeft: 16 }} />
//               ));
//             }}
//           </FetchTab>
//           <FetchTab url="wine-appellations" label="Wine appellations">
//             {(datas: any) => {
//               return datas.map((d: { id: string; title: string }) => (
//                 <Row key={d.id} label={d.title} style={{ marginLeft: 16 }} />
//               ));
//             }}
//           </FetchTab>
//           <FetchTab url="wine-winery" label="Winery">
//             {(datas: any) => {
//               return datas.map((d: { id: string; title: string }) => (
//                 <Row key={d.id} label={d.title} style={{ marginLeft: 16 }} />
//               ));
//             }}
//           </FetchTab>
//           <Row label="Variety" />
//           <Row label="Price" />
//           {/* <Row label="Note" />
//           <Row
//             FormButton={
//               <ConditionalFormButton
//                 header="Wine"
//                 condition={['country', 'region', 'sub-region']}
//                 formData={adminAddWineForm}
//               />
//             }
//             label="Wine"
//           /> */}
//         </ScrollView>
//       </Page>
//     </AdminProvider>
//   );
// }
