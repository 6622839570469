import * as React from 'react';
import Svg, { Rect } from 'react-native-svg';

function SvgComponent(props: any) {
  return (
    <Svg width={33} height={95} viewBox="0 0 33 95" fill="none" {...props}>
      <Rect y={23.173} width={32.32} height={71.348} rx={16.16} fill="#DCB287" />
      <Rect x={12.196} width={9.147} height={67.079} rx={4.574} fill="#DCB287" />
    </Svg>
  );
}

export default SvgComponent;
