import React from 'react';
import { StatusBar } from 'react-native';
import 'react-native-gesture-handler';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import Store from './Store';
import { AppCtx, AppProvider, BiometricProvider, useUser } from './context';
import { LINKING } from './AppScreens';
import { makeApp } from './loader';
import User from './User';
import type { Screen } from './AppScreens';
import { colors } from './styles';

type CommonProps = {
  screens: Screen[];
  navigatorOptions: any;
  screenOptions?: any;
};

export function Container({ children, value }: { children: React.ReactNode; value: AppCtx }) {
  return (
    <Store>
      <SafeAreaProvider>
        <AppProvider value={value}>
          <NavigationContainer linking={LINKING}>
            <BiometricProvider>
              <StatusBar backgroundColor={colors.temp.primary.white} barStyle="dark-content" />
              {children}
            </BiometricProvider>
          </NavigationContainer>
        </AppProvider>
      </SafeAreaProvider>
    </Store>
  );
}

const MainNavigator = createStackNavigator();
function DetailNavigation({
  user,
  screens,
  screenOptions,
  navigatorOptions,
}: CommonProps & { user: User | null; navigation: any }) {
  return makeApp(screens, navigatorOptions, user, screenOptions);
}

export function Authenticated({ screens, navigatorOptions, screenOptions }: CommonProps) {
  const { user } = useUser();

  return (
    <MainNavigator.Navigator screenOptions={{ presentation: 'modal', headerShown: false }}>
      <MainNavigator.Screen name="DetailNavigation">
        {(props) => (
          <DetailNavigation
            {...props}
            user={user}
            screens={screens}
            screenOptions={screenOptions}
            navigatorOptions={navigatorOptions}
          />
        )}
      </MainNavigator.Screen>
    </MainNavigator.Navigator>
  );
}
