import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props: any) {
  return (
    <Svg width={28} height={27} viewBox="0 0 28 27" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.352 16.736A9.594 9.594 0 1 0 3.784 3.168a9.594 9.594 0 0 0 13.568 13.568Zm-3.392-3.392A4.797 4.797 0 1 0 7.176 6.56a4.797 4.797 0 0 0 6.784 6.784Z"
        fill="#fff"
      />
      <Path d="m13.96 16.736 3.392-3.392L27.528 23.52l-3.392 3.392L13.96 16.736Z" fill="#fff" />
    </Svg>
  );
}

export default SvgComponent;
