import React from 'react';
import { StyleSheet, TouchableOpacity, View, ViewStyle, } from 'react-native';

import IconResolver, { IconResolverProps } from './IconResolver';
import Text from './Text';

type Props = IconResolverProps & {
  containerStyle?: ViewStyle,
  badge?: number | boolean,
  badgeColor?: string,
  onPress?: () => void,
}


export default function BadgeIcon({
  containerStyle,
  style,
  badge,
  badgeColor = '#C42124',
  Icon,
  onPress,
  ...props
}: Props) {
  return (
    <TouchableOpacity style={[styles.container, containerStyle]} onPress={onPress}>
      {badge ? (
        <View style={[styles.badgeContainer, { backgroundColor: badgeColor }]}>
          <Text style={styles.badgeText}>{badge}</Text>
        </View>
      ): null}
      <IconResolver {...{Icon, style, ...props}} />
    </TouchableOpacity>
  )
}

const PADDING = 6;

const styles = StyleSheet.create({
  container: {
    padding: 6,
  },
  badgeContainer: {
    position: 'absolute',
    right: -8 + PADDING,
    top: -8 + PADDING,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#2BA956',
    width: 14,
    height: 14,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: 'white',
    borderRadius: 7,
  },
  badgeText: {
    fontSize: 8,
    color: 'white'
  },
})
