import { useState, useEffect } from 'react';
import { Image, ImageSourcePropType } from 'react-native';

export default function useImageDimension(image?: string | number) {
  const [width, setWidth] = useState<number | undefined>();
  const [height, setHeight] = useState<number | undefined>();
  const [source, setSource] = useState<ImageSourcePropType>({});

  useEffect(() => {
    let isMounted = true;
    if (image) {
      if (typeof image === 'string') {
        Image.getSize(
          image,
          (imageWidth, imageHeight) => {
            if (isMounted) {
              setWidth(imageWidth);
              setHeight(imageHeight);
              setSource({ uri: image });
            }
          },
          (error) => {
            // eslint-disable-next-line no-console
            console.log('ScaledImage,Image.getSize failed with error: ', error);
          }
        );
      }

      if (typeof image === 'number') {
        const img = Image.resolveAssetSource(image);
        setWidth(img.width);
        setHeight(img.height);
        setSource(image);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [image]);

  return { source, width, height };
}
