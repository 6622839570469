import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props: any) {
  return (
    <Svg width={32} height={32} viewBox="0 0 32 32" fill="none" {...props}>
      <Path
        d="M20.467 8.714V8.4a3.275 3.275 0 0 0-2.565-3.197 8.516 8.516 0 0 0-3.695 0A3.275 3.275 0 0 0 11.643 8.4v.314"
        stroke="#333"
        strokeWidth={2}
        strokeLinecap="square"
      />
      <Path
        d="M4.84 23.205 4.07 12h23.825l-1.133 11.3a3 3 0 0 1-2.985 2.7H7.832a3 3 0 0 1-2.993-2.795Z"
        fill="#452A21"
        stroke="#452A21"
        strokeWidth={2}
      />
    </Svg>
  );
}

export default SvgComponent;
