/* eslint-disable @typescript-eslint/naming-convention */
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import Axios, { AxiosRequestConfig } from 'axios';

import { useStore, useUser } from '../context';
import { loadSession, refreshToken, startSession } from '../session';
import { BASE_URL } from '../constants';

const getHeaders = () => {
  const headers = {
    'Content-Type': 'application/json',
  };

  return headers;
};
const NO_OF_RETRY = 3;
const headers = getHeaders();

const createRequest = () => async (endpoint: string, config?: AxiosRequestConfig) => {
  const session = await loadSession();
  return Axios.request({
    ...config,
    ...(!config?.method && { method: 'POST' }),
    headers: {
      ...headers,
      ...(session && { Authorization: `Bearer ${session.id_token}` }),
    },
    url: endpoint,
    responseType: 'json',
  });
};

export function useAsyncSubmit(
  endpoint: string,
  config?: AxiosRequestConfig
): {
  submit: (v?: Record<string, any>) => Promise<any>;
  error?: string;
  setError: Dispatch<SetStateAction<string>>;
  loading?: boolean;
} {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { user } = useUser();

  const api = useMemo(() => createRequest(), []);

  useEffect(() => {
    let retryCount = 0;
    Axios.interceptors.response.use(undefined, async (error) => {
      const originalRequest = error.config;

      if (error.response.status === 401) {
        if (retryCount < NO_OF_RETRY || !retryCount) {
          const session = await loadSession();

          if (session) {
            retryCount += 1;
            const access_token = await refreshToken(session.refresh_token);

            if (access_token) {
              await startSession({ ...session, id_token: access_token });

              return Axios({
                ...originalRequest,
                headers: {
                  ...originalRequest.headers,
                  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                  Authorization: `Bearer ${access_token}`,
                },
              });
            }

            user && user.logout();
          }
        } else {
          user && user.logout();
        }
      }

      return Promise.reject(error);
    });
  }, [user]);

  const submit = useCallback(
    async (data: any) => {
      try {
        setError('');
        setLoading(true);

        const result = await api(`${config?.url ?? BASE_URL}${endpoint}`, {
          ...config,
          ...(data && { data }),
        });
        setLoading(false);

        if (result.status === 401) user?.logout();

        if (result.data.error) {
          setError(result.data.message);
        } else if (result.data.success) {
          return result.data.data || result.data.message || {};
        } else if (result?.data) return result.data;
        return false;
      } catch (error) {
        // TODO: Handle different parsing techniques for web and mobile
        // const { message } = error.toJSON();
        setError('Error');
        setLoading(false);
        return {};
      }
    },
    [api, config, endpoint, user]
  );

  return { submit, error, setError, loading };
}

export function useStateOrFetch(
  endpoint: string,
  assert: (state: Record<string, any>) => boolean,
  action: string,
  config?: AxiosRequestConfig
): {
  state: Record<string, any>;
  error?: string;
  loading?: boolean;
} {
  const { submit, error, loading } = useAsyncSubmit(endpoint, config);
  const [state, dispatch] = useStore();

  useEffect(() => {
    async function fetchData() {
      const data = await submit();

      if (data) {
        dispatch({ type: action, payload: data });
      }
    }
    if (!assert(state)) fetchData();
  }, [action, assert, dispatch, state, submit]);

  return { state, error, loading };
}
