import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props: any) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path stroke="#FFF" strokeWidth={4} strokeLinecap="square" d="M9 16h14M16 9v14" />
    </Svg>
  );
}

export default SvgComponent;
