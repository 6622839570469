import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props: any) {
  return (
    <Svg width={32} height={32} viewBox="0 0 32 32" fill="none" {...props}>
      <Path d="M23.23 22.77 26 20 16 10 6 20l2.77 2.77L16 15.54l7.23 7.23Z" fill="#452A21" />
    </Svg>
  );
}

export default SvgComponent;
