import React, { useContext, useEffect, useState } from 'react';
import { View, StyleSheet, TextInput } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';

import { Button, SommdText } from '../../components';
import { ADMIN_URL } from '../../constants';
import { colors, TextStyles } from '../../styles';
import { useAsyncSubmit } from '../../utils';

import { AdminContext, useAdminData } from './AdminBeta';
import { adminstyles } from './adminHelper';
import { Country, SearchInputType, Region, SubRegion, WineReview } from './adminSelectors';

type CountryDisplay = {
  item: Country;
};

export function getFirstLetters(str: string) {
  const firstLetters = str
    .split(' ')
    .map((word) => word[0])
    .join('');

  return firstLetters;
}

export function NameHelper({ title, value }: { title: string; value: string | boolean | number }) {
  return (
    <View style={{ flexDirection: 'row', marginHorizontal: 16 }}>
      <SommdText style={TextStyles.bold}>{title} : </SommdText>
      <SommdText>{value}</SommdText>
    </View>
  );
}

export function NotesHelper({ label, value }: { label: string; value: string }) {
  return (
    <>
      <SommdText vPadding="medium" style={[TextStyles.extraBold]}>
        {label}
      </SommdText>
      <SommdText style={TextStyles.bold}>{value}</SommdText>
    </>
  );
}

export function ReviewHelper({ value }: { value: WineReview }) {
  const pp = getFirstLetters(value.reviwer);
  return (
    <View style={{ flexDirection: 'row' }}>
      <View
        style={[
          adminstyles.circle,
          {
            backgroundColor: colors.temp.primary.teal,
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: 12,
          },
        ]}
      >
        <SommdText>{pp.toUpperCase()}</SommdText>
      </View>
      <View>
        <SommdText style={[TextStyles.bold]}>{value.reviwer}</SommdText>
        <SommdText>{value.review}</SommdText>
        <SommdText>{value.review_date}</SommdText>
        {/* <SommdText>Score: {}</SommdText> */}
      </View>
    </View>
  );
}

export function CircleHelper() {
  return <View style={[adminstyles.circle, { backgroundColor: colors.temp.primary.teal }]} />;
}

export function DisplayPortion({ title }: { title: string | undefined }) {
  return (
    <View>
      <SommdText style={TextStyles.bold}>{title || ''}</SommdText>
      <Line />
    </View>
  );
}

export function Find({ data }: { data: any }) {
  const { state } = useAdminData();
  const { country: ct, region: rg, subregion: srg } = state;
  const c = ct?.country.find((i: Country) => i.id === data.country_id);
  const r = rg?.region[c?.id].find((i: Region) => i.id === data.region_id);
  const sr = srg?.subregion[r?.id || 0].find((i: SubRegion) => i.id === data.subregion_id);
  const { country } = c;
  const region = r?.region || '';
  const subregion = sr?.subregion || '';
  const datas = { country, region, subregion };
  return datas;
}

export function DisplayCountry(props: CountryDisplay) {
  const { item } = props;
  const { dispatch } = useContext(AdminContext);
  const { submit, loading, error } = useAsyncSubmit('delete-country', {
    url: ADMIN_URL,
  });
  const handleDelete = async () => {
    const data = { id: item.id };
    const result = await submit(data);

    if (result.length && !error) {
      dispatch({
        type: 'DELETE_COUNTRY',
        payload: item.id,
      });
    }
  };
  return (
    <>
      <View style={[styles.countryList]}>
        <SommdText style={[TextStyles.bold]}>
          {item.id} . {item.country}
        </SommdText>
        {!!error && <SommdText style={[TextStyles.error]}>{error}</SommdText>}
        <Button
          title="Delete"
          type="primary"
          disabled={loading}
          textStyle={{ color: colors.temp.primary.bordeaux }}
          onPress={handleDelete}
        />
      </View>
      {/* <Line /> */}
    </>
  );
}

export function DisplayRegion(props: CountryDisplay) {
  const { item: i } = props;
  const { state } = useContext(AdminContext);
  const { region } = state;
  const regionData: SearchInputType[] | undefined = i.id
    ? region?.region[i.id]?.map(({ id, region, country_id }: Region) => ({
        val: region,
        id: country_id,
        rid: id,
      }))
    : [];
  return (
    <>
      <SommdText vMargin="small" style={[TextStyles.bold, styles.coun]}>
        Country: {i.country}
      </SommdText>
      <FlatList
        showsVerticalScrollIndicator={false}
        data={regionData}
        keyExtractor={(item) => item.val}
        renderItem={({ item }) => <DeleteRegion item={item} key={item.val} country={i.id} />}
        ItemSeparatorComponent={() => <Line />}
      />
    </>
  );
}

export function DisplaySubRegion(props: CountryDisplay) {
  const { item: i } = props;
  const [datas, setDatas] = useState<SearchInputType[]>([]);
  const [filteredData, setFilteredData] = useState<SearchInputType[]>([]);
  const { state } = useContext(AdminContext);
  const { region } = state;
  useEffect(() => {
    const regionData: SearchInputType[] | undefined = i.id
      ? region?.region[i.id]?.map(({ id, region, country_id }: Region) => ({
          val: region,
          id: country_id,
          rid: id,
        }))
      : [];
    setDatas(regionData || []);
    setFilteredData(regionData || []);
  }, [i.id, region?.region]);

  const handleSearch = (text: string) => {
    if (text) {
      const newData = datas.filter((t) => {
        const itemData = t.val ? t.val.toUpperCase() : ''.toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setFilteredData(newData);
    } else {
      setFilteredData(datas);
    }
  };
  return (
    <View>
      <SommdText vMargin="small" style={[TextStyles.bold, styles.coun]}>
        Country: {i.country}
      </SommdText>
      <TextInput
        placeholder={`${i.country} regions search....`}
        placeholderTextColor={colors.temp.primary.white}
        onChangeText={handleSearch}
        style={[TextStyles.actualNormal, styles.searchStyle]}
      />
      <FlatList
        showsVerticalScrollIndicator={false}
        data={filteredData}
        keyExtractor={(item) => item.rid}
        renderItem={({ item }) => <DisplayRegionsRegion item={item} key={item.rid} />}
        ItemSeparatorComponent={() => <Seperator all />}
        ListEmptyComponent={<SommdText>No Results found</SommdText>}
      />
    </View>
  );
}

function DisplayRegionsRegion({ item: i }: { item: SearchInputType }) {
  const { state } = useContext(AdminContext);
  const { subregion } = state;
  const subRegionData = i.rid
    ? subregion?.subregion[i.rid]?.map(({ id, subregion, country_id, region_id }: SubRegion) => ({
        val: subregion,
        id: country_id,
        rid: region_id,
        srid: id,
      }))
    : [];
  return (
    <View style={styles.displayRegions}>
      <SommdText style={TextStyles.bold}>{i.val}</SommdText>
      <FlatList
        showsVerticalScrollIndicator={false}
        data={subRegionData}
        keyExtractor={(item) => item.srid}
        renderItem={({ item }) => <DeleteSubRegion item={item} key={item.srid} />}
        ItemSeparatorComponent={() => <Line dark />}
      />
    </View>
  );
}

function DeleteSubRegion({ item }: { item: SearchInputType }) {
  const { dispatch } = useContext(AdminContext);
  const { submit, loading, error } = useAsyncSubmit('delete-sub-region', {
    url: ADMIN_URL,
  });
  const handleDelete = async (subregionid: number | undefined) => {
    const data = { id: subregionid };
    const result = await submit(data);

    if (result.length && !error) {
      dispatch({
        type: 'DELETE_SUB_REGION',
        payload: { ri: item.rid, sri: item.srid },
      });
    }
  };
  return (
    <View style={styles.deleteStyle}>
      <SommdText>{item.val}</SommdText>
      <SommdText style={TextStyles.error}>{error}</SommdText>
      <Button
        title="Delete"
        type="primary"
        disabled={loading}
        textStyle={{ color: colors.temp.primary.bordeaux }}
        onPress={() => handleDelete(item.srid)}
      />
    </View>
  );
}

function DeleteRegion({ item, country }: { item: SearchInputType; country: number }) {
  const { dispatch } = useContext(AdminContext);
  const { submit, loading, error } = useAsyncSubmit('delete-region', {
    url: ADMIN_URL,
  });
  const handleDelete = async (regionid: number | undefined) => {
    const data = { id: regionid };
    const result = await submit(data);

    if (result.length && !error) {
      dispatch({
        type: 'DELETE_REGION',
        payload: { i: country, ri: regionid },
      });
    }
  };
  return (
    <View style={styles.deleteStyle}>
      <SommdText>{item.val}</SommdText>
      <SommdText style={TextStyles.error}>{error}</SommdText>
      <Button
        title="Delete"
        type="primary"
        disabled={loading}
        textStyle={{ color: colors.temp.primary.bordeaux }}
        onPress={() => handleDelete(item.rid)}
      />
    </View>
  );
}

export function Line({ dark }: { dark?: boolean }) {
  return (
    <View
      style={{
        borderBottomWidth: 1,
        borderBottomColor: dark
          ? colors.temp.secondary.creme.light
          : colors.temp.secondary.creme.dark,
      }}
    />
  );
}

export function Seperator({ all }: { all?: boolean }) {
  return <View style={all ? { margin: 8 } : { marginVertical: 4 }} />;
}

export function SeperatorWithLine() {
  return (
    <View style={{ marginVertical: 16 }}>
      <Line />
    </View>
  );
}

const styles = StyleSheet.create({
  countryList: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginHorizontal: 24,
    marginVertical: 8,
    padding: 8,
  },
  search: {
    backgroundColor: colors.temp.primary.white,
    marginBottom: 16,
  },
  page: {
    paddingHorizontal: 64,
    paddingVertical: 16,
  },
  regionList: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 24,
    marginVertical: 8,
  },
  subRegionList: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
    marginHorizontal: 64,
    padding: 8,
  },
  coun: {
    marginVertical: 8,
    paddingVertical: 4,
    color: colors.temp.secondary.bordeaux.light,
  },
  displayRegions: {
    padding: 10,
    borderRadius: 4,
    backgroundColor: colors.temp.secondary.brown[5],
    marginRight: 8,
  },
  deleteStyle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 8,
  },
  searchStyle: {
    padding: 10,
    marginRight: 8,
    marginBottom: 8,
    color: colors.temp.primary.white,
    backgroundColor: colors.temp.primary.teal,
    borderRadius: 4,
  },
});
