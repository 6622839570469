/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, ChangeEvent } from 'react';
import { TextStyles } from '../styles';

import SommdText from './SommdText';

const valid_types = ['image/png', 'image/jpeg'];
const max_size = 2000000;
const validateMimeType = (file: File) => {
  return valid_types.some((type) => file.type === type);
};
const validateSize = (file: File) => {
  return file.size < max_size;
};
const convertToBase64 = async (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = () => reject();
  });
};

export const convertImageURLToBase64 = async (url: string) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = () => reject();
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  });
};

export default function UploadImage({
  name = 'file',
  onSelect,
}: {
  onSelect: (v: string) => void;
  name?: string;
}) {
  const [error, setError] = useState('');
  const [file, setFile] = useState('');
  // eslint-disable-next-line consistent-return
  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setError('');
    // @ts-ignore
    const file = e.target.files[0];
    if (!file) setError('');
    if (!validateMimeType(file)) return setError('Invalid image type');
    if (!validateSize(file)) return setError('Image too large');
    const f = await convertToBase64(file);
    setFile(file.name);
    // @ts-ignore
    onSelect(f);
  };

  return (
    <>
      <label className="camera_label_wine" htmlFor={name}>
        {file || 'Upload image...'}
      </label>
      <input
        className="camera_input_wine"
        type="file"
        name={name}
        id={name}
        onChange={handleChange}
      />
      {error ? <SommdText style={[TextStyles.error]}>{error}</SommdText> : null}
    </>
  );
}
