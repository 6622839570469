import React from 'react';
import { StyleSheet, View, Modal as RNModal, Pressable, } from 'react-native';

import { colors, TextStyles } from '../styles';

import Text from './Text';

export default function Modal({
  title,
  visible,
  onClose,
  children,
  onRequestClose,
  transparent = true
}: {
  title: string,
  visible?: boolean,
  onClose?: () => void,
  transparent?: boolean,
  children: React.ReactNode,
  onRequestClose?: () => void
}) {
  return (
    <RNModal visible={visible} transparent={transparent} onRequestClose={onRequestClose}>
      <View style={styles.container}>
        <Pressable
          children={null}
          onPress={onClose}
          style={[StyleSheet.absoluteFillObject, styles.modalBack]}
        />
        <View style={styles.innerContainer}>
          <Text style={[TextStyles.headerFour, styles.modalHeader]}>{title}</Text>
          {children}
        </View>
      </View>
    </RNModal>
  )
}


const styles = StyleSheet.create({
	container: {
    flex: 1,
    justifyContent: 'center'
  },
  innerContainer: {
    backgroundColor: colors.primaryVariant,
    justifyContent: 'center',
    margin: 16,
    borderRadius: 8,
    padding: 16,
  },
  modalBack: {
    backgroundColor: '#0009',
  },
  modalHeader: {
    fontFamily: 'SFProText-Bold',
    paddingBottom: 16,
    textAlign: 'center',
  },
})

