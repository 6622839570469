/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { View, ViewStyle } from 'react-native';
import { Controller, Control, FieldValues } from 'react-hook-form';

import { FormType } from '../forms';
import inputs from './inputs';

type LineInputProps = FormType & {
  error?: { type?: string; message?: string };
  defaultValue?: any;
  control: Control<FieldValues>;
  containerStyle?: ViewStyle;
};

function LineInput({
  name,
  error,
  validation,
  defaultValue,
  control,
  config,
  input,
  containerStyle,
}: LineInputProps) {
  const InputComponent = inputs[input];
  const booleanCheck = input === 'booleanselect';
  const A = booleanCheck ? config?.defaultBoolean : defaultValue;
  const B = booleanCheck ? false : '';

  return (
    <View key={name} style={containerStyle}>
      <Controller
        control={control}
        name={name}
        render={({ field }) => <InputComponent {...config} {...field} error={error} />}
        defaultValue={A || B}
        rules={validation}
      />
    </View>
  );
}

export default LineInput;
