import { StyleSheet } from 'react-native';
import { colors } from '../../styles';

export const adminstyles = StyleSheet.create({
  search: {
    marginBottom: 16,
  },
  page: {
    paddingVertical: 16,
  },
  list: {
    marginHorizontal: 48,
  },
  shadow: {
    shadowColor: '#171717',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.7,
    shadowRadius: 5,
    elevation: 8,
  },
  displayHelperStyle: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    padding: 10,
    borderRadius: 4,
    backgroundColor: colors.temp.secondary.brown[5],
  },
  packageContainer: {
    padding: 10,
    borderRadius: 4,
    backgroundColor: colors.temp.secondary.brown[5],
    marginRight: 8,
  },
  searchStyle: {
    padding: 10,
    marginRight: 8,
    marginBottom: 8,
    color: colors.temp.primary.white,
    backgroundColor: colors.temp.primary.teal,
    borderRadius: 4,
  },
  circle: {
    width: 48,
    height: 48,
    borderRadius: 24,
  },
});
