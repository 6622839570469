import React, { useRef, useCallback, createElement, useState, useEffect } from 'react';
import { View, Pressable, Animated, FlatListProps, StyleSheet, ViewStyle } from 'react-native';

import { colors } from '../styles';
import { getScreenDimension, isMobile } from '../utils';

const styles = StyleSheet.create({
  contentContainerStyle: {
    alignItems: 'center',
  },
  centerStyle: {
    width: '100%',
  },
  indicatorContainer: {
    zIndex: 2,
    width: '100%',
    paddingVertical: 48,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  dotOuter: {
    overflow: 'hidden',
  },
  dotInner: { position: 'absolute', left: 0, top: 0 },
});

const initialScale = 1;
const scaleDownTo = 0.65;
const dotSize = 8;
const indicatorColor = colors.temp.secondary.bordeaux.light;
const hasWindow = typeof window !== 'undefined';

type StyleProps = {
  driver: Animated.Value;
  pos: number;
  width: number;
  scaleDownTo: number;
  initialScale: number;
};

const styler = ({ driver, pos, width, scaleDownTo, initialScale }: StyleProps) => ({
  transform: [
    {
      scale: driver.interpolate({
        inputRange: [width * (pos - 1), width * pos, width * (pos + 1)],
        outputRange: [scaleDownTo, initialScale, scaleDownTo],
        extrapolate: 'clamp',
      }),
    },
  ],
});

type Props<ItemT> = FlatListProps<ItemT> & {
  data: any[];
  renderItem: any;
  itemWidth: number;
  initialActiveIdx?: number;
  contentContainerStyle?: ViewStyle;
  customStyler?: (props: StyleProps) => Animated.Value;
  onIndexChange: (v: number) => void;
  onSelect?: (item: ItemT, idx: number) => void;
};

function HeroScrollView<ItemT>(props: Props<ItemT>) {
  const {
    data,
    renderItem,
    onIndexChange,
    onSelect,
    itemWidth = 240,
    customStyler,
    initialActiveIdx = 1,
    contentContainerStyle = {},
    keyExtractor,
  } = props;

  const [width, setWidth] = useState<number>(getScreenDimension());
  const scrollViewRef = useRef<any>();
  const selectedIdxRef = useRef<number>(initialActiveIdx);
  const scrollX = React.useRef(new Animated.Value(0));

  useEffect(() => {
    const handle = () => {
      if (hasWindow && !isMobile) {
        const handleResize = () => {
          setWidth(getScreenDimension());
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }
      return false;
    };
    handle();
  }, []);

  useEffect(() => {
    if (initialActiveIdx && !isMobile)
      scrollViewRef.current.scrollToIndex({ index: initialActiveIdx });
  }, [initialActiveIdx]);

  const getActiveIndex = useCallback(
    ({ nativeEvent }) => {
      const { x: userScrollUptoX } = nativeEvent.contentOffset;
      const activeIndex = Math.round(userScrollUptoX / itemWidth);
      if (activeIndex >= 0 && activeIndex < data.length) {
        selectedIdxRef.current = activeIndex;
        if (onIndexChange) onIndexChange(activeIndex);
      }
      return selectedIdxRef.current;
    },
    [data.length, itemWidth, onIndexChange]
  );

  const handleScroll = Animated.event(
    [{ nativeEvent: { contentOffset: { x: scrollX.current } } }],
    { useNativeDriver: true, listener: getActiveIndex }
  );

  const toCenter = 0.5 * (width - itemWidth);

  const memoized = useCallback(
    (obj) => {
      const { item, index } = obj;
      const fn = customStyler ?? styler;

      return (
        <Animated.View
          style={[
            {
              alignItems: 'center',
              width: itemWidth,
            },
            fn({
              driver: scrollX.current,
              pos: index,
              width: itemWidth,
              scaleDownTo,
              initialScale,
            }),
          ]}
          onStartShouldSetResponderCapture={() => {
            return selectedIdxRef.current !== index;
          }}
          onStartShouldSetResponder={() => {
            if (onSelect) return selectedIdxRef.current === index;
            return false;
          }}
          onMoveShouldSetResponderCapture={() => {
            return selectedIdxRef.current !== index;
          }}
          onMoveShouldSetResponder={() => {
            if (onSelect) return selectedIdxRef.current === index;
            return false;
          }}
          onResponderRelease={() => {
            if (selectedIdxRef.current === index) {
              if (onSelect) onSelect(item, index);
            } else {
              scrollViewRef.current.scrollToIndex({ index });
            }
          }}
        >
          {createElement(renderItem, obj)}
        </Animated.View>
      );
    },
    [customStyler, itemWidth, onSelect, renderItem]
  );

  return (
    <>
      <Animated.FlatList
        ref={scrollViewRef}
        horizontal
        onScroll={handleScroll}
        scrollEventThrottle={16}
        snapToInterval={itemWidth}
        showsHorizontalScrollIndicator={false}
        decelerationRate="fast"
        contentContainerStyle={[
          styles.contentContainerStyle,
          data && data.length ? { paddingHorizontal: toCenter } : styles.centerStyle,
          contentContainerStyle,
        ]}
        initialScrollIndex={initialActiveIdx}
        getItemLayout={(_, index: number) => ({
          length: itemWidth,
          offset: itemWidth * index,
          index,
        })}
        keyExtractor={keyExtractor}
        data={data}
        renderItem={memoized}
      />
      <View style={styles.indicatorContainer}>
        {Array.from({ length: data.length }).map((_, idx) => {
          const translateX = scrollX.current.interpolate({
            inputRange: [itemWidth * (idx - 1), itemWidth * (idx + 1)],
            outputRange: [-dotSize, dotSize],
            extrapolate: 'clamp',
          });

          return (
            <Pressable
              key={`dot${idx + 1}`}
              onPress={() =>
                scrollViewRef.current.scrollToIndex({
                  index: idx,
                })
              }
              style={[
                {
                  width: dotSize,
                  height: dotSize,
                  borderRadius: dotSize / 2,
                  backgroundColor: `${indicatorColor}4D`,
                  marginLeft: idx === 0 ? 0 : dotSize / 2,
                },
                styles.dotOuter,
              ]}
            >
              <Animated.View
                key={`dot${idx + 1}`}
                style={[
                  {
                    width: dotSize,
                    height: dotSize,
                    transform: [{ translateX }],
                    backgroundColor: indicatorColor,
                  },
                  styles.dotInner,
                ]}
              />
            </Pressable>
          );
        })}
      </View>
    </>
  );
}

export default HeroScrollView;
