import * as React from 'react';
import Svg, { Rect, Path, Mask, G, Circle } from 'react-native-svg';

function SvgComponent(props: any) {
  return (
    <Svg width={32} height={32} viewBox="0 0 32 32" fill="none" {...props}>
      <Rect x={8} y={18} width={2} height={9} rx={1} fill="#452A21" />
      <Rect
        x={14}
        y={26}
        width={2}
        height={10}
        rx={1}
        transform="rotate(90 14 26)"
        fill="#452A21"
      />
      <Path
        d="M3 6h12v6a6 6 0 0 1-12 0V6Z"
        stroke="#452A21"
        strokeWidth={2}
        strokeLinejoin="round"
      />
      <Path d="M2 9h14v3a7 7 0 1 1-14 0V9Z" fill="#452A21" />
      <Mask id="a" x={15} y={14} width={16} height={14}>
        <Path d="M30.5 27.984A15.5 15.5 0 0 0 21.686 14L15 27.984h15.5Z" fill="#452A21" />
      </Mask>
      <G mask="url(#a)">
        <Path d="M30.5 27.984A15.5 15.5 0 0 0 21.686 14L15 27.984h15.5Z" fill="#452A21" />
        <Circle cx={22.5} cy={21.5} r={1.5} fill="#fff" />
      </G>
      <Mask id="b" x={21} y={24} width={7} height={4}>
        <Path fill="#C4C4C4" d="M21 24h7v4h-7z" />
      </Mask>
      <G mask="url(#b)">
        <Circle cx={24.5} cy={27.5} r={2.5} fill="#fff" />
      </G>
    </Svg>
  );
}

export default SvgComponent;
