import React, { useEffect, useState } from 'react';
import 'react-native-gesture-handler';
import { BottomTabBarProps, createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import BottomNav from './BottomNav';
import { DEFAULT_TAB } from './constants';
import { isMobile, useAsyncSubmit } from './utils';
import { useStore } from './context';
import { Loading, SommdText } from './components';

import { Cellar, Pairing, Camera, Cart, Home } from './Lazy';

const TAB_SCREENS = [
  { name: 'Home', Comp: Home, options: {} },
  { name: 'Cellar', Comp: Cellar },
  { name: 'Camera', Comp: Camera },
  { name: 'Cart', Comp: Cart },
  { name: 'Pairing', Comp: Pairing },
];
const config: Record<string, string> = {
  method: 'GET',
};
const Tab = createBottomTabNavigator();
export function BottomTabs({ navigation }: { navigation: any }) {
  const [end, setEnd] = useState(false);
  const { submit: submitQuestions, error: fetchError } = useAsyncSubmit('get-questions');
  const { submit, error } = useAsyncSubmit('get-user-profile', config);
  const { submit: getWines } = useAsyncSubmit('getwines', {
    method: 'POST',
    data: {},
  });
  const [state, dispatch] = useStore();

  useEffect(() => {
    const fetchProfile = async () => {
      const res = await submit();

      if (res) {
        dispatch({ type: 'INIT_PROFILE', payload: res });
        fetchWine();
        // setEnd(true);
      }
    };

    const fetchWine = async () => {
      const winesList = await getWines();
      if (winesList) {
        dispatch({ type: 'POPULATE_WINES', payload: winesList });
        setEnd(true);
      }
    };

    const checkProfile = () => {
      if (!state.profile) fetchProfile();
      else setEnd(true);
    };

    const fetchQuestions = async () => {
      const pes = await submitQuestions();

      if (pes) {
        dispatch({ type: 'POPULATE_QUESTIONS', payload: pes });
        if (!pes.profile_complete && isMobile) navigation.replace('Onboarding');
      }
    };
    if (!state.questions.allIds.length) fetchQuestions();
    else checkProfile();
  }, [submit, dispatch, navigation, state.profile, submitQuestions, state.questions.allIds.length]);

  const { profile } = state;
  if (fetchError) return <SommdText>Error while fetching your preferences.</SommdText>;
  if (error) return <SommdText>Error while fetching your profile</SommdText>;
  if (!end) return <Loading />;
  if (!profile) return null;

  return (
    <Tab.Navigator
      initialRouteName={DEFAULT_TAB}
      screenOptions={{ headerShown: false }}
      tabBar={(props: JSX.IntrinsicAttributes & BottomTabBarProps) => <BottomNav {...props} />}
    >
      {TAB_SCREENS.map(({ name, options, Comp }) => (
        <Tab.Screen key={name} name={name} options={options} component={Comp} />
      ))}
    </Tab.Navigator>
  );
}
