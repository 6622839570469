import React, { createElement, useMemo } from 'react';
import { PressableProps, ViewStyle, Platform } from 'react-native';

import { View, useScreenClass, CONFIG } from './helpers';
import { renderProps, RenderPropsType } from '../../utils';

const hasWidth = (widths: Record<string, any>) =>
  Object.keys(widths).reduce((acc, cur) => acc || widths[cur]);
const getWidth = (gridColumns: number, w?: number | string) => {
  if (typeof w !== 'number') return '0%';
  const normalizedWidth = Math.max(0, Math.min(gridColumns, w));
  return `${(100 / gridColumns) * normalizedWidth}%`;
};
const { gridColumns } = CONFIG;

const resolve = (
  data: number | Record<string, number>,
  screenClass: string
): Record<string, number> => {
  if (!data) return {};
  if (typeof data === 'number') return { [screenClass]: data };
  return data;
};

type Props = {
  children: React.ReactNode;
  size?: number;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  xxl?: number;
  offset?: Record<string, number> | number;
  pull?: Record<string, number> | number;
  push?: Record<string, number> | number;
  width?: number;
  style?: ViewStyle;
  component?: any;
} & PressableProps &
  RenderPropsType;

export default function Col({
  children,
  size = CONFIG.gridColumns,
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
  offset = {},
  pull = {},
  push = {},
  style = {},
  // gutterWidth = CONFIG.gutterWidth,
  component = View,
  width: w,
  ...otherProps
}: Props) {
  const wi: Record<string, number | string | undefined> = useMemo(
    () => ({
      xs,
      sm,
      md,
      lg,
      xl,
      xxl,
    }),
    [lg, md, sm, xl, xs, xxl]
  );
  const screenClass = useScreenClass();
  const resolvedPull = resolve(pull, screenClass);
  const resolvedPush = resolve(push, screenClass);
  const resolvedOffset = resolve(offset, screenClass);

  const theStyle = useMemo(() => {
    const styles: ViewStyle = {
      // minHeight: 1,
      display: 'flex',
      flex: style?.flex ?? (Platform.OS === 'web' ? 1 : 0),
      flexDirection: 'column',
      position: 'relative',
      // paddingLeft: -gutterWidth / 2,
      // paddingRight: -gutterWidth / 2,
      // width: '100%',
      // flexBasis: '100%',
      // flexGrow: 0,
      // flexShrink: 0,
      maxWidth: '100%',
      // marginLeft: '0%',
      right: 'auto',
      left: 'auto',
      overflow: 'hidden',
    };

    const currentWidth = getWidth(gridColumns, wi[screenClass] || size);
    // const isSizedToContent = wi[screenClass] === 'content';

    // styles.flexBasis = isSizedToContent ? 'auto' : currentWidth || styles.flexBasis;
    // styles.width = styles.flexBasis;
    styles.width = currentWidth;
    styles.maxWidth = currentWidth || styles.maxWidth;
    styles.marginLeft = getWidth(gridColumns, resolvedOffset[screenClass]) || styles.marginLeft;
    // styles.marginRight = getWidth(gridColumns, resolvedOffset[screenClass]) || styles.marginRight;
    styles.right = getWidth(gridColumns, resolvedPull[screenClass]) || styles.right;
    styles.left = getWidth(gridColumns, resolvedPush[screenClass]) || styles.left;

    if (!hasWidth(wi)) {
      styles.flexBasis = 0;
      styles.flexGrow = 1;
    }

    if (w) {
      styles.flexBasis = 'unset';
      styles.width = w;
    }

    return styles;
  }, [resolvedOffset, resolvedPull, resolvedPush, screenClass, size, style?.flex, w, wi]);

  return createElement(
    component,
    renderProps({ ...otherProps, style: [theStyle, style] }),
    children
  );
}
