import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props: any) {
  return (
    <Svg width={32} height={32} viewBox="0 0 32 32" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 28H6.235V14.706H4L16.118 4l12.117 10.706H26V28h-8v-7h-4v7Z"
        fill="#452A21"
      />
    </Svg>
  );
}

export default SvgComponent;
