import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props: any) {
  return (
    <Svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <Path d="M4.385 5 3 6.385l5 5 5-5L11.615 5 8 8.615 4.385 5Z" fill="#452A21" />
    </Svg>
  );
}

export default SvgComponent;
