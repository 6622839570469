import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props: any) {
  return (
    <Svg width={32} height={32} viewBox="0 0 32 32" fill="none" {...props}>
      <Path
        stroke="#FFF"
        strokeWidth={4}
        strokeLinecap="square"
        d="M10.481 21.69 22.172 10M22 21.69 10.31 10"
      />
    </Svg>
  );
}

export default SvgComponent;
