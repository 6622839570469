import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props: any) {
  return (
    <Svg width={13} height={20} viewBox="0 0 13 20" fill="none" {...props}>
      <Path d="M13 2.77 10.23 0l-10 10 10 10L13 17.23 5.77 10 13 2.77Z" fill="currentColor" />
    </Svg>
  );
}

export default SvgComponent;
