import React, { useContext } from 'react';

interface WizardCtx {
  page: number;
  next: () => void;
  prev: () => void;
}

const WizardContext = React.createContext<WizardCtx>({
  page: 0,
  prev: () => {},
  next: () => {},
});

export function useWizard() {
  const ctx = useContext(WizardContext);
  return ctx;
}

export const WizardProvider = WizardContext.Provider;
