import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props: any) {
  return (
    <Svg width={32} height={32} viewBox="0 0 32 32" fill="none" {...props}>
      <Path
        d="M14.473 8.026 16 9.829l1.527-1.803C18.578 6.783 20.198 6 21.85 6 24.738 6 27 8.258 27 11.194c0 1.818-.795 3.623-2.599 5.841-1.827 2.247-4.476 4.67-7.864 7.761l-.003.003-.54.494-.53-.482c0-.001 0-.002-.002-.002-3.386-3.097-6.036-5.523-7.863-7.771C5.795 14.817 5 13.012 5 11.193 5 8.258 7.262 6 10.15 6c1.652 0 3.272.783 4.323 2.026Z"
        stroke="currentColor"
        strokeWidth={4}
      />
    </Svg>
  );
}

export default SvgComponent;
