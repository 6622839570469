import React from 'react';

import { Authenticated, Container } from './Container';
import { useAppInit } from './loader';
import { AUTH_SCREENS, SCREENS } from './AppScreens';
// import NavBar from './NavBar';

const STACK_OPTIONS = {
  detachInactiveScreens: true,
  screenOptions: {
    headerMode: 'screen',
  },
};

const screens = [...AUTH_SCREENS, ...SCREENS];

export default function App() {
  const { user, setUser, onLogin } = useAppInit();

  if (user === undefined) return null;
  if (user) user.onLogout = () => setUser(null);
  return (
    <Container value={{ user, onLogin }}>
      <Authenticated screens={screens} navigatorOptions={STACK_OPTIONS} />
    </Container>
  );
}
