import React from 'react';
import { StyleSheet, TouchableOpacity, View, ViewStyle, Image } from 'react-native';

import { getScreenDimension } from '../utils';

import Text from './Text';
import WhiteWineIcon from '../assets/whiteWineIcon';
import {
  NAVBAR_BOTTOMARC_HEIGHT,
  NAVBAR_EXTENSION_HEIGHT,
  NAVBAR_HEIGHT,
  NAVBAR_TOPARC_HEIGHT,
} from '../constants';

const width = getScreenDimension();

export function NavBarTitle({
  containerStyle,
  onPress,
}: {
  containerStyle?: ViewStyle;
  onPress?: () => void;
}) {
  const children = (
    <>
      <WhiteWineIcon width={20} height={30} style={{ marginBottom: 5 }} />
      <View style={styles.title}>
        <Text style={styles.primaryText}>
          somm
          <Text style={styles.secondaryText}>&apos;d</Text>
        </Text>
      </View>
    </>
  );
  return onPress ? (
    <TouchableOpacity style={[styles.textContainer, containerStyle]} onPress={onPress}>
      {children}
    </TouchableOpacity>
  ) : (
    <View style={[styles.textContainer, containerStyle]}>{children}</View>
  );
}

export function NavExtend({
  color,
  mergeWithScreen,
}: {
  color?: string;
  mergeWithScreen?: boolean;
}) {
  return (
    <>
      <Image
        source={require('../assets/cellarTop.png')}
        style={[
          styles.navExtendImageBackground,
          {
            height: NAVBAR_HEIGHT + NAVBAR_EXTENSION_HEIGHT,
            top: mergeWithScreen ? -NAVBAR_TOPARC_HEIGHT - 15 : -NAVBAR_HEIGHT,
          },
        ]}
      />
      <View
        style={[
          styles.bottomArcContainer,
          {
            top: mergeWithScreen ? NAVBAR_EXTENSION_HEIGHT - 5 : 0,
          },
        ]}
      >
        <View style={[styles.bottomArc, { backgroundColor: color ?? '#F2F2F2' }]} />
      </View>
    </>
  );
}

export function NavExtenstionWrapper({
  mergeWithScreen,
  children,
  containerStyle,
  innerContainerStyle,
}: {
  children: React.ReactNode;
  mergeWithScreen?: boolean;
  containerStyle?: ViewStyle;
  innerContainerStyle?: ViewStyle;
}) {
  return (
    <View style={[styles.flex, containerStyle]}>
      <NavExtend mergeWithScreen={mergeWithScreen} />
      <View style={[styles.flex, !mergeWithScreen && styles.margin, innerContainerStyle]}>
        {children}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  textContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  title: {
    paddingLeft: 5,
  },
  primaryText: {
    fontSize: 27,
    color: '#FFFFFF',
    fontFamily: 'SFProText-Light',
  },
  secondaryText: {
    fontSize: 27,
    color: '#91191B',
    fontFamily: 'SFProText-Medium',
  },

  navExtendImageBackground: {
    alignItems: 'center',
    overflow: 'hidden',
    position: 'absolute',
    width: '100%',
  },
  bottomArcContainer: {
    height: NAVBAR_BOTTOMARC_HEIGHT,
    alignItems: 'center',
    overflow: 'hidden',
    position: 'absolute',
    width: '100%',
  },
  bottomArc: {
    position: 'absolute',
    height: width * 4,
    width: width * 4,
    borderRadius: width * 2,
  },

  flex: {
    flex: 1,
  },
  margin: {
    marginTop: 40,
  },
});
