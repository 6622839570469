import React from 'react';
import { StyleSheet, View, TouchableOpacity } from 'react-native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
// import Animated from 'react-native-reanimated';

import { colors, TextStyles } from '../styles';

import { getScreenDimension } from '../utils';
import SommdText from './SommdText';

// const ACTIVE_COLOR = colors.temp.primary.white;
const INACTIVE_COLOR = colors.temp.primary.teal;

const dimension = getScreenDimension();

// const TabItem = ({ color, label, }: { color: string, label: string, }) => (
//   <Text style={[{ color }, TextStyles.small, styles.tabItem]} numberOfLines={1}>{label}</Text>
// )

const Tab = createMaterialTopTabNavigator();
export default function MaterialTabs({
  tabs,
  containerStyle,
  tabStyle,
}: {
  tabs: {
    name: string;
    Component: React.ComponentType<any>;
    config?: any;
    options?: any;
  }[];
  containerStyle?: Record<string, any>;
  tabStyle?: Record<string, any>;
}) {
  return (
    <Tab.Navigator
      // tabBarOptions={{
      // activeTintColor: ACTIVE_COLOR,
      // inactiveTintColor: INACTIVE_COLOR,
      // style: [styles.tabContainer, tabStyle],
      // labelStyle: [TextStyles.small, styles.tabItem],
      // indicatorStyle: styles.tabIndicator,
      // tabStyle: { width: 100 },
      // scrollEnabled: true,
      // tabStyle: { width: 90 }
      // }}
      tabBar={(props: any) => <CustomTabBar {...{ ...props, tabStyle, containerStyle }} />}
      style={containerStyle}
    >
      {tabs.map((tab) => {
        const { name, Component, config, options } = tab;
        return (
          <Tab.Screen options={{ lazy: true, ...options }} key={name} name={name}>
            {(props) => <Component {...config} {...props} />}
          </Tab.Screen>
        );
      })}
    </Tab.Navigator>
  );
}

function CustomTabBar({
  state,
  descriptors,
  navigation,
}: // position
any) {
  return (
    <View style={[styles.container]}>
      {state.routes.map((route: { key: string; name: string }, index: number) => {
        const { options } = descriptors[route.key];
        const label =
          // eslint-disable-next-line no-nested-ternary
          options.tabBarLabel !== undefined
            ? options.tabBarLabel
            : options.title !== undefined
            ? options.title
            : route.name;

        const isFocused = state.index === index;

        const onPress = () => {
          const event = navigation.emit({
            type: 'tabPress',
            target: route.key,
            canPreventDefault: true,
          });

          if (!isFocused && !event.defaultPrevented) {
            navigation.navigate(route.name);
            // if (scrollEnabled) scrollRef.current.scrollTo({ x: index * 50, animated: true })
          }
        };

        // const opacity = Animated.interpolateNode(position, {
        //   inputRange,
        //   outputRange: inputRange.map(i => (i === index ? 1 : 0.8)),
        // });

        return (
          <TouchableOpacity
            accessibilityRole="button"
            accessibilityState={isFocused ? { selected: true } : {}}
            accessibilityLabel={options.tabBarAccessibilityLabel}
            testID={options.tabBarTestID}
            onPress={onPress}
            key={route.key}
            // style={{ width: dimension / state.routes.length }}
          >
            {/* <Animated.Text */}
            <SommdText
              hPadding="medium"
              vPadding="medium"
              numberOfLines={1}
              style={[
                TextStyles.small,
                styles.label,
                TextStyles.bold,
                {
                  // opacity,
                  // color: isFocused ? ACTIVE_COLOR : INACTIVE_COLOR,
                  width: dimension / state.routes.length,
                },
                // index === 0 && styles.first,
                // index === state.routes.length - 1 && styles.last,
              ]}
            >
              {label}
              {/* </Animated.Text> */}
            </SommdText>
            {isFocused && <View style={styles.indicator} />}
          </TouchableOpacity>
        );
      })}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'space-around',
    flexDirection: 'row',
  },
  indicator: {
    height: 3,
    backgroundColor: INACTIVE_COLOR,
  },
  label: {
    textAlign: 'center',
  },
});
