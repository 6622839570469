import React, { useState, useEffect, Suspense } from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import User from './User';
import { Session } from './session';
import { isMobile } from './utils';
import type { Screen } from './AppScreens';
import { DEFAULT_SCREEN, LOGOUT_REDIRECT } from './constants';
import { Loading } from './components';

function createRoute(user: User | null, RouteComp: React.FC<any>) {
  return function createdRoute(Comp: React.FC<any>, options?: Record<string, any>) {
    return <RouteComp {...options} user={user} Comp={Comp} />;
  };
}
type Props = {
  user: User | null;
  Comp: React.FC<any>;
  navigation: any;
  route: any;
};

function ProtectedScreen({ user, Comp, ...other }: Props) {
  const { navigation } = other;
  useEffect(() => {
    if (!user) navigation.replace(LOGOUT_REDIRECT, { referral: Comp.name });
  }, [Comp.name, navigation, user]);

  if (user) return <Comp {...other} />;
  return null;
}

function RestrictedScreen({ user, Comp, ...other }: Props) {
  const { navigation, route } = other;
  useEffect(() => {
    if (user) navigation.replace(DEFAULT_SCREEN);
  }, [navigation, route.params, user]);

  if (user) return null;
  return <Comp {...other} />;
}

function makeRoute(user: User | null, route?: string) {
  if (isMobile) return null;

  const restrictedRoute = createRoute(user, RestrictedScreen);
  const protectedRoute = route === 'protected' ? createRoute(user, ProtectedScreen) : null;

  return route === 'restricted' ? restrictedRoute : protectedRoute;
}

const Stack = createStackNavigator();

export function makeApp(
  screens: Screen[],
  navigatorOptions: any,
  user: User | null,
  screenOptions?: any
) {
  return (
    <Stack.Navigator
      screenOptions={{
        ...navigatorOptions,
        headerShown: false,
        cardStyle: { flex: 1 },
      }}
    >
      {screens.map((screen) => {
        const { name, Comp, config, route } = screen;

        const wrapper = makeRoute(user, route);
        const options = screenOptions?.[name] || {};

        return (
          <Stack.Screen key={name} name={name} options={options}>
            {(props) => (
              <Suspense fallback={<Loading />}>
                {wrapper ? wrapper(Comp, { ...props, ...config }) : <Comp {...props} {...config} />}
              </Suspense>
            )}
          </Stack.Screen>
        );
      })}
    </Stack.Navigator>
  );
}

export function useAppInit() {
  const [user, setUser] = useState<User | null>();

  useEffect(() => {
    User.load().then(setUser);
  }, []);

  const onLogin = async (session: Session) => {
    await User.create(session).then(setUser);
  };

  return { user, setUser, onLogin };
}
