import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props: any) {
  return (
    <Svg width={32} height={32} viewBox="0 0 32 32" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.368 10H7a1 1 0 0 0-1 1v13a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V11a1 1 0 0 0-1-1H21.632l-.496-1.275L20.466 7h-8.931l-.671 1.725L10.368 10Zm-.697-3.725A2 2 0 0 1 11.535 5h8.93a2 2 0 0 1 1.864 1.275L23 8h2a3 3 0 0 1 3 3v13a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3V11a3 3 0 0 1 3-3h2l.67-1.725ZM23 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm-11 5a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm4-6a6 6 0 1 0 0 12 6 6 0 0 0 0-12Z"
        fill="#fff"
      />
    </Svg>
  );
}

export default SvgComponent;
