import React from 'react';
import { View, TouchableOpacity, ViewStyle } from 'react-native';

import IconeRsolver, { IconResolverProps } from './IconResolver';

type Props = IconResolverProps & {
  disabled?: boolean;
  dimension?: number;
  containerStyle?: ViewStyle;
  onPress?: () => void;
};

export default function IconButton({
  containerStyle,
  style,
  disabled,
  onPress,
  Icon,
  ...rest
}: Props) {
  return onPress ? (
    <TouchableOpacity
      style={containerStyle}
      disabled={disabled}
      onPress={onPress}
      hitSlop={{ top: 8, bottom: 8, left: 8, right: 8 }}
    >
      <IconeRsolver {...{ Icon, style }} {...rest} />
    </TouchableOpacity>
  ) : (
    <View style={containerStyle}>
      <IconeRsolver {...{ Icon, style }} {...rest} />
    </View>
  );
}
