import React, { useEffect } from 'react';
import { Animated, StyleSheet, ViewStyle } from 'react-native';
import { timing, useAnimation } from '@lib/anim';
import Svg, { Rect } from 'react-native-svg';

import { colors, LayoutStyles, TextStyles } from '../styles';

import View from './View';
import SommdText from './SommdText';

const AnimatedRect = Animated.createAnimatedComponent(Rect);

const styler = (driver: any) => ({
  transform: [
    {
      translateY: driver.interpolate({
        inputRange: [0, 1],
        outputRange: [-25, 10],
      }),
    },
  ],
});
const colorStyler = (driver: any) =>
  driver.interpolate({
    inputRange: [0, 0.4, 1],
    outputRange: ['#9D0041', '#589D97', '#589D97'],
  });

const head = 's';
const tail = `mm'd`;

export default function Loading({ containerStyle }: { containerStyle?: ViewStyle }) {
  const [animatedStyles, driver] = useAnimation(0, styler);
  const [colorStyles, colorDriver] = useAnimation(0, colorStyler);

  useEffect(() => {
    Animated.loop(
      Animated.parallel([
        Animated.sequence([
          timing(driver, 1, { duration: 1000 }),
          timing(driver, 0, { duration: 1000 }),
        ]),
        Animated.sequence([
          timing(colorDriver, 1, { duration: 1000, useNativeDriver: false }),
          timing(colorDriver, 0, { duration: 1000, useNativeDriver: false }),
        ]),
      ]),
      { iterations: -1 }
    ).start();
  }, [colorDriver, colorStyles, driver]);

  return (
    <View style={[styles.container, containerStyle]}>
      <View style={styles.innerContainer}>
        <SommdText style={[TextStyles.headerOne, styles.header]}>{head}</SommdText>
        <View>
          <Animated.View style={animatedStyles}>
            <Svg width="34" height="96" viewBox="0 0 34 96" fill="none">
              <AnimatedRect
                x="0.694336"
                y="23.5659"
                width="32.469"
                height="71.6768"
                rx="16.2345"
                fill={colorStyles}
              />
              <AnimatedRect
                x="12.9468"
                y="0.286377"
                width="9.18934"
                height="67.3885"
                rx="4.59467"
                fill={colorStyles}
              />
            </Svg>
          </Animated.View>
          <View style={(LayoutStyles.shadow, styles.circle)} />
        </View>
        <SommdText style={[TextStyles.headerOne, styles.header]}>{tail}</SommdText>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.temp.primary.white,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    fontFamily: 'Quicksand-Bold',
    lineHeight: 69,
    color: colors.temp.secondary.brown.normal,
  },
  circle: {
    left: 8,
    bottom: 32,
    position: 'absolute',
    width: 19,
    height: 19,
    backgroundColor: 'white',
    borderRadius: 19 / 2,
  },
});
