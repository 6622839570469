import React, { useReducer } from 'react';
import { StoreProvider } from './context';
import { AdminWine, GrapeVine } from './screens/Admin/adminSelectors';
import {
  Address,
  NormalizedAddresses,
  NormalizedQuestions,
  // NormalizedCart,
  NormalizedState,
  Profile,
  Wine,
  Filters,
  Cellar,
  NormalizedCart,
} from './selectors';

export interface Action {
  type: string;
  payload: any;
}

const questionsReducer = (state: NormalizedQuestions, action: Action) => {
  switch (action.type) {
    case 'POPULATE_QUESTIONS': {
      const { profile_complete, ...questions } = action.payload;
      return {
        profile_complete,
        allIds: Object.keys(questions),
        byId: Object.keys(questions).reduce((acc, curr) => {
          const val = questions[curr];
          // @ts-ignore
          acc[curr] = val;
          // @ts-ignore
          const selections = val.answers.filter((a) => a.user_selection).map((a) => a.answer_id);
          // @ts-ignore
          acc[curr].selected = selections;
          return acc;
        }, {}),
      };
    }
    case 'UPDATE_QUESTIONS': {
      return {
        ...state,
        profile_complete: true,
        byId: action.payload.reduce(
          (
            acc: { [arg0: string]: Record<string, any> },
            v: {
              question_id: string;
              answer_ids: string;
            }
          ) => {
            const { question_id, answer_ids } = v;
            const curr = state.byId[question_id];
            acc[question_id] = {
              ...curr,
              selected: answer_ids,
              answers: curr.answers.map((a) => ({
                ...a,
                user_selection: answer_ids.includes(a.answer_id),
              })),
            };
            return acc;
          },
          {}
        ),
      };
    }
    default:
      return state;
  }
};

const findAndReplaceWine = (wine: any, state: Profile) => {
  const { id, ...rest } = wine;
  return {
    ...state,
    wines: state.wines.slice().reduce((res: Wine[], item) => {
      if (item?.id === id) {
        res.push({ ...item, ...rest });
      } else res.push(item);
      return res;
    }, []),
  };
};

const profileReducer = (state: Profile, action: Action) => {
  switch (action.type) {
    case 'INIT_PROFILE': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'UPDATE_WINE': {
      return findAndReplaceWine(action.payload, state);
    }
    case 'ADD_TO_FAVORITE': {
      return {
        ...state,
        favorite_wines: [...(state?.favorite_wines || []), action.payload],
      };
    }
    case 'REMOVE_FROM_FAVORITE': {
      const newFavoriteWinesList = state?.favorite_wines?.filter((i) => i.id !== action.payload);
      return { ...state, favorite_wines: newFavoriteWinesList };
    }
    default:
      return state;
  }
};

const authReducer = (state: Record<string, any>, action: Action) => {
  switch (action.type) {
    case 'UPDATE_AUTH_INFO': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'RESET_AUTH_INFO':
    default:
      return state;
  }
};

const addressesReducer = (state: NormalizedAddresses, action: Action) => {
  switch (action.type) {
    case 'POPULATE_ADDRESSES': {
      const { allIds, byId } = action.payload.reduce(
        (res: NormalizedState, item: Address) => {
          const { id, ...rest } = item;
          res.allIds.push(id);
          res.byId[id] = rest;

          return res;
        },
        { allIds: [], byId: {} }
      );
      return { ...state, allIds, byId };
    }
    case 'ADD_ADDRESS': {
      const { address_id } = action.payload;
      return {
        allIds: [...state.allIds, address_id],
        byId: { ...state.byId, [address_id]: action.payload },
      };
    }
    case 'UPDATE_ADDRESS': {
      const { address_id, ...rest } = action.payload;
      return {
        ...state,
        byId: {
          ...state.byId,
          [address_id]: { ...state.byId[address_id], ...rest },
        },
      };
    }
    case 'UPDATE_BILLING': {
      const id = action.payload;

      const updatedIds = state.allIds.reduce((res, item) => {
        const val = state.byId[item];

        if (item === id) val.is_billing = true;
        else val.is_billing = false;
        // @ts-ignore
        res[item] = val;

        return res;
      }, {});

      return {
        ...state,
        byId: updatedIds,
      };
    }
    case 'UPDATE_SHIPPING': {
      const id = action.payload;

      const updatedIds = state.allIds.reduce((res, item) => {
        const val = state.byId[item];

        if (item === id) val.is_shipping = true;
        else val.is_shipping = false;
        // @ts-ignore
        res[item] = val;

        return res;
      }, {});

      return {
        ...state,
        byId: updatedIds,
      };
    }
    case 'REMOVE_ADDRESS': {
      const id = action.payload;

      delete state.byId[id];

      return {
        ...state,
        allIds: state.allIds.filter((val) => val !== id),
      };
    }
    default:
      return state;
  }
};

const cartReducer = (state: NormalizedCart, action: Action) => {
  switch (action.type) {
    case 'POPULATE_CART': {
      const { allIds, byId } = action.payload.reduce(
        (res: NormalizedState, item: AdminWine) => {
          const { id, ...rest } = item;
          res.allIds.push(id);
          res.byId[Number(id)] = rest;

          return res;
        },
        { allIds: [], byId: {} }
      );
      return { ...state, allIds, byId };
    }
    case 'ADD_CART': {
      const { id } = action.payload;
      const wineInCellar = state.byId[id];
      if (wineInCellar) {
        return {
          ...state,
          byId: {
            ...state.byId,
            [id]: {
              ...action.payload,
              count: (state.byId?.[id].count || 0) + 1,
            },
          },
        };
      }
      return {
        allIds: [...state.allIds, id],
        byId: { ...state.byId, [id]: { ...action.payload, count: 1 } },
      };
    }
    case 'UPDATE_CART': {
      const { id } = action.payload;
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...state.byId[id],
            ...action.payload,
          },
        },
      };
    }
    case 'REMOVE_CART': {
      const id = action.payload;

      delete state.byId[id];

      return {
        ...state,
        allIds: state.allIds.filter((val) => val !== id),
      };
    }
    default:
      return state;
  }
};

const DEFAULTFILTERS = {
  sortBy: '',
  filterBy: [],
};
const filtersReducer = (state: Filters, action: Action) => {
  switch (action.type) {
    case 'SORT_BY': {
      return {
        ...state,
        sortBy: action.payload,
      };
    }
    case 'FILTER_BY': {
      const key = action.payload;
      const filters = state.filterBy.slice();

      return {
        ...state,
        filterBy: filters.includes(key) ? filters.filter((k) => k !== key) : [...filters, key],
      };
    }
    case 'RESET_FILTERS': {
      return DEFAULTFILTERS;
    }
    default:
      return state;
  }
};

const winesReducer = (state: AdminWine[], action: Action) => {
  switch (action.type) {
    case 'POPULATE_WINES': {
      return [...state, ...action.payload];
    }
    case 'UPDATE_WINELIST': {
      const updatedWinesList = state.map((i) => {
        if (i.id === action.payload?.id) return action.payload;
        return i;
      });
      return [...updatedWinesList];
    }
    default:
      return state;
  }
};

const cellarReducer = (state: Cellar, action: Action) => {
  switch (action.type) {
    case 'POPULATE_CELLAR': {
      // return formatCellatWines(action.payload);
      return action.payload[0] || {};
    }
    case 'ADD_TO_CELLAR': {
      const wines_add = action.payload;
      const findIndexOfWine = state?.wines?.findIndex((i) => i?.id === wines_add?.id);
      if (findIndexOfWine !== -1) {
        const updatedWinesList = state.wines.map((wi) => {
          if (wi.id === wines_add?.id) return { ...wi, count: (wi.count || 0) + 1 };
          return wi;
        });
        return { ...state, wines: updatedWinesList };
      }
      return { ...state, wines: [...(state?.wines || []), wines_add] };
    }
    case 'UPDATE_TO_CELLAR': {
      const wines_update = action.payload;
      const isCountZero = wines_update?.count;
      let updatedWinesList: AdminWine[] = [];
      if (!isCountZero) {
        updatedWinesList = state.wines.filter((wi) => wi.id === wines_update?.id);
      } else {
        const findIndexOfWine = state.wines.findIndex((i) => i.id === wines_update?.id);
        if (findIndexOfWine !== -1) {
          updatedWinesList = state.wines.map((wi) => {
            if (wi.id === wines_update?.id) return { ...wi, count: (wi.count || 0) + 1 };
            return wi;
          });
        }
      }
      return { ...state, wines: updatedWinesList };
    }
    default:
      return state;
  }
};

const grapeVineReducer = (state: GrapeVine[], action: Action) => {
  switch (action.type) {
    case 'POPULATE_GRAPE_VINE': {
      return [...state, ...action.payload];
    }
    default:
      return state;
  }
};

const trendingWinesReducer = (state: Cellar[], action: Action) => {
  switch (action.type) {
    case 'POPULATE_TRENDING_WINES': {
      return [...state, ...action.payload];
    }
    default:
      return state;
  }
};

function combineReducers(reducers: {
  [arg0: string]: (state: any, action: Action) => Record<string, any>;
}) {
  return (state: any, action: Action) =>
    Object.keys(reducers).reduce(
      (acc, key) => ({ ...acc, [key]: reducers[key](state[key], action) }),
      {}
    );
}

const INIT = {
  questions: { allIds: [], byId: {}, profile_complete: false },
  profile: null,
  auth: {},
  addresses: { allIds: [], byId: {} },
  carts: { allIds: [], byId: {} },
  filters: DEFAULTFILTERS,
  winesList: [],
  cellar: {},
  trending: [],
  grapevine: [],
};

const rootReducer = combineReducers({
  questions: questionsReducer,
  profile: profileReducer,
  auth: authReducer,
  addresses: addressesReducer,
  carts: cartReducer,
  filters: filtersReducer,
  winesList: winesReducer,
  cellar: cellarReducer,
  trending: trendingWinesReducer,
  grapevine: grapeVineReducer,
});
export default function Store({ children }: { children: React.ReactNode }) {
  const intermediateReducer = (state: any, action: Action) => {
    if (action.type === 'RESET_STORE') {
      state = { ...state, ...INIT };
    }
    return rootReducer(state, action);
  };
  const [state, dispatch] = useReducer(intermediateReducer, INIT);

  const store = React.useMemo(() => ({ state, dispatch }), [state]);
  return <StoreProvider value={store}>{children}</StoreProvider>;
}
